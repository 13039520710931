import { InfoIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Input,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { FileInput } from '@mantine/core';
import { IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';
import { useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fileUpload } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { useS3FileUpload } from 'src/hooks';
import { removeFields, trimStringValues } from 'src/utils/helpers';
import {
  BrokerageUpdate,
  fetchBrokerageUserData,
} from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { RootState } from '../../../Redux/Store';
import { BrokerageProfileSchema } from '../../../ValidationSchema/Auth/Index';
import useObjectComparator from '../../../hooks/useObjectComparator';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

function BrokerageProfile() {
  const [showClear, setShowClear] = useState(false);
  const autoCompleteRef: any = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Toast = useToast();
  const useruidData = useSelector((state: RootState) => state.brokerage.user);
  let userGetidData = useruidData && useruidData?.brokerageInfo;
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const [fileName, setFileName] = useState<string>('');
  const [bondFileName, setBondFileName] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (!useruidData || !Object.keys(useruidData).length) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (userGetidData) {
      const { legalName, contactNumber, email, officeAddress, logoInfo } =
        userGetidData;
      const brokerageLogo = logoInfo?.urlKey?.split('/').pop();
      const bondLogo = logoInfo?.bondLogo?.urlKey?.split('/').pop();
      setFileName(brokerageLogo!);
      setBondFileName(bondLogo!);
      formik.setValues((prevValues) => ({
        ...prevValues,
        legalName: legalName || '',
        contactNumber: contactNumber || '',
        email: email || '',
        officeAddress: officeAddress || '',
        logoInfo: logoInfo ?? null,
        logoAttachment: null,
      }));
      setShowClear(!!officeAddress);
      setInitialValues((prevValues: any) => ({
        ...prevValues,
        legalName: legalName || '',
        contactNumber: contactNumber || '',
        email: email || '',
        officeAddress: officeAddress || '',
        logoInfo: logoInfo ?? null,
        logoAttachment: null,
      }));
    }
  }, [userGetidData]);

  interface FormValues {
    legalName: string;
    contactNumber: number | null;
    email: string;
    officeAddress: string;
    logoInfo: fileUpload | null;
    bondLogoInfo: fileUpload | null;
    logoAttachment: File | null;
    bondLogoAttachment: File | null;
  }

  const initialValues: FormValues = {
    legalName: '',
    contactNumber: null,
    email: '',
    officeAddress: '',
    logoInfo: null,
    bondLogoInfo: null,
    logoAttachment: null,
    bondLogoAttachment: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BrokerageProfileSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      console.log(values, 'values================');
      setIsProfileUpdate(true);
      let res = '';
      try {
        if (!areDifferent) {
          setIsProfileUpdate(false);
          return;
        }
        const clonedValues = _cloneDeep(values);
        const updatedValues = trimStringValues(
          removeFields(clonedValues, ['logoAttachment', 'bondLogoAttachment'])
        );

        const actionResult = await dispatch(
          BrokerageUpdate(updatedValues) as any
        );
        console.log('actionResult', actionResult);
        const response = actionResult?.payload;
        res = response?.message;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: res,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          resetForm();
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      if (!!localStorage.getItem('Token')) {
        const actionResult = await dispatch(fetchBrokerageUserData() as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
      setIsProfileUpdate(false);
    },
  });

  const handleClear = () => {
    formik.setFieldValue('officeAddress', '');
    setShowClear(false);

    autoCompleteRef.current.value = '';
  };
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);

  const handleUploadAttachments = async (
    payload: File,
    attachmentFieldName: string,
    fieldName: string,
    logoType: string
  ) => {
    try {
      formik.setFieldValue(attachmentFieldName, payload);
      const formData = new FormData();
      formData.append(`files`, payload);
      const res = await uploadFilesInS3(formData);
      console.log('res', res);

      if (S3UploadError) {
        Toast({
          title: S3UploadError,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
        return;
      }

      const fileData = {
        linkId: res?.data?.[0]?.encoding || '',
        link: res?.data?.[0]?.location || '',
        urlKey: res?.data?.[0]?.key || '',
      };

      console.log(fileData, 'fileData');

      const existingLogoInfo = formik.values.logoInfo ?? {
        linkId: '',
        link: '',
        urlKey: '',
        bondLogo: {
          linkId: '',
          link: '',
          urlKey: '',
        },
      };

      if (logoType === 'bondLogo') {
        // Store `bondLogo` inside `logoInfo`
        const updatedLogoInfo = {
          ...existingLogoInfo,
          bondLogo: fileData,
        };
        formik.setFieldValue('logoInfo', updatedLogoInfo);
      } else {
        // Store `logoInfo` main data
        const updatedLogoInfo = {
          ...existingLogoInfo,
          ...fileData,
        };
        formik.setFieldValue('logoInfo', updatedLogoInfo);
      }
    } catch (error) {
      console.log('Upload error', error);
    }
  };

  console.log('formik', formik);

  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Text
              fontWeight="600"
              color="#114684"
              textAlign="center"
              fontSize={20}
              pt="20px"
            >
              Brokerage Profile
            </Text>
          </Box>

          <Box>
            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Legal Name" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="name"
                  placeholder="Legal Name"
                  name="legalName"
                  borderRadius="5px"
                  value={formik.values.legalName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.legalName && formik.errors.legalName && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.legalName}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Contact Number" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="tel"
                  placeholder=" Contact Number"
                  borderRadius="5px"
                  name="contactNumber"
                  value={formik.values.contactNumber ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.contactNumber && formik.errors.contactNumber && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.contactNumber}
              </div>
            )}

            <Box display="flex" mb={['20px', '5px']} mt={2}>
              <Tooltip label="Email Address" placement="right" hasArrow>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="Email"
                  placeholder="Email Address"
                  borderRadius="5px"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                />
              </Tooltip>
            </Box>
            {formik.touched.email && formik.errors.email && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.email}
              </div>
            )}
            <Tooltip label="Office Address" placement="right" hasArrow>
              <Box display="flex" mb={['20px', '5px']} mt={2}>
                <AutoComplete
                  apiKey={GOOGLE_MAPS_API_KEY}
                  defaultValue={formik.values.officeAddress ?? ''}
                  onPlaceSelected={(place) => {
                    if (place && place.formatted_address) {
                      formik.setFieldValue(
                        'officeAddress',
                        place.formatted_address
                      );
                      setShowClear(true);
                    } else {
                      formik.setFieldValue('officeAddress', '');
                      setShowClear(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  className="googlelocation"
                  ref={autoCompleteRef}
                  options={{
                    types: [],
                  }}
                  style={{ fontSize: '13px' }}
                />
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  bg={'#f0f5f9'}
                  mr={2}
                  cursor={'pointer'}
                  _hover={{ color: '#114684' }}
                >
                  {showClear && <FaTimes onClick={handleClear} />}
                </Box>
              </Box>
            </Tooltip>

            {formik.touched.officeAddress && formik.errors.officeAddress && (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.officeAddress}
              </div>
            )}

            <Box
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              sx={{ justifyContent: 'space-between' }}
              mt={2}
              bg={'#f0f5f9'}
            >
              <Box sx={{ padding: 2 }}>Logo for application :</Box>

              <Box sx={{ width: '60%', position: 'relative' }}>
                <FileInput
                  icon={
                    <IconFileCv
                      style={{ width: 18, height: 18 }}
                      stroke={1.5}
                    />
                  }
                  styles={{
                    input: {
                      border: '1px solid #7f7f7f',
                      minHeight: '35px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    },
                    placeholder: {
                      color: `black !important`,
                    },
                    label: {
                      width: '100%',
                      textAlign: 'left',
                    },
                  }}
                  // @ts-ignore
                  placeholder={!!fileName ? fileName : 'Logo'}
                  name="uploadLogoAttachments"
                  onChange={(payload: File) =>
                    handleUploadAttachments(
                      payload,
                      'logoAttachment',
                      'logoInfo',
                      'logo'
                    )
                  }
                  value={formik.values.logoAttachment ?? null}
                  variant="filled"
                  accept=".jpeg,.png,.jpg"
                />
                {/* Icon on the right side */}
                <Tooltip
                  label="Please upload square logo(1:1 ratio) for Application PDF. eg 100x100 px, 256x256 px"
                  placement="right"
                  hasArrow
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: '0',
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                      }}
                      aria-label="Info"
                    >
                      <InfoIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>

              {formik.touched.logoInfo &&
                formik.errors.logoInfo &&
                typeof formik.errors.logoInfo === 'string' && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.logoInfo}
                  </div>
                )}
            </Box>

            <Box
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              sx={{ justifyContent: 'space-between' }}
              mt={2}
              bg={'#f0f5f9'}
            >
              <Box sx={{ padding: 2 }}> Logo for Bond Form :</Box>

              <Box sx={{ width: '60%', position: 'relative' }}>
                <FileInput
                  icon={
                    <IconFileCv
                      style={{ width: 18, height: 18 }}
                      stroke={1.5}
                    />
                  }
                  styles={{
                    input: {
                      border: '1px solid #7f7f7f',
                      minHeight: '35px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    },
                    placeholder: {
                      color: `black !important`,
                    },
                    label: {
                      width: '100%',
                      textAlign: 'left',
                    },
                  }}
                  // @ts-ignore
                  placeholder={!!bondFileName ? bondFileName : 'Bond Logo'}
                  name="uploadBondAttachments"
                  onChange={(payload: File) =>
                    handleUploadAttachments(
                      payload,
                      'bondLogoAttachment',
                      'logoInfo',
                      'bondLogo'
                    )
                  }
                  value={formik.values.bondLogoAttachment ?? null}
                  variant="filled"
                  accept=".jpeg,.png,.jpg"
                />
                <Tooltip
                  label=" Please upload rectangular logo(3:1 or 4:1 ratio) for Bond request form. eg 150x50 px, 300x100 px"
                  placement="right"
                  hasArrow
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    <IconButton
                      sx={{
                        padding: '0',
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                      }}
                      aria-label="info"
                    >
                      <InfoIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>

              {formik.touched.bondLogoInfo &&
                formik.errors.bondLogoInfo &&
                typeof formik.errors.bondLogoInfo === 'string' && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.bondLogoInfo}
                  </div>
                )}
            </Box>

            <Flex justifyContent="space-evenly" columnGap={5}>
              <Box mt="20px" w="100%" mb={10}>
                <Button
                  isLoading={isProfileUpdate}
                  loadingText="Update"
                  type="submit"
                  bg="#114684"
                  color="white"
                  w="100%"
                  _hover={{
                    bg: '#114684',
                    color: 'white',
                    cursor: areDifferent ? 'pointer' : 'not-allowed',
                  }}
                  isDisabled={!areDifferent || S3UploadLoading}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default BrokerageProfile;
