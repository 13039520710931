export const reportTypeOptions = [
  { label: 'OCR', value: 'OCR' },
  { label: 'QBO', value: 'QBO' },
];
export const QBOTimeFrameOptions = [
  { label: 'This Fiscal Year-to-date', value: 'This Fiscal Year-to-date' },
  { label: 'Last Fiscal Year', value: 'Last Fiscal Year' },
  { label: 'Historical', value: 'Other' },
];

export const getHistoricalOptions = (currentYear: number) => {
  const startYear = currentYear - 4; // Start from 3 years before the current year
  const numberOfYears = 8; // Last 8 years

  return Array.from({ length: numberOfYears }, (_, index) => {
    const year = startYear - index;
    return { label: year.toString(), value: year.toString() };
  });
};

export const getHistoricalOptionsQbo = (currentYear: number) => {
  const startYear = currentYear - 2;
  const numberOfYears = 10;

  return Array.from({ length: numberOfYears }, (_, index) => {
    const year = startYear - index;
    return { label: year.toString(), value: year.toString() };
  });
};

export const WIP_TEMPLATE_DOC_TYPES = ['.xlsx', '.xls'];
