import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';

import { useFormik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import { DatePickerInput } from 'rc-datepicker';
import { useEffect, useRef, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { IoWarning } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AddOwner } from 'src/Redux/Applicant/Owner/Owner';
import { OwnerSchema } from 'src/ValidationSchema/Pages/Index';
import {
  addFocusEventListenerOnInput,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

function CreateOwner() {
  interface FormValues {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    SIN_SSN: number | null;
    dateOfBirth: Date | null;
    homeAddress: string;
  }

  const [searchParams] = useSearchParams();
  const [errorPopup, showErrorPopup] = useState(false);
  const [token, setToken] = useState<Record<string, any>>({});

  useEffect(() => {
    try {
      let decoded = jwtDecode(searchParams.get('token')?.toString() || '');

      setToken(decoded);
      formik.setFieldValue('firstName', token.firstName || '');
      formik.setFieldValue('middleName', token.middleName || '');
      formik.setFieldValue('lastName', token.lastName || '');
      formik.setFieldValue('email', token.email || '');
    } catch (err) {
      showErrorPopup(true);
    }
  }, [token.firstName]);

  const initialValues: FormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    SIN_SSN: null,
    dateOfBirth: new Date(),
    homeAddress: '',
  };

  const dispatch = useDispatch();
  const Toast = useToast();
  const navigate = useNavigate();

  const bithDateRef = useRef<DatePickerInput>(null);
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);
  const [showClear, setShowClear] = useState(false);
  const autoCompleteRef: any = useRef(null);
  const [isOwnerUpdate, setIsOwnerUpdate] = useState(false);
  const [ownerCreated, setOwnerCreated] = useState(false);

  const formik = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: OwnerSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsOwnerUpdate(true);
      let res = '';
      try {
        const actionResult = await dispatch(
          AddOwner({ ...values, isVerified: true }) as any
        );
        const response = actionResult?.payload;
        res = response?.message;
        const resStatus = response?.status;
        if (resStatus === 200) {
          setOwnerCreated(true);
          Toast({
            title: res,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });

          resetForm();
        } else {
          if (resStatus === 401) {
            Toast({
              title: 'Token expired!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            Toast({
              title: response?.msg || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res || 'Error occurred!',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsOwnerUpdate(false);
    },
  });

  const handleClear = () => {
    formik.setFieldValue('homeAddress', '');
    setShowClear(false);
    setAutoCompleteKey((prevKey) => prevKey + 1);
  };

  return (
    <Container
      style={{
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',

        transform: 'translate(-50%, -50%)',
      }}
      boxShadow={'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;'}
      textAlign="center"
      h="auto"
      borderRadius="20px"
      p={'20px'}
      borderTop={ownerCreated || errorPopup ? '20px solid #114684' : 'none'}
    >
      {!ownerCreated && !errorPopup && (
        <form onSubmit={formik.handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Text fontSize={'3xl'} color={'#114684'} fontWeight={'700'} pb={3}>
              Owner Info
            </Text>
            <Box display="flex" flexDirection={'column'}>
              <Tooltip label="First Name" placement="right" hasArrow>
                <Input
                  bg={'#f0f5f9'}
                  border="none"
                  focusBorderColor="transparent"
                  size="md"
                  w="100%"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>

              {formik.touched.firstName && formik.errors.firstName && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.firstName}
                </div>
              )}
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Tooltip label="Middle Name" placement="right" hasArrow>
                <Input
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  size="md"
                  w="100%"
                  type="text"
                  placeholder="Middle Name"
                  name="middleName"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.middleName}
                />
              </Tooltip>
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Tooltip label="Last Name" placement="right" hasArrow>
                <Input
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  size="md"
                  w="100%"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
              {formik.touched.lastName && formik.errors.lastName && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.lastName}
                </div>
              )}
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Tooltip label="Email Address" placement="right" hasArrow>
                <Input
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  size="md"
                  w="100%"
                  type="Email"
                  placeholder="Email Address"
                  name="email"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
              {formik.touched.email && formik.errors.email && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.email}
                </div>
              )}
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Tooltip label="Contact Number" placement="right" hasArrow>
                <Input
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  size="md"
                  w="100%"
                  type="tel"
                  placeholder="Contact Number"
                  name="contactNumber"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.contactNumber}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
              {formik.touched.contactNumber && formik.errors.contactNumber && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.contactNumber}
                </div>
              )}
            </Box>

            <Box display="flex" flexDirection={'column'}>
              <Tooltip
                label="This information is strictly used for identity validation only"
                placement="right"
                hasArrow
              >
                <Input
                  focusBorderColor="transparent"
                  bg={'#f0f5f9'}
                  border="none"
                  size="md"
                  w="100%"
                  type="number"
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  placeholder="SIN_SSN"
                  name="SIN_SSN"
                  borderRadius="5px"
                  onChange={formik.handleChange}
                  value={formik.values.SIN_SSN ?? ''}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
              {formik.touched.SIN_SSN && formik.errors.SIN_SSN && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.SIN_SSN}
                </div>
              )}
            </Box>
            <Tooltip label="Date Of Birth" placement="right" hasArrow>
              <Box display="flex" flexDirection={'column'}>
                <DatePickerInput
                  ref={bithDateRef}
                  value={
                    formik.values.dateOfBirth !== null
                      ? formik.values.dateOfBirth
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('dateOfBirth', date);
                  }}
                  locale="en"
                  placeholder="Date of Birth"
                  className="react-datepicker-component react-datepicker-input input null-border"
                  maxDate={new Date()}
                  showOnInputClick={true}
                  onShow={() => addFocusEventListenerOnInput(bithDateRef)}
                  onHide={() => removeFocusEventListenerOnInput(bithDateRef)}
                />
                {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.dateOfBirth as any}
                  </div>
                )}
              </Box>
            </Tooltip>

            <Tooltip label="Home Address" placement="right" hasArrow>
              <Box display="flex" position="relative" mt={1}>
                <AutoComplete
                  key={autoCompleteKey} // Key for dynamic remounting
                  apiKey={GOOGLE_MAPS_API_KEY}
                  defaultValue={formik.values.homeAddress ?? ''}
                  onPlaceSelected={(place) => {
                    if (place && place.formatted_address) {
                      formik.setFieldValue(
                        'homeAddress',
                        place.formatted_address
                      );
                      setShowClear(true);
                    } else {
                      formik.setFieldValue('homeAddress', '');
                      setShowClear(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  className="googlelocation"
                  ref={autoCompleteRef}
                  options={{
                    types: [],
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  bg="#f0f5f9"
                  mr={2}
                  cursor="pointer"
                  _hover={{ color: '#114684' }}
                  position="absolute"
                  top="50%"
                  transform="translateY(-50%)"
                  right={0}
                >
                  {showClear && <FaTimes onClick={handleClear} />}
                </Box>
              </Box>
            </Tooltip>
            {formik.touched.homeAddress && formik.errors.homeAddress && (
              <div
                style={{
                  color: 'red',
                  fontSize: '13px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {formik.errors.homeAddress}
              </div>
            )}

            <Box mt={7} display={'flex'} gap={2}>
              <Button
                isLoading={isOwnerUpdate}
                loadingText={'Submit'}
                type="submit"
                bg="#114684"
                color="white"
                w="100%"
                _hover={{
                  bg: '#114684',
                  color: 'white',
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      )}
      {ownerCreated && (
        <Flex direction={'column'} alignItems={'center'}>
          <FaCheckCircle size={'50px'} color="#4cc819" />
          <Text fontSize="5xl" fontWeight={'700'} color="#4cc819">
            Success!
          </Text>
          <Text fontSize="3xl" color={'#114684'} fontWeight={'600'}>
            Owner created successfully.
          </Text>
        </Flex>
      )}
      {errorPopup && (
        <Flex direction={'column'} alignItems={'center'}>
          <IoWarning size={'50px'} color="#ef4444" />
          <Text fontSize="3xl" color={'#114684'} fontWeight={'600'}>
            Invalid Link!
          </Text>
        </Flex>
      )}
    </Container>
  );
}

export default CreateOwner;
