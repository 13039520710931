import { Box } from '@chakra-ui/react';
import BidBondReference from './BidBondReference';
import FinalForm from './FinalBond.tsx';

const BondReference = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
      <BidBondReference />
      <FinalForm />
    </Box>
  );
};

export default BondReference;
