export interface BondActivity {
  projectName: string | null;
  projectLocation?: string | null;
  obligeeName?: string | null;
  projectStartDate?: string | null;
  projectCompletionDate?: string | null;
  initialTotalContractValue?: number | null;
  currentTotalContractValue?: number | null;
  overageSCL?: string | null;
  bondType?: Array<BondActivityBonds> | null;
  bondNumber?: string | null;
  bondAmount?: number | null;
  bondStatus?: string | null;
  issueDate?: string | null;
  expiryDate?: string | null;
  maintenancePeriod?: number | null;
  indemnityAndSecurityConditions?: Array<string> | null;
  attachments?: Array<any> | [];
  statusQueryFormProvided?: string | null;
  queryFormAttachments?: Array<any> | [];
  otherIndemnity?: string | null;
  id?: string | null;
  linkedByProjectId?: number | string;
  linkedByProjectName?: string;
  createdByProjectId?: number | string;
  createdBySource?: string;
  suretyBondFormsAttachments?: Array<any> | null;
  applicantId?: string;
}

export interface ClientProfile {
  totalAggregateBondFacilityLimit?: number | null;
  totalUtilizedAggregateOfBondFacility?: number | null;
  totalRemainingAggregateOfBondFacility?: number | null;
  singleContractLimit?: number | null;
  renewalDate?: string | null;
  financialCovenants?: string | null;
  clientReportingRequirements?: string | null;
  financialReportingFrequency?: Array<string> | null;
  mostRecentFinancialReviewDate?: string | null;
  indemnityAndSecurityConditions?: Array<string> | null;
  indemnityAndSecurityConditionsAttachments?: Array<any>;
  claimsIncidents?: string | null;
  underwritingFee?: number | null;
  bondFees?: string | null;
  otherIndemnity?: string | null;
  id?: string | null;
  bondFacilityAgreementAttachments?: Array<any> | null;
  updatedAt?: string;
}

export interface BondActivityBonds {
  number: string;
  bonds:
    | Array<{
        type: string;
        amount?: number | null;
        expDate?: string | null;
        maintenancePeriod?: number | null;
        // disabledExpiry?: string | null;
      }>
    | [];
}
export interface BondEntry {
  bondNo: string;
  bondType: string;
  customBond?: string;
}

export const initalizeClientProfile: ClientProfile = {
  totalAggregateBondFacilityLimit: null,
  totalUtilizedAggregateOfBondFacility: null,
  totalRemainingAggregateOfBondFacility: null,
  singleContractLimit: null,
  renewalDate: null,
  financialCovenants: null,
  clientReportingRequirements: null,
  financialReportingFrequency: ['Annually'],
  mostRecentFinancialReviewDate: null,
  indemnityAndSecurityConditions: null,
  indemnityAndSecurityConditionsAttachments: [],
  claimsIncidents: null,
  underwritingFee: null,
  bondFees: null,
  otherIndemnity: null,
  id: null,
  bondFacilityAgreementAttachments: null,
};

export const initialBondActivity: BondActivity = {
  projectName: '',
  projectLocation: null,
  obligeeName: null,
  projectStartDate: null,
  projectCompletionDate: null,
  initialTotalContractValue: null,
  currentTotalContractValue: null,
  overageSCL: null,
  bondType: null,
  bondNumber: null,
  bondAmount: null,
  bondStatus: null,
  issueDate: null,
  expiryDate: null,
  maintenancePeriod: null,
  indemnityAndSecurityConditions: null,
  attachments: [],
  statusQueryFormProvided: null,
  queryFormAttachments: [],
  otherIndemnity: null,
  id: null,
  suretyBondFormsAttachments: null,
};

export const initialBondEntry: BondEntry = {
  bondNo: '',
  bondType: '',
  customBond: '',
};

export interface BondActivityProjects {
  projectName: string;
  source: string;
  currentTotalContractValue?: number;
  bondNumber?: Array<string>;
  obligeeName?: string;
  projectStartDate?: string | null | undefined;
  projectCompletionDate?: string | null | undefined;
  projectLocation?: string;
  initialTotalContractValue?: number;
  expiryDate?: string;
  BondType?: Array<string>;
  id: string;
}

export const bondTypes: { label: string; value: string }[] = [
  {
    value: 'Performance Bond',
    label:
      'Performance Bond (Contract Bond, Performance Security, Completion Bond)',
  },
  {
    value: 'Payment Bond',
    label:
      "Payment Bond (Labor & Material Payment Bond, Supply Bond, Materialmen's Bond)",
  },
  {
    value: 'Maintenance Bond',
    label: 'Maintenance Bond (Warranty Bond, Defect Bond, Guarantee Bond)',
  },
  {
    value: 'Supply Bond',
    label: 'Supply Bond (Material Supply Bond, Supplier Bond)',
  },
  {
    value: 'Subdivision Bond',
    label:
      'Subdivision Bond (Improvement Bond, Developer Bond, Land Development Bond)',
  },
  {
    value: 'License and Permit Bond',
    label:
      'License and Permit Bond (Compliance Bond, Regulatory Bond, Code Compliance Bond)',
  },
  {
    value: 'Environmental or Reclamation Bond',
    label:
      'Environmental or Reclamation Bond (Restoration Bond, Environmental Protection Bond, Mine Reclamation Bond)',
  },
  {
    value: 'Completion Bond',
    label:
      'Completion Bond (Project Completion Bond, Contract Completion Bond)',
  },
  {
    value: 'Site Improvement Bond',
    label:
      'Site Improvement Bond (Off-Site Improvement Bond, Private Improvement Bond)',
  },
  {
    value: 'Material Bond',
    label:
      "Material Bond (Materialmen's Bond, Supplier Payment Bond, Material Delivery Bond)",
  },
];

export const indemnityAndSecurityTypes = [
  'Collateral',
  'Letter of Credit',
  'Guarantees',
  'Other',
];
