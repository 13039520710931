import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
} from '../../data';

interface CalculationDisplayBTTNWProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayBTTNW: React.FC<
  CalculationDisplayBTTNWProps
> = ({ label, calculationFields }) => {
  const { backlog, setBacklog, tangibleNetworth, reportReferenceData } =
    useLiquidityContext();
  const isBacklogOption = !!calculationFields?.backlog?.fields?.length;
  const isBacklogValue = !!calculationFields?.backlog?.value
    ? calculationFields?.backlog?.value
    : 0;
  // const isTangibleNetworthOption =
  //   !!calculationFields?.tangibleNetworth?.fields?.length;
  // const isTangibleNetworthValue = !!calculationFields?.tangibleNetworth?.value
  //   ? calculationFields?.tangibleNetworth?.value
  //   : 0;

  const totalBacklogSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      backlog,
      calculationFields?.backlog,
      reportReferenceData
    );
  }, []);
  const backlogTotalInitialValue =
    isBacklogValue - totalBacklogSelectedFieldSumInitial;

  const backlogTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      backlog,
      calculationFields?.backlog,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + backlogTotalInitialValue)?.toFixed(2)
    );
  }, [backlog, calculationFields?.backlog]);

  // const backlogTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       backlog,
  //       calculationFields.backlog,
  //       reportReferenceData
  //     ) + (isBacklogOption ? 0 : isBacklogValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [backlog, calculationFields.backlog]);

  // const tangibleNetworthTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       tangibleNetworth,
  //       calculationFields.tangibleNetworth,
  //       reportReferenceData
  //     ) + (isTangibleNetworthOption ? 0 : isTangibleNetworthValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [tangibleNetworth, calculationFields.tangibleNetworth]);

  const result = useMemo(() => {
    const tangibleNetworthValue = !!tangibleNetworth ? tangibleNetworth : 1;
    return Number((backlogTotal / tangibleNetworthValue).toFixed(2));
  }, [backlogTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Backlog (${backlogTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.backlog?.fields!,
              reportReferenceData!
            )}
            value={backlog}
            onChange={setBacklog}
            placeholder="Select Backlog items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label={`Tangible Networth (${tangibleNetworthTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.tangibleNetworth?.fields!,
              reportReferenceData!
            )}
            value={tangibleNetworth}
            onChange={setTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          /> */}

          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
