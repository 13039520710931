import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Divider, Progress, Switch } from '@mantine/core';
// import '@mantine/core/styles.css';
// import '@mantine/dates/styles.css'; //if using mantine date picker features
// import 'mantine-react-table/styles.css';

import { useDisclosure } from '@mantine/hooks';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CiWarning } from 'react-icons/ci';
import { FaHistory, FaUserEdit } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { RiChatHistoryLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import { useApplicationNavigation } from 'src/hooks/useApplicationNavigation';
import PdfContent from 'src/Pages/Reports/components/PdfModal';
import { IApplication } from 'src/Redux/Applications/application';
import {
  getBrokerDashboardAppList,
  removeType,
} from 'src/Redux/Applications/slice';
import {
  ApplicantBrokerage,
  Applicantpermission,
  BrokerageDashboardState,
  checkUserProfileStatus,
  InitialApplication,
  removeBrokerDashboardType,
} from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { RootState, useAppSelector } from 'src/Redux/Store';
import { socket } from 'src/Socket';
import { AutoPopulateModal } from './BrokerDashboard';
import GlobalHistoryModal from './BrokerDashboardList/components/GlobalHistoryModal';

interface TBrokerDashboard {
  id: number;
  applicationName: string;
  createdAt: string;
  applicantName: string;
  status: string;
  awaitingInput: string;
  brokerName: string;
}

const BrokerDashboardTableMantine = () => {
  const toast = useAppToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tableData, setTableData] = useState<IApplication[]>([]);
  const { handelEdit, handelHistory, handelView } = useApplicationNavigation();
  const [isloadingApp, setIsloadingApp] = useState<boolean>(false);
  const [selectedBrokerageId, setSelectedBrokerageId] = useState('');
  const [autoPopulateModalOpen, setAutoPopulateModalOpen] = useState(false);
  const [qboModal, setQboModal] = useState(false);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [loader, setLoader] = useState<{
    confirmBtnLoader: boolean;
    declinedBtnloader: boolean;
  }>({ confirmBtnLoader: false, declinedBtnloader: false });
  const [applicationCount, setApplicationCount] = useState({});
  const [isUpdateApplication, setIsUpdateApplication] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState<Record<
    string,
    any
  > | null>(null);
  const [showAllBrokerApps, setShowAllBrokerApps] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure(false);
  const appliactionsSlice = useAppSelector(
    (state: RootState) => state.appliactionsSlice
  );
  const brokerageDashboard: BrokerageDashboardState = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const userData = useAppSelector((state) => state.broker.brokerProfileData);
  const userId = localStorage.getItem('userID');

  const isBrokerStatusValid =
    !!userData &&
    !['decline', 'revoke', 'pending']?.includes(
      userData?.data?.brokerageStatus
    );
  console.log('userData', userData);

  useEffect(() => {
    return () => {
      dispatch(removeType({}));
    };
  }, []);

  useEffect(() => {
    console.log(
      'selectedBrokerageId:::',
      selectedBrokerageId,
      appliactionsSlice?.brokerDashboardAppList,
      showAllBrokerApps
    );

    let filterApplication: IApplication[] = [];
    if (!!appliactionsSlice?.brokerDashboardAppList) {
      if (!!selectedBrokerageId && showAllBrokerApps) {
        filterApplication = appliactionsSlice?.brokerDashboardAppList?.filter(
          (el) => el.applicantId === Number(selectedBrokerageId)
        );
      } else if (selectedBrokerageId) {
        filterApplication = appliactionsSlice?.brokerDashboardAppList
          ?.filter((el) => el.applicantId === Number(selectedBrokerageId))
          ?.filter((el) => el.brokerId === Number(userId));
      } else if (showAllBrokerApps) {
        filterApplication = appliactionsSlice?.brokerDashboardAppList;
      } else {
        filterApplication = appliactionsSlice?.brokerDashboardAppList?.filter(
          (el) => el.brokerId === Number(userId)
        );
      }
    }
    setTableData(filterApplication);
  }, [selectedBrokerageId, showAllBrokerApps]);

  useEffect(() => {
    const { status, error, type, brokerDashboardAppList, errorResponse } =
      appliactionsSlice;
    switch (status) {
      case 'loading':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          setIsLoading(false);
          if (brokerDashboardAppList && brokerDashboardAppList?.length > 0) {
            const currentBrokerApp = brokerDashboardAppList?.filter(
              (el) => el.brokerId === Number(userId)
            );
            setTableData(currentBrokerApp);
          }
        }
        break;
      case 'failed':
        if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
          if (!!errorResponse && errorResponse?.type === 'INFO') {
            toast({
              title: error,
              status: 'info',
            });
          } else {
            toast({
              title: error,
              status: 'error',
            });
          }
          setIsLoading(false);
          setTableData([]);
        }
        break;
    }

    // const dateInput = document.querySelector('.mantine-DateInput-root');
    // // Check if the element exists to avoid errors
    // if (dateInput) {
    //   const parentDiv = dateInput.parentElement;
    //   if (parentDiv) {
    //     parentDiv.style.display = 'block';
    //   }
    // }
  }, [appliactionsSlice?.status, appliactionsSlice?.type]);

  //new add
  useEffect(() => {
    dispatch(ApplicantBrokerage() as any);
    dispatch(getBrokerDashboardAppList() as any);

    return () => {
      dispatch(removeBrokerDashboardType({}) as any);
    };
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData, user, error } =
      brokerageDashboard;
    console.log('brokerageDashboard', brokerageDashboard);

    switch (status) {
      case 'loading': {
        if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
          setLoader({ ...loader, confirmBtnLoader: true });
        }
        if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
          setLoader({ ...loader, declinedBtnloader: true });
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   setIsLoading(true);
        // }
        break;
      }
      case 'succeed': {
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
        }
        //(comment below code due to  this changes -> on select applicant -> everytime create new application) (below code required if not create fresh application everytime)
        // if (type === 'GET_APPLICANT_PROFILE_STATUS' && selectedBrokerageId) {
        // if (brokerageDashboard.selectedApplicantData?.isProfileCompleted) {
        //   fetchData(selectedBrokerageId);
        // }
        // }
        if (
          type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
          type === 'ACCOUNTING_DATA_PERMISSION_DECLINED'
        ) {
          toast({
            title: user?.data?.msg,
            status: 'success',
          });
          if (type === 'ACCOUNTING_DATA_PERMISSION_APPROVE') {
            setLoader({ ...loader, confirmBtnLoader: false });
          } else if (type === 'ACCOUNTING_DATA_PERMISSION_DECLINED') {
            setLoader({ ...loader, declinedBtnloader: false });
          }
          navigate(`/application/${user?.data?.applicationId}`);
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   setIsLoading(false);
        // }
        break;
      }
      case 'failed': {
        if (
          type === 'ACCOUNTING_DATA_PERMISSION_APPROVE' ||
          type === 'ACCOUNTING_DATA_PERMISSION_DECLINED'
        ) {
          toast({
            title: error,
            status: 'error',
          });
          setLoader({
            ...loader,
            confirmBtnLoader: false,
            declinedBtnloader: false,
          });
        }
        // if (type === 'GET_BROKER_DASHBOARD_APP_LIST') {
        //   toast({
        //     title: error,
        //     status: 'error',
        //   });
        //   setIsLoading(false);
        // }
        break;
      }

      default:
        break;
    }
  }, [brokerageDashboard.status, selectedBrokerageId, brokerageDashboard.type]);

  useEffect(() => {
    // 1
    socket.on('permissionGranted', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 2
    socket.on('permissionDenied', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 3
    socket.on('updateFormByApplicant', (data) => {
      if (data?.applicationCounts) setApplicationCount(data?.applicationCounts);
    });

    // 4
    // socket.on("updateFormByAccountant", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // 5
    // socket.on("updateFormByOwner", (data) => {
    //   Toast({
    //     title: data.message,
    //     status: "success",
    //     isClosable: true,
    //     duration: 3000,
    //     position: "top-right",
    //   });
    // });

    // Cleanup function
    return () => {
      socket.off('permissionGranted');
      socket.off('permissionDenied');
      socket.off('updateFormByApplicant');
      // socket.off("updateFormByAccountant");
      // socket.off("updateFormByOwner");
    };
  }, []);

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedId = event.target.value;
    console.log('selectedId', selectedId);

    setSelectedBrokerageId((prevSelectedId) => selectedId);

    if (selectedId) {
      setIsloadingApp(true);
      dispatch(checkUserProfileStatus(selectedId) as any);

      setIsloadingApp(false);
    }
  };

  const confirmAutoPopulate = () => {
    if (applicationId) {
      navigate(`/application/${applicationId}`);
    } else {
      setIsUpdateApplication(true);
      try {
        dispatch(
          Applicantpermission({
            applicantId: parseFloat(selectedBrokerageId),
          }) as any
        );
      } catch (error: any) {
        toast({
          title: error.message,
          status: 'error',
        });
      }
      setIsUpdateApplication(false);
    }
  };
  const declinedAutoPopulate = () => {
    setIsUpdateApplication(true);
    try {
      dispatch(
        InitialApplication({
          id: parseFloat(selectedBrokerageId),
        }) as any
      );
    } catch (error: any) {
      toast({
        title: error.message,
        status: 'error',
      });
    }
    setIsUpdateApplication(false);
  };

  const getBrokerDetails = (broker: any) => {
    let brokerData = {
      firstName: broker?.firstName,
      lastName: broker?.lastName,
      contactNumber: broker?.contactNumber,
      brokerageName: broker?.brokerage?.legalName,
      email: broker?.email,
    };
    return brokerData;
  };

  const getFormData = (user: any) => {
    let formData = {
      accountingSystem: user?.accountingSystem,
      creditBureau: user?.creditBureau,
      financialInfo: user?.financialInfo,
      cms: user?.cms,
      surety: user?.surety,
      insurance: user?.insurance,
    };
    return formData;
  };

  // const handleToggle = (val: boolean) => {
  //   const { brokerDashboardAppList } = appliactionsSlice;
  //   setShowAllBrokerApps(val);
  //   if (brokerDashboardAppList) {
  //     if (val) {
  //       setTableData(brokerDashboardAppList);
  //     } else {
  //       setTableData(
  //         brokerDashboardAppList?.filter((el) => el.brokerId === Number(userId))
  //       );
  //     }
  //   }
  // };

  const columns = useMemo<MRT_ColumnDef<IApplication>[]>(
    () => [
      {
        accessorKey: 'applicationName',
        header: 'Application Code',
        enableColumnActions: false,
        enableSorting: false,
        // filterVariant: 'autocomplete',
        enableColumnFilter: false,
      },
      {
        accessorFn: (originalRow) => {
          return new Date(originalRow.createdAt);
        }, //convert to date for sorting and filtering
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: 'Created Date',
        enableColumnActions: false,
        enableSorting: false,
        filterVariant: 'date',
        // filterVariant: 'date-range',
        filterFn: (row, id, filterValue) => {
          // const [minDate, maxDate] = filterValue;
          const { createdAt } = row?.original;
          console.log('row-createdDate-filter', row, id, filterValue);
          return moment(createdAt).startOf('d').isSame(moment(filterValue));

          // if (!!minDate && !!!maxDate) {
          //   return moment(createdAt).isSameOrAfter(
          //     moment(minDate).startOf('d')
          //   );
          // } else if (!!maxDate && !!!minDate) {
          //   return moment(createdAt).isSameOrBefore(moment(maxDate).endOf('d'));
          // } else if (!!minDate && !!maxDate) {
          //   return moment(createdAt).isBetween(
          //     moment(minDate).startOf('d'),
          //     moment(maxDate).endOf('d')
          //   );
          // } else {
          //   return true;
          // }
          // return true;
        },
        mantineFilterDateInputProps: {
          className: 'custom-date-picker',
        },
        Cell: ({ row }: { row: any }) => {
          return moment(row?.original?.createdAt).format('MM/DD/YYYY');
        },
      },
      {
        accessorKey: 'applicantName',
        id: 'applicantName',
        accessorFn: (row) => `${row?.applicant?.companyName}`,
        header: 'Applicant Name',
        enableColumnActions: false,
        enableSorting: false,
        // Cell: ({ row }: { row: any }) => {
        //   return row?.applicant?.companyName;
        // },
      },
      {
        accessorKey: 'brokerDashboard',
        header: 'Status',
        enableColumnActions: false,
        enableSorting: false,
        filterVariant: 'multi-select',
        mantineFilterMultiSelectProps: {
          data: ['Draft', 'Completed'] as any,
        },
      },
      {
        accessorKey: 'awaitingInput',
        header: 'Awaiting Input',
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }: { row: any }) => {
          return row?.original?.isAwaitingInput ? 'Yes' : 'No';
        },
      },
      {
        accessorKey: 'brokerName',
        id: 'brokerName',
        accessorFn: (row) =>
          `${row?.broker?.firstName} ${row?.broker?.lastName}`,
        header: 'Broker Name',
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'document',
        header: 'Document',
        Cell: ({ renderedCellValue, row }) => {
          //   console.log('row', row);
          const user = row?.original;
          if (
            [
              'Application Submitted. Awaiting Underwriting',
              'Approved by Underwriter',
              'Declined by Underwriter',
              'Completed',
            ].includes(user.brokerDashboard)
          ) {
            return (
              <Box
                cursor={'pointer'}
                _hover={{ color: '#114684' }}
                // minW={'25px'}
                w={'max-content'}
              >
                <PdfContent
                  applicationId={user?.applicationId}
                  companyName={user?.accountingSystem?.legalName}
                  brokerInfo={getBrokerDetails(user?.broker)}
                  allFormData={getFormData(user)}
                  qboCompanyName={user?.autoPopulateStatus?.qboCompanyName}
                  user={user}
                />
              </Box>
            );
          }
        },
        size: 44,
        maxSize: 44,
        minSize: 25,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        id: 'view',
        header: 'View',
        Cell: ({ renderedCellValue, row }) => {
          //   console.log('row', row);
          const user = row?.original;
          return (
            <Box
              //   minW={'25px'}
              w={'max-content'}
              cursor={'pointer'}
              _hover={{ color: '#114684' }}
              onClick={() =>
                handelView({
                  applicationId: user.applicationId,
                  status: user.brokerDashboard,
                })
              }
            >
              <IoEyeSharp size={20} />
            </Box>
          );
        },
        size: 30,
        maxSize: 30,
        minSize: 25,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        id: 'edit',
        header: 'Edit',
        Cell: ({ renderedCellValue, row }) => {
          //   console.log('row', row);
          const user = row?.original;
          return (
            <Box
              //   minW={'25px'}
              w={'max-content'}
              cursor={'pointer'}
              _hover={{ color: '#114684' }}
              onClick={() => {
                handelEdit({
                  applicationId: user.applicationId,
                  status: user.brokerDashboard,
                });
              }}
            >
              <FaUserEdit size={20} />
            </Box>
          );
        },
        size: 30,
        maxSize: 30,
        minSize: 25,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
      {
        id: 'history',
        header: 'History',
        Cell: ({ renderedCellValue, row }) => {
          //   console.log('row', row);
          const user = row?.original;
          return (
            <Flex
              gap={{ base: 1, md: 2 }}
              // flexWrap={'nowrap'}
              // flexWrap={'wrap'}
            >
              <Box
                cursor={'pointer'}
                _hover={{ color: '#114684' }}
                onClick={() => {
                  setApplicationDetails({
                    applicationCode: user?.applicationName,
                    applicantName: user?.applicant?.companyName,
                    brokerName: `${user?.broker?.firstName} ${user?.broker?.lastName}`,
                    applicationId: user.applicationId,
                  });
                  open();
                }}
              >
                <Tooltip
                  fontSize="md"
                  label={'Global History'}
                  placement={'auto'}
                  hasArrow
                >
                  <Box>
                    <RiChatHistoryLine size={20} />
                  </Box>
                </Tooltip>
              </Box>
              <Box
                cursor={'pointer'}
                _hover={{ color: '#114684' }}
                onClick={() =>
                  handelHistory({
                    applicationId: user.applicationId,
                    applicantId: user.applicantId,
                    status: user.brokerDashboard,
                  })
                }
              >
                <Tooltip
                  fontSize="md"
                  label={'History'}
                  placement={'auto'}
                  hasArrow
                >
                  <Box>
                    <FaHistory size={18} />
                  </Box>
                </Tooltip>
              </Box>
            </Flex>
          );
        },
        size: 33,
        maxSize: 33,
        minSize: 25,
        enableColumnFilter: false,
        enableColumnActions: false,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns as any,
    data: (tableData ?? []) as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      pagination: { pageSize: 100, pageIndex: 0 },
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 50, maxSize: 80, size: 80 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
      className: 'broker_dashboard_table',
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isLoading ? 'hidden' : 'auto',
        // maxHeight: 'unset !important',
        // overflow: 'visible !important',
        maxHeight: `calc(100vh - 257px)`,
        // maxHeight: `calc(100vh - 350px)`,
        '@media (max-width: 1275px)': {
          maxHeight: `calc(100vh - 222px)`,
        },
        '@media (max-width: 1230px)': {
          maxHeight: `calc(100vh - 276px)`,
        },
        '@media (max-width: 978px)': {
          maxHeight: `calc(100vh - 314px)`,
        },
        '@media (max-width: 525px)': {
          maxHeight: `calc(100vh - 331px)`,
        },
        '@media (max-width: 492px)': {
          maxHeight: `calc(100vh - 398px)`,
        },
      },
      className: '',
    },
    mantineTableHeadCellProps: {
      sx: {
        boxShadow: 'unset !important',
      },
    },
    mantineTableHeadProps: {
      sx: {
        opacity: '1 !important',
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        boxShadow: 'unset !important',
      },
    },
    // mantineTableBodyProps: {
    //   sx: {
    //     overflow: isLoading ? 'hidden' : 'auto',
    //     maxHeight: `calc(100vh - 350px)`,
    //   },
    // },
    enableColumnPinning: true,
    enablePagination: true,
    positionPagination: 'none',
    enableStickyHeader: true,
    enableBottomToolbar: false,
    // enableFacetedValues: true,
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex py={1} alignItems={'center'} justifyContent={'flex-end'}>
          <Flex alignItems={'center'} gap={4} p={3}>
            <MRT_GlobalFilterTextInput table={table} />
            <MRT_ToolbarInternalButtons table={table} />
          </Flex>
          <Divider
            size="md"
            orientation="vertical"
            h={40}
            style={{ alignSelf: 'center' }}
          />
          <Box className="pagination-wrapper">
            <MRT_TablePagination position="top" table={table} />
          </Box>
        </Flex>
        {(isLoading ||
          (brokerageDashboard.status === 'loading' &&
            brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS')) && (
          <Progress value={100} animate={true} />
        )}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isFullScreen: isFullScreen,
      isLoading: isLoading,
      // ||
      // (brokerageDashboard.status === 'loading' &&
      //   brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS') ||
      // isloadingApp,
    },
  });
  return (
    <Box>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        borderBottomWidth="1px"
        mb={2}
        flexWrap={'wrap'}
        py={1}
      >
        <Heading
          p="4"
          fontSize={'24px'}
          fontWeight={'600'}
          //   borderBottomWidth="1px"
        >
          Dashboard Details
        </Heading>
        {isBrokerStatusValid && (
          <Flex
            alignItems={'center'}
            gap={{ base: 3, md: 5 }}
            mx={4}
            flexWrap={'wrap'}
          >
            {!!userData?.data?.purchasedPlanInfo && (
              <Text>
                Applications remaining :{' '}
                {userData?.data?.purchasedPlanInfo?.remainingApplications ?? 0}
              </Text>
            )}
            <Flex
              alignItems={'center'}
              gap={{ base: 3, md: 5 }}
              flexWrap={'wrap'}
            >
              <Box minW="max-content">
                <Select
                  w="100%"
                  onChange={handleBrokerageSelect}
                  value={selectedBrokerageId}
                  name="userData.brokerageId"
                  borderRadius="5px"
                  bg={'#114684'}
                  color={'white'}
                  textAlign={'center'}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Applicant"
                  />
                  {applicantOptions.map((user?: any, index?: number) => (
                    <option
                      style={{ color: 'black' }}
                      key={index}
                      value={user?.applicantId ?? ''}
                      label={user?.companyName ?? ''}
                    />
                  ))}
                </Select>
              </Box>
              <Box minW={'200px'}>
                {/* <Button
               isLoading={
                 (brokerageDashboard.status === 'loading' &&
                   brokerageDashboard.type === 'GET_APPLICANT_PROFILE_STATUS') ||
                 isOngoingDataLoading
               }
               w={'100%'}
               type="submit"
               bg="#114684"
               color="white"
               _hover={{ bg: '#114684' }}
               onClick={() =>
                 applicationId
                   ? navigate(`/application/${applicationId}`)
                   : setQboModal(true)
               }
               isDisabled={isloadingApp || !selectedBrokerageId}
               position={'relative'}
             >
               {!brokerageDashboard.selectedApplicantData?.isProfileCompleted ? (
                 <Box
                   onClick={(e) => {
                     e.stopPropagation();
                     navigate(
                       `/broker/applicant-profile?applicantId=${selectedBrokerageId}`
                     );
                   }}
                 >
                   Complete Applicant Profile
                 </Box>
               ) : applicationId ? (
                 'Update Application'
               ) : (
                 'Create Application'
               )}
             </Button> */}
                {/* {button text change based on application status for every time create new application} */}
                <Button
                  isLoading={
                    brokerageDashboard.status === 'loading' &&
                    !!brokerageDashboard.type &&
                    [
                      'ACCOUNTING_DATA_PERMISSION_DECLINED',
                      'GET_APPLICANT_PROFILE_STATUS',
                    ].includes(brokerageDashboard.type)
                  }
                  w={'100%'}
                  type="submit"
                  bg="#114684"
                  color="white"
                  _hover={{ bg: '#114684' }}
                  onClick={() =>
                    !brokerageDashboard.selectedApplicantData
                      ?.isProfileCompleted
                      ? navigate(
                          `/broker/applicant-profile?applicantId=${selectedBrokerageId}`
                        )
                      : !!brokerageDashboard.selectedApplicantData
                            ?.qboAccountLinked
                        ? declinedAutoPopulate()
                        : setQboModal(true)
                  }
                  isDisabled={
                    isloadingApp ||
                    !selectedBrokerageId ||
                    (!!selectedBrokerageId &&
                      !userData?.data?.purchasedPlanInfo)
                  }
                  position={'relative'}
                >
                  <Tooltip
                    label="Your brokerage company has not purchased any plan"
                    isDisabled={
                      !(
                        !!selectedBrokerageId &&
                        !userData?.data?.purchasedPlanInfo
                      )
                    }
                    placement="bottom"
                    hasArrow
                  >
                    <span>
                      {selectedBrokerageId &&
                      !brokerageDashboard.selectedApplicantData
                        ?.isProfileCompleted
                        ? 'Complete Applicant Profile'
                        : 'Create Application'}
                    </span>
                  </Tooltip>
                </Button>
              </Box>
            </Flex>
          </Flex>
        )}
      </Flex>
      {!!userData && !!Object.keys(userData)?.length ? (
        !isBrokerStatusValid ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={'calc(100vh - 210px)'}
            // bg="#f9f9f9"
          >
            <Box
              border="1px solid #ddd"
              borderRadius="8px"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              p="20px"
              bg="white"
              maxWidth="400px"
              textAlign="center"
            >
              <Box>
                <Box display={'flex'} justifyContent={'center'}>
                  <CiWarning fontSize={'64px'} color="#3182ce" />
                  {/* <CiCircleInfo color="#3182ce" fontSize={64} /> */}
                </Box>
                <Text
                  as="h1"
                  fontSize="x-large"
                  fontWeight="bold"
                  color="#333"
                  mb={2}
                >
                  Please Contact Your Brokerage
                </Text>
                <Text as="p" fontSize="lg" color="#555">
                  {userData?.data?.brokerageStatus === 'pending'
                    ? 'Your Access is not Approved'
                    : `Your Access is ${userData?.data?.brokerageStatus === 'Decline' ? 'Declined' : 'Revoked'} by Brokerage`}
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box margin={'15px 30px'}>
            <Flex alignItems={'flex-end'} justifyContent={'flex-end'} mb={5}>
              <Flex alignItems={'center'} gap={2}>
                <Switch
                  styles={{
                    body: {
                      gap: 5,
                      flexDirection: 'row-reverse',
                    },
                  }}
                  label="Show Other Broker Applications"
                  checked={showAllBrokerApps}
                  onChange={(event) =>
                    setShowAllBrokerApps(event.currentTarget.checked)
                  }
                />
              </Flex>
            </Flex>
            <MantineReactTable table={table} key={'broker_dashboard_table'} />
          </Box>
        )
      ) : (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}

      <AutoPopulateModal
        isOpen={qboModal}
        onClose={() => setQboModal(false)}
        onConfirm={() => {
          setQboModal(false);
          setAutoPopulateModalOpen(true);
        }}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Yes"
        declinedButtonText="No"
        title={`Do you use QuickBooks (QBO) online?`}
        loader={loader}
      />
      <AutoPopulateModal
        isOpen={autoPopulateModalOpen}
        onClose={() => setAutoPopulateModalOpen(false)}
        onConfirm={confirmAutoPopulate}
        onDecline={declinedAutoPopulate}
        confirmButtonText="Approve"
        declinedButtonText="Decline"
        title={`Can we have your permission to access your accounting data to
         generate financial reports for insurance purposes?`}
        subTitle={`Why do we need this`}
        tooltip={`Securely pulling financial information from your accounting system significantly expedites the bonding and insurance underwriting process`}
        loader={loader}
      />

      <GlobalHistoryModal
        isOpen={opened}
        onClose={close}
        applicationDetails={applicationDetails}
      />
    </Box>
  );
};

export default BrokerDashboardTableMantine;
