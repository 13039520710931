import { Button, Flex } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { updateAnalyticalReport } from 'src/Redux/Broker/AnalyticalReports/slice';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import { useAnalyticalReportContext } from '../..';
import {
  updateDataWithSelectedItemsAndSum,
  updateSelectedItems,
} from '../../data';
import LiquidityCalculationReferenceFields from './LiquidityCalculationReferenceFields';

const initialData: any = {
  cashOnHand: {
    value: 10,
    fields: [
      { label: 'cashOnHand 1', value: 100, id: 'coh1', isSelected: true },
      { label: 'cashOnHand 2', value: 100, id: 'coh2', isSelected: false },
    ],
  },
  annualOperatingExpense: {
    value: 10,
    fields: [
      {
        label: 'annualOperatingExpense 1',
        value: 500,
        id: 'aoe1',
        isSelected: false,
      },
      {
        label: 'annualOperatingExpense 2',
        value: 50,
        id: 'aoe2',
        isSelected: false,
      },
    ],
  },
  nonCashItems: {
    value: 10,
    fields: [
      { label: 'nonCashItems 1', value: 300, id: 'nci1', isSelected: false },
      { label: 'nonCashItems 2', value: 200, id: 'nci2', isSelected: true },
    ],
  },
  currentAssets: {
    value: 30,
  },
  currentLiabilities: { value: 10 },
  netAnnualSales: { value: 10 },
  averageWorkingCapital: { value: 10 },
  backlog: {
    value: 10,
    fields: [{ label: 'backlog 1', value: 100, id: 'b1', isSelected: false }],
  },
  quickAssets: { value: 10 },
  tangibleNetworth: {
    value: 10,
    fields: [
      { label: 'tangibleNetworth 1', value: 200, id: 'bo1', isSelected: false },
      { label: 'tangibleNetworth 2', value: 50, id: 'bo2', isSelected: false },
    ],
  },
  billingInExcessOfCosts: {
    value: 10,
    fields: [
      {
        label: 'billingInExcessOfCosts 1',
        value: 100,
        id: 'bi1',
        isSelected: false,
      },
      {
        label: 'billingInExcessOfCosts 2',
        value: 100,
        id: 'bi2',
        isSelected: false,
      },
    ],
  },
  cashEquivalents: {
    value: 10,
    fields: [
      {
        label: 'cashEquivalents 1',
        value: 100,
        id: 'cc1',
        isSelected: false,
      },
      {
        label: 'cashEquivalents 2',
        value: 100,
        id: 'cc2',
        isSelected: false,
      },
    ],
  },
};

const EditLiquidityRatioModal = ({
  isOpen,
  onClose,
  TData,
}: {
  isOpen: boolean;
  onClose: () => void;
  TData: AnalyticalReportDetails;
}) => {
  const {
    cashOnHand,
    annualOperatingExpense,
    nonCashItems,
    backlog,
    billingsInExcessOfCosts,
    cashEquivalents,
    reportReferenceData,
    totalAsset,
    totalLiabilities,
    inTangibleAsset,
    setSelectedCashOnHand,
    setSelectedAnnualOperatingExpense,
    setSelectedNonCashItems,
    setBacklog,
    setBillingsInExcessOfCosts,
    setCashEquivalents,
    setReportReferenceData,
    setTotalAsset,
    setTotalLiabilities,
    setInTangibleAsset,
  } = useLiquidityContext();
  const {
    applicantId,
    analyticalReportPeriod,
    analyticalReportRatio,
    btnLoader,
  } = useAnalyticalReportContext();

  const dispatch = useAppDispatch();
  const analyticalReportsSclice = useAppSelector(
    (state) => state.analyticalReportSlice
  );
  const [calculationReferenceFields, setCalculationReferenceFields] = useState<
    Record<string, any>
  >({});
  const [
    initialCalculationReferenceFields,
    setInitialCalculationReferenceFields,
  ] = useState<Record<string, any>>({});

  useEffect(() => {
    const { status, type } = analyticalReportsSclice;
    switch (status) {
      case 'succeed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      case 'failed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      default:
        break;
    }
  }, [analyticalReportsSclice.status, analyticalReportsSclice.type]);

  useEffect(() => {
    if (isOpen) {
      setCalculationReferenceFields(TData?.editable);
      setInitialCalculationReferenceFields(TData?.editable);
      setReportReferenceData({
        profitAndLossData: TData?.profitAndLossData,
        balanceSheetData: TData?.balanceSheetData,
      });
      updateSelectedItems(TData?.editable, {
        cashOnHand: setSelectedCashOnHand,
        annualOperatingExpense: setSelectedAnnualOperatingExpense,
        nonCashItems: setSelectedNonCashItems,
        backlog: setBacklog,
        billingsInExcessOfCosts: setBillingsInExcessOfCosts,
        cashEquivalents: setCashEquivalents,
        totalAsset: setTotalAsset,
        totalLiabilities: setTotalLiabilities,
        inTangibleAsset: setInTangibleAsset,
      });
    }
  }, [isOpen, TData?.editable]);

  const handleSubmitLiquidityRatio = () => {
    const selectedItemsMap = {
      cashOnHand,
      annualOperatingExpense,
      nonCashItems,
      backlog,
      billingsInExcessOfCosts,
      cashEquivalents,
      totalAsset,
      totalLiabilities,
      inTangibleAsset,
    };

    console.log(
      'selectedItemsMap',
      selectedItemsMap,
      calculationReferenceFields
    );
    // const allOptionsbinded = [
    //   ...Object.values(reportReferenceData.profitAndLossData).flat(),
    //   ...Object.values(reportReferenceData.balanceSheetData).flat(),
    // ];
    const allOptionsbinded = [
      ...(reportReferenceData?.balanceSheetData?.assets ?? []),
      ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
      ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
    ];

    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );

    const payload = {
      ratioName: analyticalReportRatio,
      duration: analyticalReportPeriod,
      applicantId: applicantId,
      updatedFields: updatedData,
    };

    try {
      dispatch(updateAnalyticalReport(payload as any));
    } catch (error) {
      console.log('Liquidity Ratio Update Error', error);
    }

    console.log('Liquidity updatedData', updatedData, payload);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit Analytical Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'750px'}
      styles={{
        title: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }}
    >
      <form>
        <LiquidityCalculationReferenceFields
          calculationFields={calculationReferenceFields}
        />

        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            isDisabled={btnLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            loadingText="Update"
            isLoading={btnLoader}
            onClick={() => handleSubmitLiquidityRatio()}
          >
            Update
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditLiquidityRatioModal;
