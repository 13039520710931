import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface EfficiencyContextType {
  costsInExcessOfBillings: string[];
  setCostsInExcessOfBillings: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  totalRevenue: string[];
  setTotalRevenue: React.Dispatch<React.SetStateAction<string[]>>;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
    }>
  >;
  totalAsset: string[];
  setTotalAsset: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  inTangibleAsset: string[];
  setInTangibleAsset: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create Context with Default Values
const EfficiencyContext = createContext<EfficiencyContextType | undefined>(
  undefined
);

// Define Props for Provider
interface EfficiencyContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const EfficiencyProvider: React.FC<EfficiencyContextProviderProps> = ({
  children,
}) => {
  const [costsInExcessOfBillings, setCostsInExcessOfBillings] = useState<
    string[]
  >([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalAsset, setTotalAsset] = useState<string[]>([]);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [inTangibleAsset, setInTangibleAsset] = useState<string[]>([]);
  const [totalRevenue, setTotalRevenue] = useState<string[]>([]);
  // const [revenueTangibleNetworth, setRevenueTangibleNetworth] = useState<
  //   string[]
  // >([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }>({ profitAndLossData: {}, balanceSheetData: {} });

  return (
    <EfficiencyContext.Provider
      value={{
        costsInExcessOfBillings,
        setCostsInExcessOfBillings,
        tangibleNetworth,
        setTangibleNetworth,
        totalRevenue,
        setTotalRevenue,
        reportReferenceData,
        setReportReferenceData,
        totalAsset,
        setTotalAsset,
        totalLiabilities,
        setTotalLiabilities,
        inTangibleAsset,
        setInTangibleAsset,
      }}
    >
      {children}
    </EfficiencyContext.Provider>
  );
};

// Custom Hook for Context Access
export const useEfficiencyContext = (): EfficiencyContextType => {
  const context = useContext(EfficiencyContext);
  if (!context) {
    throw new Error(
      'useEfficiencyContext must be used within an EfficiencyProvider'
    );
  }
  return context;
};
