import { Box, Button, Flex } from '@chakra-ui/react';
import { Checkbox, Modal, Stack, Text } from '@mantine/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InputTag from 'src/Component/Common/TagInput';
import { updateProjectStatus } from 'src/Redux/Broker/Reports/WorkInProgress/slice';
import {
  WIPUploadBondList,
  WIPUploadProjectList,
  uploadTemplteType,
} from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch } from 'src/Redux/Store';

const WIPProjectListModal = ({
  isOpen,
  onClose,
  isUpdateStatusLoader,
  uploadTemplte,
}: {
  isOpen: boolean;
  onClose: () => void;
  isUpdateStatusLoader: boolean;
  uploadTemplte: uploadTemplteType;
}) => {
  const dispatch = useAppDispatch();
  const [selectedProjectId, setSelectedProjectId] = useState<string[]>([]);
  const [bondNumbers, setBondNumbers] = useState<WIPUploadBondList[] | []>([]);

  useEffect(() => {
    setBondNumbers(uploadTemplte?.bondList || []);
  }, [uploadTemplte?.bondList]);

  const handleUpdateProjectStatus = async (selectedProjectId: string[]) => {
    const { reportId, projectList } = uploadTemplte;

    console.log('bonnnnd=', bondNumbers);
    const bondlist = bondNumbers.filter((obj) => obj.bondNumber.length > 0);
    const selectedProjectList: WIPUploadProjectList[] = projectList
      ?.filter((item) => selectedProjectId.includes(item.uniqueProjectId))
      ?.map((item) => ({ ...item, projectStatus: 'completed' }));
    const payload = {
      id: reportId,
      projectList: selectedProjectList,
      bondList: bondlist,
    };

    dispatch(updateProjectStatus(payload));
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setSelectedProjectId([]);
        setBondNumbers([]);
        onClose();
      }}
      title="WIP Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box>
        <Checkbox.Group
          value={selectedProjectId}
          label={
            <Text weight={'bold'}>
              Below active project(s) already exist in WIP report as of&nbsp;
              {moment(uploadTemplte?.reportAsOf).format('MMMM DD, YYYY')}.
              <br></br>
              Select project(s) to set as 'Complete'.
            </Text>
          }
          onChange={setSelectedProjectId}
        >
          <Stack mt="xs">
            {uploadTemplte?.projectList?.map((item, index) => (
              <Checkbox
                value={item?.uniqueProjectId}
                label={item?.projectName}
                key={index}
              />
            ))}
          </Stack>
        </Checkbox.Group>
        {bondNumbers.length > 0 && (
          <Box borderTop={'1px solid rgba(17, 71, 132, 0.28)'} mt={4} pt={4}>
            <Text size={'sm'} mb={4} weight={'bold'}>
              Provide Bond numbers for following project(s).<br></br>
              Use comma(,) to seperate multiple bond numbers.
            </Text>
            {bondNumbers.map((item, index) => (
              <Flex direction={'column'} key={index}>
                <Text>{item?.projectName}</Text>
                <InputTag
                  onChange={(data) => {
                    console.log('bonnnnn=', bondNumbers);

                    setBondNumbers(
                      [...bondNumbers].map((obj) => {
                        if (obj.uniqueProjectId === item.uniqueProjectId) {
                          return { ...obj, bondNumber: data };
                        } else return obj;
                      })
                    );
                  }}
                />
              </Flex>
            ))}
          </Box>
        )}
        {/* <Text size={'sm'} mt={4} weight={'bold'}>
          Do you want to mark selected project(s) completed??
        </Text> */}
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              setSelectedProjectId([]);
              setBondNumbers([]);
              onClose();
            }}
            isDisabled={isUpdateStatusLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            onClick={() => handleUpdateProjectStatus(selectedProjectId)}
            loadingText="Saving"
            isDisabled={
              bondNumbers.findIndex((obj) => obj.bondNumber.length > 0) ===
                -1 && selectedProjectId.length === 0
            }
            isLoading={isUpdateStatusLoader}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default WIPProjectListModal;
