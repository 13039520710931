export type AnalyticalReportRatioData =
  | 'Profitability'
  | 'Liquidity'
  | 'Efficiency'
  | 'Leverage'
  | 'YearOverYearGrowth';

export const analyticalReportRatioOptions: {
  value: AnalyticalReportRatioData;
  label: string;
}[] = [
  {
    value: 'Profitability',
    label: 'Profitability Ratio',
  },
  {
    value: 'Liquidity',
    label: 'Liquidity Ratio',
  },
  {
    value: 'Efficiency',
    label: 'Efficiency Ratio',
  },
  {
    value: 'Leverage',
    label: 'Leverage Ratio',
  },
  {
    value: 'YearOverYearGrowth',
    label: 'YoY growth',
  },
];

export type AnalyticalReportPeriodData =
  | 'Monthly'
  | 'Quarterly'
  | 'Semi Annually'
  | 'Year-to-Date'
  | 'Annual Statements';

export const analyticalReportPeriodOptions: {
  value: AnalyticalReportPeriodData;
  label: string;
}[] = [
  {
    value: 'Annual Statements',
    label: 'Annual Statements',
  },
  {
    value: 'Semi Annually',
    label: 'Semi Annually',
  },
  {
    value: 'Quarterly',
    label: 'Quarterly',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
  },
  {
    value: 'Year-to-Date',
    label: 'YTD',
  },
];

export const analyticalReportsCalculationFieldsLabel: {
  [key: string]: string;
} = {
  TotalRevenue: 'Total Revenue',
  CoGS: 'Cost of Goods Sold',
  TotalOperatingExpenses: 'Total Operating Expenses',
  TotalOtherIncome: 'Total Other Income',
  TotalOtherExpenses: 'Total Other Expenses',
  TotalIncomeTaxes: 'Total Income Taxes',
  TotalCurrentAssets: 'Total Current Assets',
  TotalLongTermAssets: 'Total Long Term Assets',
  TotalAssets: 'Total Assets',
  TotalCurrentLiabilities: 'Total Current Liabilities',
  TotalLongTermLiabilities: 'Total Long Term Liabilities',
  TotalLiabilities: 'Total Liabilities',
  TotalEquity: 'Total Equity',
};

export const updateSelectedItems = (
  data: any,
  setters: Record<string, Function>
) => {
  Object.keys(data).forEach((key) => {
    if (data[key]?.fields) {
      // Filter only selected items
      const selectedItems = data[key].fields.filter(
        (item: any) => item.isSelected
      );

      const selectedItemsIds = selectedItems.map((item: any) => item.id);

      // Update corresponding state dynamically
      if (setters[key]) {
        setters[key](selectedItemsIds);
      }
    }
  });
};

//old function without PL/BS report options render
// export const updateDataWithSelectedItemsAndSum = (
//   data: any,
//   selectedItemsMap: Record<string, string[]>
// ) => {
//   return Object.keys(data).reduce((acc: any, key) => {
//     const updatedFields = data[key]?.fields?.map((field: any) => ({
//       ...field,
//       isSelected: selectedItemsMap[key]?.includes(field.id) || false,
//     }));

//     // Calculate sum of selected items' values
//     const selectedSum = updatedFields
//       ? updatedFields
//         .filter((field: any) => field.isSelected)
//         .reduce((sum: number, field: any) => sum + field.value, 0)
//       : data[key]?.value || 0;

//     acc[key] = {
//       ...data[key],
//       ...(updatedFields ? { fields: updatedFields } : {}),
//       value: selectedSum, // Update value with sum of selected fields
//     };

//     return acc;
//   }, {});
// };

//new function with PL/BS report options render
export const updateDataWithSelectedItemsAndSum = (
  calculationReferenceFields: CalculationFields,
  selectedItemsMap: Record<string, string[]>, // Selected IDs for each field
  allOptionsbinded: optionsFields[],
  initialCalculationReferenceFields: CalculationFields
): CalculationFields => {
  let updatedFields = { ...calculationReferenceFields };

  Object.keys(updatedFields).forEach((key) => {
    if (!updatedFields[key]?.fields) return;

    // Get selected IDs for the current field
    const selectedIds = selectedItemsMap[key] || [];

    // Update isSelected for existing fields
    // updatedFields[key].fields = updatedFields?.[key]?.fields?.map((item) => ({
    //   ...item,
    //   isSelected: selectedIds?.includes(item.id),
    // }));

    updatedFields[key] = {
      ...updatedFields[key],
      fields: updatedFields?.[key]?.fields?.map((item) => ({
        ...item,
        isSelected: selectedIds?.includes(item.id),
      })),
    };

    // Add new selected items from allOptionsbinded that are not already in fields

    // const filteredOptions = allOptionsbinded.filter((item) => !selectedIds?.includes(item.id));
    const newSelectedItems = allOptionsbinded
      .filter(
        (item) =>
          selectedIds?.includes(item.id) &&
          !updatedFields?.[key]?.fields?.some((f) => f.id === item.id)
      )
      .map((item) => ({ ...item, isSelected: true }));

    console.log('newSelectedItems', newSelectedItems);

    updatedFields[key]?.fields?.push(...newSelectedItems);

    // Calculate total sum of selected fields
    // Ensure value is always a number (default to 0 if no selected items exist)
    const initialValue = initialCalculationReferenceFields?.[key]?.value ?? 0;
    const initialSelectedFieldsTotal = initialCalculationReferenceFields?.[
      key
    ]?.fields
      ?.filter((item) => item.isSelected)
      .reduce((sum, item) => sum + (item.Total ?? 0), 0);
    const selectedFieldsTotal = updatedFields?.[key]?.fields
      ?.filter((item) => item.isSelected)
      .reduce((sum, item) => sum + (item.Total ?? 0), 0);

    console.log(
      'updatedFields',
      key,
      updatedFields,
      Number(
        (
          initialValue -
          Number(initialSelectedFieldsTotal) +
          Number(selectedFieldsTotal)
        ).toFixed(2)
      )
    );
    updatedFields[key] = {
      ...updatedFields[key],
      value: Number(
        (
          initialValue -
          Number(initialSelectedFieldsTotal) +
          Number(selectedFieldsTotal)
        ).toFixed(2)
      )!, // Ensures value is always a number
    };
  });

  return updatedFields;
};

export interface CalculationFields {
  [key: string]: CalculationField;
}

export interface optionsFields {
  Label: string;
  Total: number;
  id: string;
  isSelected: boolean;
  groupBy: 'self' | 'Profit and Loss' | 'Balance Sheet';
}

export interface CalculationField {
  value: number;
  fields?: optionsFields[];
}

export const getOptions = (
  field: Array<{
    Label: string;
    Total: number;
    id: string;
    isSelected: boolean;
  }>
) =>
  field?.map((item) => ({
    value: item.id,
    label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
  })) || [];

export const calculateTotal = (
  selectedIds: string[],
  field: CalculationField
) =>
  field?.fields
    ?.filter((item) => selectedIds.includes(item.id))
    .reduce((sum, item) => sum + (!!item.Total ? item.Total : 0), 0) || 0;

export const calculateTotalWthPLBS = (
  selectedIds: string[],
  field: CalculationField,
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.fields?.map((item) => item.id);
  // const PLReportOptions = Object.values(
  //   reportReferenceData.profitAndLossData
  // )?.flat();
  // const BSReportOptions = Object.values(
  //   reportReferenceData.balanceSheetData
  // )?.flat();
  const PLReportOptions = [
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];
  const BSReportOptions = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
  ];

  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [
    ...field?.fields!,
    ...PLUpdatedData,
    ...BSUpdatedData,
  ];
  return (
    allOptionsbinded
      ?.filter((item) => selectedIds.includes(item.id))
      .reduce((sum, item) => sum + (!!item.Total ? item.Total : 0), 0) || 0
  );
};

export const getOptionWithPLBSReports = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  // const PLReportOptions = Object.values(
  //   reportReferenceData.profitAndLossData
  // )?.flat();
  // const BSReportOptions = Object.values(
  //   reportReferenceData.balanceSheetData
  // )?.flat();
  const PLReportOptions = [
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];
  const BSReportOptions = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
  ];
  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds.includes(el?.id)
  );
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...PLUpdatedData, ...BSUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};

export const monthLabels: Record<string, string> = {
  m1: 'Jan',
  m2: 'Feb',
  m3: 'Mar',
  m4: 'Apr',
  m5: 'May',
  m6: 'Jun',
  m7: 'Jul',
  m8: 'Aug',
  m9: 'Sep',
  m10: 'Oct',
  m11: 'Nov',
  m12: 'Dec',
};

export const getBSAssetsOptions = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  const BSReportOptions = reportReferenceData.balanceSheetData?.assets;
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...BSUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};

export const getPLExpensesOptions = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  const PLReportOptions = reportReferenceData.profitAndLossData?.expenses;
  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...PLUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};
