import { Box, Flex, Heading, Select } from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import 'rc-datepicker/lib/style.css';
import { createContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { getAnalyticalReportData } from 'src/Redux/Broker/AnalyticalReports/slice';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import AnalyticalTable from './components/Analytical/AnalyticalTable';
import {
  analyticalReportPeriodOptions,
  analyticalReportRatioOptions,
} from './data/index';

export interface AnalyticalReportContextType {
  applicantId: number;
  analyticalReportPeriod: string;
  analyticalReportRatio: string;
  btnLoader: boolean;
  contentLoader: boolean;
}

const AnalyticalReportContext = createContext<
  AnalyticalReportContextType | undefined
>(undefined);

export const useAnalyticalReportContext = (): AnalyticalReportContextType => {
  const context = useContext(AnalyticalReportContext);
  if (!context) {
    throw new Error(
      'useAnalyticalReportContext must be used within an AnalyticalReportContextProvider'
    );
  }
  return context;
};

const AnalyticalReports = () => {
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const analyticalReportsSclice = useAppSelector(
    (state) => state.analyticalReportSlice
  );

  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [applicantId, setApplicantId] = useState<number>();
  const [analyticalReportRatio, setAnalyticalReportRatio] = useState<
    string | null
  >(null);
  const [analyticalReportPeriod, setAnalyticalReportPeriod] =
    useState<string>('Annual Statements');
  const [loader, setLoader] = useState<{
    applicantLoader: boolean;
    contentLoader: boolean;
    btnLoader: boolean;
  }>({
    applicantLoader: false,
    contentLoader: false,
    btnLoader: false,
  });
  const [tableInfo, setTableInfo] = useState<AnalyticalReportDetails | null>(
    null
  );

  const userType = localStorage.getItem('userType');
  const userID = localStorage.getItem('userID');

  useEffect(() => {
    console.log('userType', userType);

    if (userType === 'broker') {
      dispatch(ApplicantBrokerage(true));
    } else if (userType === 'applicant') {
      setApplicantId(Number(userID));
    }
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICANT_BY_BROKERAGE') {
          setLoader({ ...loader, applicantLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'GET_APPLICANT_BY_BROKERAGE' &&
          !!applicantsByBrokrageData?.length
        ) {
          setApplicantOptions(applicantsByBrokrageData);
          setLoader({ ...loader, applicantLoader: false });
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICANT_BY_BROKERAGE') {
          setLoader({ ...loader, applicantLoader: false });
        }
        break;
      }
      default:
        break;
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    const { status, type, error, msg } = analyticalReportsSclice;
    switch (status) {
      case 'loading': {
        if (type === 'GET_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, contentLoader: true, btnLoader: false });
        }
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, contentLoader: false });
          const validKeys = Object.keys(
            analyticalReportsSclice?.reportDetails || {}
          ).filter(
            (key) =>
              (analyticalReportsSclice?.reportDetails as any)?.[key] !== null
          );

          if (validKeys?.length > 0) {
            setTableInfo(analyticalReportsSclice?.reportDetails!);
          }
        }
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, btnLoader: false });
          toast({
            title: 'Analytical Report Updated Successfully',
            status: 'success',
          });
          getAnalyticalReportDetalis();
        }
        break;
      }
      case 'failed': {
        toast({
          title: error,
          status: 'error',
        });
        if (type === 'GET_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, contentLoader: false });
        }
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          setLoader({ ...loader, btnLoader: false });
        }
        break;
      }
      default:
        break;
    }
  }, [analyticalReportsSclice.status, analyticalReportsSclice.type]);

  useEffect(() => {
    getAnalyticalReportDetalis();
  }, [applicantId, analyticalReportRatio, analyticalReportPeriod]);

  const getAnalyticalReportDetalis = () => {
    if (!!applicantId && !!analyticalReportRatio && !!analyticalReportPeriod) {
      dispatch(
        getAnalyticalReportData({
          applicantId,
          analyticalReportRatio,
          analyticalReportPeriod,
        })
      );
    }
  };

  const filteredAnalyticalReportPeriodOptions = useMemo(() => {
    return analyticalReportRatio === 'YearOverYearGrowth'
      ? analyticalReportPeriodOptions?.filter(
          (el) => el.value === 'Annual Statements'
        )
      : analyticalReportPeriodOptions;
  }, [analyticalReportRatio]);

  return (
    <Flex flexDirection={'column'} gap={5} p={5}>
      <Heading fontSize={'24px'} fontWeight={'600'}>
        Analytical Reports
      </Heading>
      <Flex
        gap={3}
        flexWrap={'wrap'}
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        {userType === 'broker' && (
          <Box maxW="max-content">
            <Select
              w="100%"
              onChange={(event) => {
                setApplicantId(Number(event.target.value));
              }}
              value={applicantId ?? ''}
              name="applicantId"
              borderRadius="5px"
              bg={'#114684'}
              color={'white'}
              textAlign={'center'}
            >
              <option
                value={''}
                style={{ color: 'black' }}
                label="Select Applicant"
              />
              {applicantOptions?.map((user?: any, index?: number) => (
                <option
                  style={{ color: 'black' }}
                  key={index}
                  value={user?.applicantId ?? ''}
                  label={user?.companyName ?? ''}
                />
              ))}
            </Select>
          </Box>
        )}
        {loader?.applicantLoader && (
          <Flex alignItems={'center'}>
            <Loader size={'sm'} h={'100%'} />
          </Flex>
        )}
        <Box maxW="max-content">
          <Select
            w="100%"
            onChange={(event) => {
              setAnalyticalReportRatio(event.target.value);
            }}
            value={analyticalReportRatio ?? ''}
            name="analyticalReportRatio"
            borderRadius="5px"
            bg={'#114684'}
            color={'white'}
            textAlign={'center'}
          >
            <option
              value={''}
              style={{ color: 'black' }}
              label="Select Ratio"
            />
            {analyticalReportRatioOptions?.map((user?: any, index?: number) => (
              <option
                style={{ color: 'black' }}
                key={index}
                value={user?.value ?? ''}
                label={user?.label ?? ''}
              />
            ))}
          </Select>
        </Box>
        <Box maxW="max-content">
          <Select
            w="100%"
            onChange={(event) => {
              setAnalyticalReportPeriod(event.target.value);
            }}
            value={analyticalReportPeriod ?? ''}
            name="analyticalReportPeriod"
            borderRadius="5px"
            bg={'#114684'}
            color={'white'}
            textAlign={'center'}
          >
            <option
              value={''}
              style={{ color: 'black' }}
              label="Select Period"
            />
            {filteredAnalyticalReportPeriodOptions?.map(
              (user?: any, index?: number) => (
                <option
                  style={{ color: 'black' }}
                  key={index}
                  value={user?.value ?? ''}
                  label={user?.label ?? ''}
                />
              )
            )}
          </Select>
        </Box>
      </Flex>
      {!!applicantId && !!analyticalReportRatio && !!analyticalReportPeriod && (
        <AnalyticalReportContext.Provider
          value={{
            applicantId,
            analyticalReportPeriod,
            analyticalReportRatio,
            btnLoader: loader?.btnLoader,
            contentLoader: loader?.contentLoader,
          }}
        >
          <AnalyticalTable tableInfo={tableInfo!} />
        </AnalyticalReportContext.Provider>
      )}
    </Flex>
  );
};
export default AnalyticalReports;
