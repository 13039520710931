import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
  getOptionWithPLBSReports,
} from '../../data';

interface CalculationDisplayOverbillingsToNetCashProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayOverbillingsToNetCash: React.FC<
  CalculationDisplayOverbillingsToNetCashProps
> = ({ label, calculationFields }) => {
  const {
    billingsInExcessOfCosts,
    setBillingsInExcessOfCosts,
    cashEquivalents,
    setCashEquivalents,
    reportReferenceData,
  } = useLiquidityContext();

  const isBillingsInExcessOfCostsOption =
    !!calculationFields?.billingsInExcessOfCosts?.fields?.length;
  const isBillingsInExcessOfCostsValue = !!calculationFields
    ?.billingsInExcessOfCosts?.value
    ? calculationFields?.billingsInExcessOfCosts?.value
    : 0;
  const isCashAndCashEquivalentsOption =
    !!calculationFields?.cashEquivalents?.fields?.length;
  const isCashAndCashEquivalentsValue = !!calculationFields?.cashEquivalents
    ?.value
    ? calculationFields?.cashEquivalents?.value
    : 0;

  const totalBillingsInExcessOfCostsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      billingsInExcessOfCosts,
      calculationFields?.billingInExcessOfCosts,
      reportReferenceData
    );
  }, []);
  const billingsInExcessOfCostsTotalInitialValue =
    isBillingsInExcessOfCostsValue -
    totalBillingsInExcessOfCostsSelectedFieldSumInitial;

  const totalCashEquivalentsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      cashEquivalents,
      calculationFields?.cashEquivalents,
      reportReferenceData
    );
  }, []);
  const cashEquivalentsTotalInitialValue =
    isCashAndCashEquivalentsValue - totalCashEquivalentsSelectedFieldSumInitial;

  const billingsInExcessOfCostsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      billingsInExcessOfCosts,
      calculationFields?.billingInExcessOfCosts,
      reportReferenceData
    );
    return Number(
      (
        totalSumSelectedFields + billingsInExcessOfCostsTotalInitialValue
      )?.toFixed(2)
    );
  }, [billingsInExcessOfCosts, calculationFields?.billingInExcessOfCosts]);

  const cashAndCashEquivalentsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      cashEquivalents,
      calculationFields?.cashEquivalents,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + cashEquivalentsTotalInitialValue)?.toFixed(2)
    );
  }, [cashEquivalents, calculationFields?.cashEquivalents]);

  // const billingsInExcessOfCostsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       billingsInExcessOfCosts,
  //       calculationFields.billingInExcessOfCosts,
  //       reportReferenceData
  //     ) +
  //     (isBillingsInExcessOfCostsOption ? 0 : isBillingsInExcessOfCostsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [billingsInExcessOfCosts, calculationFields.billingInExcessOfCosts]);

  // const cashAndCashEquivalentsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       cashEquivalents,
  //       calculationFields.cashEquivalents,
  //       reportReferenceData
  //     ) + (isCashAndCashEquivalentsOption ? 0 : isCashAndCashEquivalentsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [cashEquivalents, calculationFields.cashEquivalents]);

  const result = useMemo(() => {
    const cashEquivalents = !!cashAndCashEquivalentsTotal
      ? cashAndCashEquivalentsTotal
      : 1;
    console.log('result', billingsInExcessOfCostsTotal, cashEquivalents);
    return Number((billingsInExcessOfCostsTotal / cashEquivalents).toFixed(2));
  }, [billingsInExcessOfCostsTotal, cashAndCashEquivalentsTotal]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Billings in Excess of Costs (${billingsInExcessOfCostsTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.billingInExcessOfCosts?.fields!,
              reportReferenceData!
            )}
            value={billingsInExcessOfCosts}
            onChange={setBillingsInExcessOfCosts}
            placeholder="Select Billings in Excess of Costs items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          <MultiSelect
            label={`Cash and Cash Equivalents (${cashAndCashEquivalentsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.cashEquivalents?.fields!,
              reportReferenceData!
            )}
            value={cashEquivalents}
            onChange={setCashEquivalents}
            placeholder="Select Cash and Cash Equivalents items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
