import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Select,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { DatePickerInput } from 'rc-datepicker';
import { useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoAttachSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import {
  GetProjectList,
  SaveBondActivity,
  UpdateBondActivity,
} from 'src/Redux/Broker/BondFacilityTracker/BondActivity';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  addFocusEventListenerOnInput,
  amountOnly,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';

import { Tooltip } from '@mantine/core';
import moment from 'moment';
import { FaDollarSign } from 'react-icons/fa';
import { MdOutlineHelp } from 'react-icons/md';
import { bondActivityYupSchema } from 'src/ValidationSchema/Bond/bond-facility-tracker';
import { useS3FileUpload } from 'src/hooks';
import Swal from 'sweetalert2';
import ManageBonds from './ManageBonds';
import {
  BondActivity,
  BondActivityBonds,
  BondActivityProjects,
  indemnityAndSecurityTypes,
  initialBondActivity,
} from './model';

const BondActivityAdd = ({
  addMode,
  applicantId,
  selectedBondActivity,
}: {
  addMode: any;
  applicantId: string;
  selectedBondActivity: BondActivity | null;
}) => {
  const projectStartDateRef = useRef<DatePickerInput>(null);
  const projectEndDateRef = useRef<DatePickerInput>(null);
  const issueDateRef = useRef<DatePickerInput>(null);

  const dispatch = useAppDispatch();
  const [projectList, setProjectList] = useState<BondActivityProjects[]>([]);
  const Toast = useToast();
  const navigate = useNavigate();
  const [selectedProjIndex, setSelectedProjIndex] = useState(-1);
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bondNumberList, setBondNumberList] = useState<BondActivityBonds[]>([]);
  const [bondRequestsBondTypes, setBondRequestsBondTypes] = useState<
    BondActivityBonds[]
  >([]);
  const bondFacilityTrackerSlice = useAppSelector(
    (state) => state.bondFacilityTrackerSlice
  );
  const [singleContractLimit, setSingleContractLimit] = useState<number>(0);
  const [disableInitialContract, setDisableInitialContract] = useState(false);
  const [wipProjects, setWipProjects] = useState<BondActivityProjects[]>([]);
  const [selectedWipProject, setSelectedWipProject] =
    useState<BondActivityProjects | null>(null);

  const handleExit = () => {
    addMode(false);
  };

  const getProjects = async () => {
    const result = await dispatch(GetProjectList(applicantId));

    if (result.payload?.data) {
      setProjectList(result.payload?.data);
    } else setProjectList([]);
  };

  useEffect(() => {
    setSingleContractLimit(
      bondFacilityTrackerSlice.clientProfileDetails
        ? Number(
            bondFacilityTrackerSlice.clientProfileDetails[0]
              ?.singleContractLimit
          )
        : 0
    );
  }, [
    bondFacilityTrackerSlice.status,
    bondFacilityTrackerSlice.type,
    applicantId,
  ]);

  useEffect(() => {
    if (selectedBondActivity) {
      formik.setFieldValue(
        'statusQueryFormProvided',
        selectedBondActivity.statusQueryFormProvided !== null
          ? selectedBondActivity.statusQueryFormProvided
            ? 'yes'
            : 'no'
          : null
      );
      let otherindem =
        selectedBondActivity.indemnityAndSecurityConditions?.filter(
          (obj) => !indemnityAndSecurityTypes.includes(obj)
        );

      if (otherindem && otherindem?.length > 0)
        formik.setFieldValue('otherIndemnity', otherindem[0]);
      setBondNumberList(selectedBondActivity.bondType || []);

      if (
        selectedBondActivity.initialTotalContractValue !== null &&
        selectedBondActivity.initialTotalContractValue! > 0
      )
        setDisableInitialContract(true);
    } else {
      getProjects();
      formik.resetForm();

      setBondNumberList([]);
      setBondRequestsBondTypes([]);
      setWipProjects([]);
      setSelectedWipProject(null);
    }
  }, [applicantId]);

  const inputFileIndemnityRef = useRef<HTMLInputElement>(null);
  const inputFileQueryRef = useRef<HTMLInputElement>(null);
  const inputFileSuretyBond = useRef<HTMLInputElement>(null);

  const onFileChange = async (e: any, fieldName: string) => {
    try {
      const formData = new FormData();
      formData.append(`files`, e.target.files[0]);
      const res = await uploadFilesInS3(formData);

      if (S3UploadError) {
        Toast({
          title: S3UploadError,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
        return;
      }

      const fileData = {
        linkId: res?.data?.[0]?.encoding,
        link: res?.data?.[0]?.location,
        urlKey: res?.data?.[0]?.key,
        fileName: res?.data?.[0]?.originalname,
      };

      const files = [
        fileData,
        ...(formik.getFieldProps(fieldName).value || []),
      ];

      formik.setFieldValue(fieldName, files);
    } catch (error) {
      console.log('Upload error', error);
    }
  };

  const removeFile = (index: number, field: string) => {
    const files = [...(formik.getFieldProps(field).value || [])];
    files.splice(index, 1);
    formik.setFieldValue(field, files);
  };

  const formik = useFormik({
    initialValues: selectedBondActivity
      ? selectedBondActivity
      : initialBondActivity,
    validationSchema: bondActivityYupSchema,
    onSubmit: async (values: BondActivity, { resetForm }) => {
      let res = '';
      let query =
        values.statusQueryFormProvided !== null
          ? values.statusQueryFormProvided === 'yes'
          : null;

      if (
        values.indemnityAndSecurityConditions &&
        values.indemnityAndSecurityConditions?.length > 0
      ) {
        if (values.indemnityAndSecurityConditions.includes('Other'))
          values.indemnityAndSecurityConditions.push(
            values.otherIndemnity ?? ''
          );
      }
      let bondL: any = bondNumberList;
      bondL.forEach((obj: any) => {
        obj.bonds.forEach((items: any) => delete items?.disabledExpiry);
      });

      const payload: any = {
        attachments:
          values.indemnityAndSecurityConditions &&
          values.indemnityAndSecurityConditions.length > 0
            ? values.attachments
            : [],
        // bondAmount: totalBondAmt,
        // bondNumber: null,
        bondStatus: values.bondStatus,
        bondType: bondL, ///values.bondType || [],
        currentTotalContractValue: values.currentTotalContractValue || 0,
        expiryDate: values.expiryDate,
        indemnityAndSecurityConditions:
          values.indemnityAndSecurityConditions || [],
        initialTotalContractValue: values.initialTotalContractValue || 0,
        issueDate: values.issueDate,
        // maintenancePeriod: mp ? mp.toString() : null,
        obligeeName: values.obligeeName,
        // otherIndemnity: null,
        // overageSCL: null,
        projectCompletionDate: values.projectCompletionDate,
        projectLocation: values.projectLocation,
        projectName: values.projectName,
        projectStartDate: values.projectStartDate,
        queryFormAttachments: query ? values.queryFormAttachments : [],
        statusQueryFormProvided: query,
        applicantId: applicantId,
        suretyBondFormsAttachments: values.suretyBondFormsAttachments,
      };

      if (selectedBondActivity == null) {
        try {
          const payload2 =
            selectedWipProject !== null
              ? {
                  ...payload,
                  createdByProjectId: projectList[selectedProjIndex].id,
                  createdBySource: projectList[selectedProjIndex].source,
                  linkedByProjectId: selectedWipProject.id,
                  linkedByProjectName: selectedWipProject.projectName,
                }
              : {
                  ...payload,
                  createdByProjectId: projectList[selectedProjIndex].id,
                  createdBySource: projectList[selectedProjIndex].source,
                };

          const actionResult = await dispatch(
            SaveBondActivity(payload2) as any
          );

          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            resetForm();
            setBondNumberList([]);
            handleExit();
            Toast({
              title: res || 'Bond Activity successfully added',
              status: 'success',
              isClosable: true,
              duration: 1000,
              position: 'top-right',
            });
          } else {
            if (resStatus !== 401) {
              Toast({
                title: res || 'Error occurred!',
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              navigate('/login');
            }
          }
        } catch (err: any) {
          Toast({
            title: 'Error occurred!',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      } else {
        try {
          const payload2 = selectedBondActivity.linkedByProjectId
            ? {
                ...payload,
                createdByProjectId: selectedBondActivity.createdByProjectId,
                createdBySource: selectedBondActivity.createdBySource,
                linkedByProjectId: selectedBondActivity.linkedByProjectId,
                linkedByProjectName: selectedBondActivity.linkedByProjectName,
              }
            : {
                ...payload,
                createdByProjectId: selectedBondActivity.createdByProjectId,
                createdBySource: selectedBondActivity.createdBySource,
              };
          const actionResult = await dispatch(
            UpdateBondActivity({
              ...payload2,
              id: selectedBondActivity.id,
            }) as any
          );

          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            resetForm();
            handleExit();
            Toast({
              title: res || 'Bond Activity successfully updated',
              status: 'success',
              isClosable: true,
              duration: 1000,
              position: 'top-right',
            });
          } else {
            if (resStatus !== 401) {
              Toast({
                title: res || 'Error occurred!',
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              navigate('/login');
            }
          }
        } catch (err: any) {
          Toast({
            title: res || 'Error occurred!',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    },
  });

  const setFieldValues = (e: any) => {
    setBondNumberList([]);
    setBondRequestsBondTypes([]);
    setWipProjects([]);
    setSelectedWipProject(null);

    const pindex = e.target.selectedIndex - 1;

    setSelectedProjIndex(pindex);
    formik.setFieldValue('projectName', e.target.value);
    formik.setFieldValue(
      'currentTotalContractValue',
      projectList[pindex]?.currentTotalContractValue
    );
    formik.setFieldValue('obligeeName', projectList[pindex]?.obligeeName);
    formik.setFieldValue(
      'projectStartDate',
      projectList[pindex]?.projectStartDate
    );
    formik.setFieldValue(
      'projectCompletionDate',
      projectList[pindex]?.projectCompletionDate
    );
    formik.setFieldValue(
      'projectLocation',
      projectList[pindex]?.projectLocation
    );

    formik.setFieldValue(
      'initialTotalContractValue',
      projectList[pindex]?.initialTotalContractValue
    );
    formik.setFieldValue('expiryDate', projectList[pindex]?.expiryDate);

    calculateOverage(Number(projectList[pindex]?.currentTotalContractValue));
    const projectStartDate = projectList[pindex]?.projectStartDate;

    if (
      typeof projectStartDate === 'string' &&
      !isNaN(Date.parse(projectStartDate))
    ) {
      const current = new Date(moment().format('YYYY-MM-DD'));
      const start = new Date(moment(projectStartDate).format('YYYY-MM-DD'));

      if (start.getTime() > current.getTime()) {
        formik.setFieldValue('bondStatus', 'Upcoming');
      } else if (start.getTime() < current.getTime()) {
        formik.setFieldValue('bondStatus', 'Active');
      }
    }

    if (projectList[pindex]?.source.toLowerCase() === 'wip') {
      const bonds: BondActivityBonds[] = [];
      projectList[pindex]?.bondNumber?.forEach((obj) => {
        bonds.push({ number: obj, bonds: [] });
      });

      setBondNumberList(bonds);
    } else {
      // if (
      //   projectList[pindex]?.source.toLowerCase() === 'bondform' ||
      //   projectList[pindex]?.source.toLowerCase() === 'bondapplication'
      // )
      if (pindex !== -1) {
        const wips = projectList.filter((p) => p.source === 'WIP');

        setWipProjects([...wips]);
      }
      handleBondRequest(pindex, bondNumberList);
    }
  };

  const calculateOverage = (currentTotalContractValue: number) => {
    const overageSCL =
      Number(singleContractLimit) - Number(currentTotalContractValue);
    formik.setFieldValue('overageSCL', overageSCL < 0 ? 'N/A' : overageSCL);
  };

  const handleBondRequest = (pindex: number, bondNum: BondActivityBonds[]) => {
    if (pindex !== -1) {
      if (
        projectList[pindex]?.BondType &&
        Array.isArray(projectList[pindex]?.BondType) &&
        projectList[pindex]?.BondType?.length! > 0
      ) {
        const bond_types = projectList[pindex]?.BondType;
        for (var i = 0; i < bondNum.length; i++) {
          for (var j = 0; j < bondNum[i].bonds.length; j++) {
            // let exist = bondNum[i].bonds.find((ele) =>
            //   bond_types.includes(ele.type)
            // );
            // console.log('exist===', exist);
            if (bond_types?.includes(bondNum[i].bonds[j].type)) {
              bond_types?.splice(
                bond_types?.indexOf(bondNum[i].bonds[j].type),
                1
              );
            }
            if (bond_types?.length === 0) break;
          }
          if (bond_types?.length === 0) break;
        }

        if (bond_types?.length! > 0) {
          let rbonds: any = [];
          bond_types?.forEach((obj) => rbonds.push({ type: obj }));

          setBondRequestsBondTypes([{ number: '', bonds: rbonds }]);
        } else setBondRequestsBondTypes([]);
      }
    }
  };

  const handleBonds = (
    newdata: BondActivityBonds[],
    removedBonds: string[]
  ) => {
    const existingBonds = [...bondNumberList];
    //remove bonds
    if (removedBonds.length > 0) {
      removedBonds.forEach((obj) => {
        const idx = existingBonds.findIndex((itm) => itm.number === obj);
        if (idx !== -1) existingBonds.splice(idx, 1);
      });
    }

    //set bonds on top with empty bond types
    if (existingBonds.length > 0) {
      existingBonds.forEach((obj) => {
        if (newdata.find((item) => item.number === obj.number) === undefined) {
          newdata.unshift(obj);
        }
      });
    }

    setBondNumberList(newdata);
    handleBondRequest(selectedProjIndex, newdata);
  };

  const handleWIPProjectChange = (proj: any) => {
    const pindex = proj.target.selectedIndex - 1;

    setSelectedWipProject(wipProjects[pindex]);

    const bonds: BondActivityBonds[] = [];
    wipProjects[pindex]?.bondNumber?.forEach((obj) => {
      if (bondNumberList.find((item) => item.number === obj) === undefined)
        bonds.push({ number: obj, bonds: [] });
    });

    setBondNumberList((prevItems) => [...prevItems, ...bonds]);
  };

  const unlinkBondNumbers = () => {
    Swal.fire({
      // title: 'Do you want to?',
      icon: 'info',
      showConfirmButton: true,
      customClass: {
        container: 'custom-swal',
      },
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      html: `Do you want to unlink following bond numbers? <br><b>${selectedWipProject?.bondNumber?.join(',')}</b><br>These bond numbers will be removed from '${formik.values.projectName}'`,
    }).then((result) => {
      if (result.isConfirmed === true) {
        selectedWipProject?.bondNumber?.forEach((obj) => {
          setBondNumberList((prevItems) =>
            prevItems.filter((item) => item.number !== obj)
          );
        });
        setSelectedWipProject(null);
      }
    });
  };

  return (
    <Flex
      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px;'}
      p="1.5em"
      borderRadius={'8px'}
      flexDirection={'column'}
      gap={5}
      color={'#114684'}
    >
      <Flex>
        <Heading size={'md'}>
          {selectedBondActivity === null ? 'Add' : 'Edit'} Bond Activity
        </Heading>
        <Spacer></Spacer>
        <IconButton
          isRound={true}
          variant="solid"
          fontSize={'18px'}
          aria-label="Exit"
          icon={<IoMdClose />}
          onClick={handleExit}
        />
      </Flex>

      <form onSubmit={formik.handleSubmit}>
        <Flex flexDirection={'column'} gap={5}>
          <Flex gap={5}>
            <Box w="32.5%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Project Name
              </Text>
              {selectedBondActivity === null && (
                <Select
                  name="projectName"
                  value={formik.values.projectName || ''}
                  onChange={(e: any) => {
                    setFieldValues(e);
                  }}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Project"
                  />
                  {Array.isArray(projectList) &&
                    projectList?.map(
                      (ele?: BondActivityProjects, index?: number) => (
                        <option
                          style={{ color: 'black' }}
                          key={index}
                          value={ele?.projectName ?? ''}
                          label={ele?.projectName ?? ''}
                        />
                      )
                    )}
                </Select>
              )}
              {selectedBondActivity !== null && (
                <Input
                  type="text"
                  name="projectName"
                  value={formik.values.projectName || ''}
                  isDisabled
                />
              )}

              {formik.touched.projectName && formik.errors.projectName && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.projectName}
                </div>
              )}
            </Box>
            {wipProjects && wipProjects.length > 0 && (
              <Box w="32.5%">
                <Flex gap={2} alignItems={'center'}>
                  <Text fontWeight="600" fontSize={13} pb="5px">
                    WIP Projects
                  </Text>
                  <Tooltip
                    withArrow
                    label="Choose a WIP project to link Bond Numbers."
                  >
                    <span style={{ cursor: 'pointer' }}>
                      <MdOutlineHelp size={16} />
                    </span>
                  </Tooltip>
                </Flex>

                <Select
                  name="wipProjects"
                  onChange={(e: any) => {
                    handleWIPProjectChange(e);
                  }}
                  isDisabled={selectedWipProject ? true : false}
                  value={selectedWipProject?.projectName || ''}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Project"
                  />
                  {Array.isArray(wipProjects) &&
                    wipProjects.map(
                      (ele?: BondActivityProjects, index?: number) => (
                        <option
                          key={index}
                          style={{ color: 'black' }}
                          value={ele?.projectName ?? ''}
                          label={ele?.projectName ?? ''}
                        />
                      )
                    )}
                </Select>
              </Box>
            )}
            {selectedWipProject &&
              selectedWipProject.bondNumber &&
              selectedWipProject.bondNumber?.length > 0 && (
                <Box w={'32.5%'} alignContent={'end'}>
                  <Button onClick={unlinkBondNumbers}>
                    Unlink Bond Numbers
                  </Button>
                </Box>
              )}
            {selectedBondActivity !== null &&
              formik.values.linkedByProjectId && (
                <Box w="32.5%">
                  <Text fontWeight="600" fontSize={13} pb="5px">
                    Linked WIP Project
                  </Text>
                  <Input
                    type="text"
                    name="linkedByProjectName"
                    value={formik.values.linkedByProjectName || ''}
                    isDisabled
                  />
                </Box>
              )}
          </Flex>
          <Flex gap={5}>
            <Box w="32.5%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Project Location
              </Text>
              <Input
                type="text"
                name="projectLocation"
                value={formik.values.projectLocation || ''}
                placeholder="Project Location"
                onChange={formik.handleChange}
              />
            </Box>
            <Box w="32.5%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Obligee Name
              </Text>
              <Input
                type="text"
                name="obligeeName"
                value={formik.values.obligeeName || ''}
                placeholder="Obligee Name"
                onChange={formik.handleChange}
              />
            </Box>
          </Flex>
          <Flex gap={5}>
            <Box w="32.5%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Project Start Date
              </Text>
              <DatePickerInput
                ref={projectStartDateRef}
                locale="en"
                placeholder="Project Start Date"
                className={`react-datepicker-component react-datepicker-input input gray-border`}
                showOnInputClick={true}
                onShow={() => addFocusEventListenerOnInput(projectStartDateRef)}
                onHide={() =>
                  removeFocusEventListenerOnInput(projectStartDateRef)
                }
                value={
                  formik.values.projectStartDate
                    ? formik.values.projectStartDate
                    : undefined
                }
                onChange={(date: Date | null) => {
                  formik.setFieldValue('projectStartDate', date);
                }}
              />
              {formik.touched.projectStartDate &&
                formik.errors.projectStartDate && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.projectStartDate}
                  </div>
                )}
            </Box>
            <Box w="32.5%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Project Completion Date
              </Text>
              <DatePickerInput
                ref={projectEndDateRef}
                locale="en"
                placeholder="Project Completion Date"
                className={`react-datepicker-component react-datepicker-input input gray-border`}
                showOnInputClick={true}
                value={
                  formik.values.projectCompletionDate !== null
                    ? formik.values.projectCompletionDate
                    : undefined
                }
                onChange={(date: Date | null) => {
                  formik.setFieldValue('projectCompletionDate', date);
                }}
                onShow={() => addFocusEventListenerOnInput(projectEndDateRef)}
                onHide={() =>
                  removeFocusEventListenerOnInput(projectEndDateRef)
                }
              />
              {formik.touched.projectCompletionDate &&
                formik.errors.projectCompletionDate && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.projectCompletionDate}
                  </div>
                )}
            </Box>
          </Flex>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Initial Total Contract Value
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  type="text"
                  name="initialTotalContractValue"
                  value={formik.values.initialTotalContractValue || ''}
                  placeholder="Initial Total Contract Value"
                  onChange={formik.handleChange}
                  onKeyDown={amountOnly}
                  isDisabled={disableInitialContract}
                />
              </InputGroup>
              {formik.touched.initialTotalContractValue &&
                formik.errors.initialTotalContractValue && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.initialTotalContractValue}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Current Total Contract Value
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  type="text"
                  name="currentTotalContractValue"
                  value={formik.values.currentTotalContractValue || ''}
                  placeholder="Current Total Contract Value"
                  onChange={(e) => {
                    formik.setFieldValue(
                      'currentTotalContractValue',
                      e.target.value
                    );
                    calculateOverage(Number(e.target.value));
                  }}
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.currentTotalContractValue &&
                formik.errors.currentTotalContractValue && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.currentTotalContractValue}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Overage of Single Contract Limit
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  type="text"
                  name="overage"
                  value={formik.values.overageSCL || ''}
                  disabled
                  placeholder="Overage of Single Contract Limit"
                />
              </InputGroup>
            </Box>
          </Flex>

          <Flex gap={5}>
            <Box w={'66.66%'} flexGrow={1}>
              <Flex
                border={'1px solid rgb(221 228 237)'}
                borderRadius={'6px 6px 0 0'}
              >
                <Text
                  textAlign={'center'}
                  fontWeight="600"
                  fontSize={13}
                  pb="5px"
                  width={'20%'}
                >
                  Bond Numbers
                </Text>
                <Text
                  width={'80%'}
                  textAlign={'center'}
                  fontWeight="600"
                  fontSize={13}
                  pb="5px"
                >
                  Bond Types
                </Text>
              </Flex>
              <Box
                border={'1px solid rgb(221 228 237)'}
                borderRadius={'0 0 6px 6px'}
                maxH={'300px'}
                overflowY={'auto'}
                borderTop={'none'}
                height={'88%'}
              >
                {bondRequestsBondTypes &&
                  bondRequestsBondTypes.map((item, index) => (
                    <Flex
                      gap={5}
                      key={index}
                      borderBottom={
                        index < 4 ? '1px solid rgb(221 228 237)' : undefined
                      }
                      alignItems={'center'}
                    >
                      <Box width={'20%'} padding={'5px'}></Box>
                      <Box
                        width={'80%'}
                        padding={'5px'}
                        borderLeft={'1px solid rgb(221 228 237)'}
                      >
                        <Flex gap={2} wrap={'wrap'}>
                          {item.bonds &&
                            item.bonds.map((bondtype, index2) => (
                              <Text
                                style={{
                                  backgroundColor: '#ededed',
                                  padding: '1px 10px 4px 10px',
                                  borderRadius: '20px',
                                  fontSize: '13px',

                                  color: '#646464',
                                }}
                                key={index2}
                              >
                                {bondtype.type}
                              </Text>
                            ))}
                        </Flex>
                      </Box>
                    </Flex>
                  ))}
                {bondNumberList &&
                  bondNumberList.map((bonds, index) => (
                    <Flex
                      gap={5}
                      key={index}
                      borderBottom={
                        index < 4 ? '1px solid rgb(221 228 237)' : undefined
                      }
                      alignItems={'center'}
                    >
                      <Box width={'20%'} padding={'5px'}>
                        <Text
                          color={'#114684'}
                          fontWeight={'600'}
                          fontSize={'13px'}
                        >
                          {bonds.number}
                        </Text>
                      </Box>
                      <Box
                        width={'80%'}
                        padding={'5px'}
                        borderLeft={'1px solid rgb(221 228 237)'}
                      >
                        <Flex gap={2} wrap={'wrap'}>
                          {bonds.bonds &&
                            bonds.bonds.map((bondtype, index2) => (
                              <Text
                                style={{
                                  backgroundColor: '#ededed',
                                  padding: '1px 10px 4px 10px',
                                  borderRadius: '20px',
                                  fontSize: '13px',

                                  color: '#646464',
                                }}
                                key={index2}
                              >
                                {bondtype.type}
                              </Text>
                            ))}
                        </Flex>
                        {bonds.bonds.length === 0 && (
                          <Text
                            color={'gray.300'}
                            fontSize={13}
                            fontWeight={'600'}
                            padding={'10px'}
                          >
                            No Bond Type
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  ))}
                {formik.values.projectName === '' && (
                  <Text
                    color={'gray.300'}
                    fontSize={13}
                    textAlign={'center'}
                    fontWeight={'600'}
                    padding={'10px'}
                  >
                    Select Project to view data
                  </Text>
                )}
                {formik.values.projectName !== '' &&
                  bondNumberList.length === 0 &&
                  bondRequestsBondTypes.length === 0 && (
                    <Text
                      color={'gray.300'}
                      fontSize={13}
                      textAlign={'center'}
                      fontWeight={'600'}
                      padding={'10px'}
                    >
                      No Records
                    </Text>
                  )}
              </Box>
            </Box>

            <Box
              w={'33.33%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              gap={5}
              flexGrow={1}
            >
              <Button
                onClick={onOpen}
                isDisabled={formik.values.projectName === ''}
              >
                Manage Bonds
              </Button>
              <Box>
                <Text fontWeight="600" fontSize={13} pb="5px">
                  Bond Status
                </Text>
                <Select
                  onChange={formik.handleChange}
                  name="bondStatus"
                  value={formik.values.bondStatus || ''}
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Status"
                  />
                  <option
                    value={'Active'}
                    style={{ color: 'black' }}
                    label="Active"
                  />
                  <option
                    value={'Cancelled'}
                    style={{ color: 'black' }}
                    label="Cancelled"
                  />
                  <option
                    value={'Completed'}
                    style={{ color: 'black' }}
                    label="Completed"
                  />
                  <option
                    value={'Expired'}
                    style={{ color: 'black' }}
                    label="Expired"
                  />
                  <option
                    value={'Upcoming'}
                    style={{ color: 'black' }}
                    label="Upcoming"
                  />
                </Select>
              </Box>
              <Box>
                <Text fontWeight="600" fontSize={13} pb="5px">
                  Issue Date
                </Text>
                <DatePickerInput
                  ref={issueDateRef}
                  locale="en"
                  placeholder="Issue Date"
                  className={`react-datepicker-component react-datepicker-input input gray-border`}
                  showOnInputClick={true}
                  onShow={() => addFocusEventListenerOnInput(issueDateRef)}
                  onHide={() => removeFocusEventListenerOnInput(issueDateRef)}
                  value={
                    formik.values.issueDate
                      ? formik.values.issueDate
                      : undefined
                  }
                  onChange={(date: Date | null) => {
                    formik.setFieldValue('issueDate', date);
                  }}
                />
                {formik.touched.issueDate && formik.errors.issueDate && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.issueDate}
                  </div>
                )}
              </Box>
            </Box>
          </Flex>

          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Indemnity and Security Conditions
              </Text>
              <CheckboxGroup
                value={formik.values.indemnityAndSecurityConditions || []}
                onChange={(value) => {
                  formik.setFieldValue('indemnityAndSecurityConditions', value);
                }}
              >
                <Stack
                  spacing={[1, 5]}
                  direction={['column', 'row']}
                  flexWrap={'wrap'}
                >
                  <Checkbox value="Collateral">Collateral</Checkbox>
                  <Checkbox value="Letter of Credit">Letter of Credit</Checkbox>
                  <Checkbox value="Guarantees">Guarantees</Checkbox>
                  <Checkbox value="Other">Other</Checkbox>
                  <input
                    type="file"
                    ref={inputFileIndemnityRef}
                    style={{ display: 'none' }}
                    onChange={(e: any) => onFileChange(e, 'attachments')}
                  />
                  {formik.values.indemnityAndSecurityConditions &&
                    formik.values.indemnityAndSecurityConditions?.length >
                      0 && (
                      <Button
                        size={'sm'}
                        colorScheme="yellow"
                        onClick={() => inputFileIndemnityRef.current?.click()}
                        isDisabled={S3UploadLoading}
                      >
                        Attach File
                      </Button>
                    )}
                </Stack>
              </CheckboxGroup>
              {formik.values.indemnityAndSecurityConditions?.includes(
                'Other'
              ) && (
                <Box pt="10px">
                  <Text fontWeight="600" fontSize={13} pb="5px">
                    Name
                  </Text>
                  <Input
                    type="text"
                    name="otherIndemnity"
                    onChange={formik.handleChange}
                    value={formik.values.otherIndemnity || ''}
                    placeholder="Indemnity and Security Condition Name"
                  />
                  {formik.touched.otherIndemnity &&
                    formik.errors.otherIndemnity && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.otherIndemnity}
                      </div>
                    )}
                </Box>
              )}

              {formik.values.indemnityAndSecurityConditions &&
                formik.values.indemnityAndSecurityConditions?.length > 0 && (
                  <Box>
                    {formik.values.attachments &&
                      formik.values.attachments.length > 0 && (
                        <Text fontWeight="600" fontSize={13} pb="5px" pt="5px">
                          Files
                        </Text>
                      )}

                    <Flex
                      width={'100%'}
                      borderRadius={'6px'}
                      direction={'column'}
                      border={
                        formik.values.attachments &&
                        formik.values.attachments.length > 0
                          ? '1px solid rgb(210, 213, 218)'
                          : 'none'
                      }
                    >
                      {Array.isArray(formik.values.attachments) &&
                        formik.values.attachments.map((items, index) => (
                          <Flex
                            key={index}
                            padding={'4px 5px'}
                            alignItems={'center'}
                            gap={2}
                            borderBottom={
                              index <
                              (formik?.values?.attachments ?? []).length - 1
                                ? '1px solid rgb(210, 213, 218)'
                                : undefined
                            }
                          >
                            <IoAttachSharp
                              style={{ flexGrow: 0, flexShrink: 0 }}
                              color="#909cab"
                              fontSize={'22px'}
                            />
                            <Text fontSize={'sm'}>{items.fileName}</Text>
                            <Spacer></Spacer>
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              size="sm"
                              fontSize={'14px'}
                              aria-label="Exit"
                              icon={<IoMdClose />}
                              onClick={() => removeFile(index, 'attachments')}
                            />
                          </Flex>
                        ))}
                    </Flex>
                  </Box>
                )}
            </Box>

            <Flex w="33.33%" gap={2} flexDirection={'column'}>
              <Text fontWeight="600" fontSize={13} pb="5px">
                Status Query Form Provided
              </Text>
              <RadioGroup
                name="statusQueryFormProvided"
                value={formik.values.statusQueryFormProvided || ''}
                onChange={(value) => {
                  formik.setFieldValue('statusQueryFormProvided', value);
                }}
              >
                <Stack direction="row">
                  <Radio value="no">No</Radio>
                  <Radio value="yes">Yes</Radio>
                  {formik.values.statusQueryFormProvided === 'yes' && (
                    <>
                      <input
                        type="file"
                        ref={inputFileQueryRef}
                        style={{ display: 'none' }}
                        onChange={(e: any) =>
                          onFileChange(e, 'queryFormAttachments')
                        }
                      />
                      <Button
                        ml={3}
                        size={'sm'}
                        colorScheme="yellow"
                        onClick={() => inputFileQueryRef.current?.click()}
                        isDisabled={S3UploadLoading}
                      >
                        Attach File
                      </Button>
                    </>
                  )}
                </Stack>
              </RadioGroup>

              {formik.values.statusQueryFormProvided === 'yes' && (
                <Flex direction={'column'}>
                  {(formik.values.queryFormAttachments ?? []).length > 0 && (
                    <Text fontWeight="600" fontSize={13} pb="5px">
                      Files
                    </Text>
                  )}

                  <Flex
                    width={'100%'}
                    borderRadius={'6px'}
                    direction={'column'}
                    border={
                      (formik.values.queryFormAttachments ?? []).length > 0
                        ? '1px solid rgb(210, 213, 218)'
                        : 'none'
                    }
                  >
                    {Array.isArray(formik.values.queryFormAttachments) &&
                      formik.values.queryFormAttachments.map((items, index) => (
                        <Flex
                          key={index}
                          padding={'4px 5px'}
                          alignItems={'center'}
                          gap={2}
                          borderBottom={
                            index <
                            (formik.values.queryFormAttachments ?? []).length -
                              1
                              ? '1px solid rgb(210, 213, 218)'
                              : undefined
                          }
                        >
                          <IoAttachSharp
                            style={{ flexGrow: 0, flexShrink: 0 }}
                            color="#909cab"
                            fontSize={'22px'}
                          />
                          <Text fontSize={'sm'}>{items.fileName}</Text>
                          <Spacer></Spacer>
                          <IconButton
                            isRound={true}
                            variant="ghost"
                            size="sm"
                            fontSize={'14px'}
                            aria-label="Exit"
                            icon={<IoMdClose />}
                            onClick={() =>
                              removeFile(index, 'queryFormAttachments')
                            }
                          />
                        </Flex>
                      ))}
                  </Flex>
                </Flex>
              )}
            </Flex>

            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="8px">
                Surety Bond Forms
              </Text>
              <input
                type="file"
                ref={inputFileSuretyBond}
                style={{ display: 'none' }}
                onChange={(e: any) =>
                  onFileChange(e, 'suretyBondFormsAttachments')
                }
              />
              <Button
                size={'sm'}
                colorScheme="yellow"
                onClick={() => inputFileSuretyBond.current?.click()}
                isDisabled={S3UploadLoading}
              >
                Attach File
              </Button>

              {(formik.values.suretyBondFormsAttachments ?? []).length > 0 && (
                <Flex direction={'column'}>
                  <Text fontWeight="600" fontSize={13} pb="5px" pt={'10px'}>
                    Files
                  </Text>

                  <Flex
                    width={'100%'}
                    borderRadius={'6px'}
                    direction={'column'}
                    border={'1px solid rgb(210, 213, 218)'}
                  >
                    {Array.isArray(formik.values.suretyBondFormsAttachments) &&
                      formik.values.suretyBondFormsAttachments.map(
                        (items, index) => (
                          <Flex
                            key={index}
                            padding={'4px 5px'}
                            alignItems={'center'}
                            gap={2}
                            borderBottom={
                              index <
                              (formik.values.suretyBondFormsAttachments ?? [])
                                .length -
                                1
                                ? '1px solid rgb(210, 213, 218)'
                                : undefined
                            }
                          >
                            <IoAttachSharp
                              style={{ flexGrow: 0, flexShrink: 0 }}
                              color="#909cab"
                              fontSize={'22px'}
                            />
                            <Text fontSize={'sm'}>{items.fileName}</Text>
                            <Spacer></Spacer>
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              size="sm"
                              fontSize={'14px'}
                              aria-label="Exit"
                              icon={<IoMdClose />}
                              onClick={() =>
                                removeFile(index, 'suretyBondFormsAttachments')
                              }
                            />
                          </Flex>
                        )
                      )}
                  </Flex>
                </Flex>
              )}
            </Box>
          </Flex>
          <Flex gap={10} justifyContent={'center'} pt={'2em'}>
            <Button onClick={handleExit} type="button" colorScheme="gray">
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              type="button"
              onClick={() => {
                formik.handleSubmit();
              }}
              isDisabled={S3UploadLoading}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
      <ManageBonds
        isOpen={isOpen}
        onClose={onClose}
        bondsList={bondNumberList}
        onSubmit={handleBonds}
        projectEndDate={
          formik.values.projectCompletionDate
            ? new Date(formik.values.projectCompletionDate)
            : null
        }
      ></ManageBonds>
    </Flex>
  );
};

export default BondActivityAdd;
