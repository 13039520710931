import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Documents/slice';
import initialState from './state';

export const getAnalyticalReportData = createAsyncThunk(
  'analyticalReportStore/getAnalyticalReportData',
  async (
    payload: {
      applicantId: number;
      analyticalReportRatio: string;
      analyticalReportPeriod: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { analyticalReportRatio, analyticalReportPeriod, applicantId } =
        payload;
      const res = await callAPI(
        `/analyticalReports/analytics`,
        'POST',
        {
          ratioName: analyticalReportRatio,
          duration: analyticalReportPeriod,
          applicantId,
        },
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateAnalyticalReport = createAsyncThunk(
  'analyticalReportStore/updateAnalyticalReport',
  async (
    payload: {
      applicantId: number;
      analyticalReportRatio: string;
      analyticalReportPeriod: string;
      updatedFields: Record<string, any>;
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        analyticalReportRatio,
        analyticalReportPeriod,
        applicantId,
        updatedFields,
      } = payload;
      const res = await callAPI(
        `/analyticalReports/saveModifiedFields`,
        'POST',
        payload,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const analyticalReportStore = createSlice({
  name: 'analyticalReportStore',
  initialState,
  reducers: {
    removeAnalyticalReportsType(state, action) {
      state.type = null;
      state.error = null;
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    // get bond request details
    builder
      .addCase(getAnalyticalReportData.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ANALYTICAL_REPORTS_DETAILS';
      })
      .addCase(getAnalyticalReportData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ANALYTICAL_REPORTS_DETAILS';
        state.reportDetails = action.payload?.data?.data;
      })
      .addCase(getAnalyticalReportData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ANALYTICAL_REPORTS_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateAnalyticalReport.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'UPDATE_ANALYTICAL_REPORTS_DETAILS';
      })
      .addCase(updateAnalyticalReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_ANALYTICAL_REPORTS_DETAILS';
        state.reportDetails = action.payload?.data?.data;
      })
      .addCase(updateAnalyticalReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_ANALYTICAL_REPORTS_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const analyticalReportSlice = analyticalReportStore.reducer;
export const { removeAnalyticalReportsType } = analyticalReportStore.actions;

export default analyticalReportSlice;
