import { useEffect, useState } from 'react';
import callAPI from '../utils/callAPI';

const useS3FileUpload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const uploadFilesInS3 = async (payload: FormData) => {
    try {
      setLoading(true);
      const response = await callAPI(
        // `http://192.168.1.44:3001/api/upload/file/bucket`,
        `${process.env.REACT_APP_BASE_URL}/upload/file/bucket`,
        'POST',
        payload,
        false,
        'multipart/form-data'
      );
      setResponse(response.data);
      return response.data;
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const uploadbulkFilesInS3 = async (payload: FormData) => {
    try {
      setLoading(true);
      const response = await callAPI(
        // `http://192.168.1.44:3001/api/upload/file/bucket`,
        `${process.env.REACT_APP_BASE_URL}/upload/file/bucket/bulk`,
        'POST',
        payload,
        false,
        'multipart/form-data'
      );
      setResponse(response.data.data);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup if needed
    };
  }, []); // You can add dependencies if needed

  return {
    S3UploadLoading: loading,
    S3UploadError: error,
    S3UploadResponse: response,
    uploadFilesInS3,
    uploadbulkFilesInS3,
  };
};

export default useS3FileUpload;
