import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
} from '../../data';

interface CalculationDisplayTangibleNetworthEffProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayTangibleNetworthEff: React.FC<
  CalculationDisplayTangibleNetworthEffProps
> = ({ label, calculationFields }) => {
  const {
    totalAsset,
    setTotalAsset,
    totalLiabilities,
    setTotalLiabilities,
    inTangibleAsset,
    setInTangibleAsset,
    setTangibleNetworth,
    reportReferenceData,
  } = useEfficiencyContext();

  const isTotalAssetOption = !!calculationFields?.totalAsset?.fields?.length;
  const isTotalAssetValue = !!calculationFields?.totalAsset?.value
    ? calculationFields?.totalAsset?.value
    : 0;
  const isTotalLiabilitiesOption =
    !!calculationFields?.totalLiabilities?.fields?.length;
  const isTotalLiabilitiesValue = !!calculationFields?.totalLiabilities?.value
    ? calculationFields?.totalLiabilities?.value
    : 0;
  const isInTangibleAssetOption =
    !!calculationFields?.inTangibleAsset?.fields?.length;
  const isInTangibleAssetValue = !!calculationFields?.inTangibleAsset?.value
    ? calculationFields?.inTangibleAsset?.value
    : 0;

  const totalAssetSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalAsset,
      calculationFields?.totalAsset,
      reportReferenceData
    );
  }, []);
  const assetsTotalInitialValue =
    isTotalAssetValue - totalAssetSelectedFieldSumInitial;

  const totalLiabilitiesSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
  }, []);
  const liabilitiesTotalInitialValue =
    isTotalLiabilitiesValue - totalLiabilitiesSelectedFieldSumInitial;

  const inTangibleAssetSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      inTangibleAsset,
      calculationFields?.inTangibleAsset,
      reportReferenceData
    );
  }, []);
  const intangibleAssetsTotalInitialValue =
    isInTangibleAssetValue - inTangibleAssetSelectedFieldSumInitial;

  const assetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalAsset,
      calculationFields?.totalAsset,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + assetsTotalInitialValue)?.toFixed(2)
    );
  }, [totalAsset, calculationFields?.totalAsset]);

  const liabilitiesTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + liabilitiesTotalInitialValue)?.toFixed(2)
    );
  }, [totalLiabilities, calculationFields?.totalLiabilities]);

  const inTangibleAssetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      inTangibleAsset,
      calculationFields?.inTangibleAsset,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + intangibleAssetsTotalInitialValue)?.toFixed(2)
    );
  }, [inTangibleAsset, calculationFields?.inTangibleAsset]);

  const result = useMemo(() => {
    console.log('result', assetsTotal, liabilitiesTotal, inTangibleAssetsTotal);
    return Number(
      (assetsTotal - liabilitiesTotal - inTangibleAssetsTotal).toFixed(2)
    );
  }, [assetsTotal, liabilitiesTotal, inTangibleAssetsTotal]);

  useEffect(() => {
    setTangibleNetworth(result);
  }, [result]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Total Assets (${assetsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalAsset?.fields!,
              reportReferenceData!
            )}
            value={totalAsset}
            onChange={setTotalAsset}
            placeholder="Select Total Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <MultiSelect
            label={`Total Liabilities (${liabilitiesTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalLiabilities?.fields!,
              reportReferenceData!
            )}
            value={totalLiabilities}
            onChange={setTotalLiabilities}
            placeholder="Select Total Liabilities items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <MultiSelect
            label={`Intangible Assets (${inTangibleAssetsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.inTangibleAsset?.fields!,
              reportReferenceData!
            )}
            value={inTangibleAsset}
            onChange={setInTangibleAsset}
            placeholder="Select Intangible Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
