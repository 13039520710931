import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BondApplicationAttributes } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Documents/slice';
import initialState from './state';

export const getBondApploicationDetails = createAsyncThunk(
  'bondApplicationStore/getBondApploicationDetails',
  async (
    payload: { id: number; isCreatedFromApplication?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { id, isCreatedFromApplication } = payload;
      if (!!isCreatedFromApplication) {
        const res = await callAPI(
          `bondApplication/getById/${id}?isApplicationBond=true`,
          'GET',
          null,
          true
        );
        return res;
      } else {
        const res = await callAPI(
          `bondApplication/getById/${id}`,
          'GET',
          null,
          true
        );
        return res;
      }
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getBondApploicationReference = createAsyncThunk(
  'bondApplicationStore/getBondApploicationReference',
  async (
    payload: { id: number; isCreatedFromApplication?: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { id, isCreatedFromApplication } = payload;
      if (!!isCreatedFromApplication) {
        const res = await callAPI(
          `bondApplication/getById/${id}?isApplicationBond=true`,
          'GET',
          null,
          true
        );
        return res;
      } else {
        const res = await callAPI(
          `bondApplication/getById/${id}`,
          'GET',
          null,
          true
        );
        return res;
      }
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const createBondApplication = createAsyncThunk(
  'bondApplicationStore/createBondApplication',
  async (
    { formData }: { formData: BondApplicationAttributes },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`bondApplication/create`, 'POST', formData);

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateBondApplication = createAsyncThunk(
  'bondApplicationStore/updateBondApplication',
  async (
    {
      formData,
      bondId,
      isCreatedFromApplication,
    }: {
      formData: BondApplicationAttributes;
      bondId: number;
      isCreatedFromApplication?: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      if (!!isCreatedFromApplication) {
        const res = await callAPI(
          `bondApplication/update/${bondId}?isApplicationBond=true`,
          'PUT',
          formData
        );

        return res;
      } else {
        const res = await callAPI(
          `bondApplication/update/${bondId}`,
          'PUT',
          formData
        );

        return res;
      }
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getWipBondApplicationDetails = createAsyncThunk(
  'bondApplicationStore/getWipBondApplicationDetails',
  async (payload: { applicantId: number }, { rejectWithValue }) => {
    try {
      const { applicantId } = payload;
      const res = await callAPI(
        `/user/applicant/wip/${applicantId}`,
        'GET',
        null,
        true
      );

      return res;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const bondApplicationStore = createSlice({
  name: 'bondApplicationStore',
  initialState,
  reducers: {
    removeBondApplicationType(state, action) {
      state.type = null;
      state.error = null;
      state.status = null;
      state.bondApplicationData = null;
    },
  },
  extraReducers: (builder) => {
    // get bond request details
    builder
      .addCase(getBondApploicationDetails.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_BOND_APPLICATION_FORM_DETAILS';
      })
      .addCase(getBondApploicationDetails.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_BOND_APPLICATION_FORM_DETAILS';
        state.bondApplicationData = action.payload.data?.data;
      })
      .addCase(getBondApploicationDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_BOND_APPLICATION_FORM_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(getBondApploicationReference.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_BOND_APPLICATION_REFERENCE_DETAILS';
      })
      .addCase(getBondApploicationReference.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_BOND_APPLICATION_REFERENCE_DETAILS';
        state.bondApplicationReferenceData = action.payload.data?.data;
      })
      .addCase(getBondApploicationReference.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_BOND_APPLICATION_REFERENCE_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(createBondApplication.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'CREATE_BOND_APPLICATION_FORM';
      })
      .addCase(createBondApplication.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'CREATE_BOND_APPLICATION_FORM';
        state.msg = action.payload.data?.msg;
        // state.bondApplicationData = action.payload.data;
      })
      .addCase(createBondApplication.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'CREATE_BOND_APPLICATION_FORM';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateBondApplication.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'UPDATE_BOND_APPLICATION_FORM_DETAILS';
      })
      .addCase(updateBondApplication.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_BOND_APPLICATION_FORM_DETAILS';
        state.msg = action.payload.data?.msg;
        // state.bondApplicationData = action.payload.data;
      })
      .addCase(updateBondApplication.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_BOND_APPLICATION_FORM_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(getWipBondApplicationDetails.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_WIP_BOND_DETAILS';
      })
      .addCase(getWipBondApplicationDetails.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_WIP_BOND_DETAILS';
        state.wipBondDetails = action.payload.data;
      })
      .addCase(getWipBondApplicationDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_WIP_BOND_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const bondApplicationSlice = bondApplicationStore.reducer;
export const { removeBondApplicationType } = bondApplicationStore.actions;

export default bondApplicationSlice;
