import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react';
import { Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import BondActivityReport from './BondActivityReport';
import ClientProfileReport from './ClientProfileReport';

const BondFacilityTracker = () => {
  const dispatch = useAppDispatch();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [selectedApplicant, setSelectedApplicant] = useState('');
  const [bondActivityEditMode, setBondActivityEditMode] = useState(false);
  const [clientProfileEditMode, setClientProfileEditMode] = useState(false);
  const [disableApplicant, setDisableApplicant] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>('bond');

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData) {
      dispatch(ApplicantBrokerage());
    } else {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    }
  }, [brokerageDashboard.applicantsByBrokrageData]);

  const handleApplicantChange = (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedApplicant(selectedId);
  };

  const handleEditModeBA = (editMode: boolean) => {
    setBondActivityEditMode(editMode);
    setDisableApplicant(editMode);
  };

  const handleEditModeCP = (editMode: boolean) => {
    setClientProfileEditMode(editMode);
    setDisableApplicant(editMode);
  };

  const handleTabChange = (tab: any) => {
    if (tab === 'client' && !bondActivityEditMode) setActiveTab(tab);
    else if (tab === 'bond' && !clientProfileEditMode) setActiveTab(tab);
  };

  return (
    <Flex direction={'column'} gap={5} p={5}>
      <Heading fontSize={'24px'} fontWeight={'600'}>
        Bond Facility Tracker
      </Heading>
      <Box maxW="max-content" display={'flex'}>
        <Select
          w="100%"
          name="applicantId"
          borderRadius="5px"
          bg={'#114684'}
          color={'white'}
          textAlign={'center'}
          onChange={handleApplicantChange}
          isDisabled={disableApplicant}
        >
          <option
            value={''}
            style={{ color: 'black' }}
            label="Select Applicant"
          />
          {Array.isArray(applicantOptions) &&
            applicantOptions?.map((user?: any, index?: number) => (
              <option
                style={{ color: 'black' }}
                key={index}
                value={user?.applicantId ?? ''}
                label={user?.companyName ?? ''}
              />
            ))}
        </Select>
      </Box>
      {selectedApplicant === '' && (
        <Text size={'xs'} fontWeight={'600'}>
          Choose an applicant to view its Bond Activity & Client Profile Report
        </Text>
      )}
      {selectedApplicant && (
        <Tabs
          defaultValue="bond"
          value={activeTab}
          onTabChange={(e) => {
            handleTabChange(e);
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="bond">Bond Activity Report</Tabs.Tab>
            <Tabs.Tab value="client">Client Profile Report</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="bond">
            <Box p={'2em 0'}>
              <BondActivityReport
                applicantId={selectedApplicant}
                onEditMode={handleEditModeBA}
              ></BondActivityReport>
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="client">
            <Box p={'2em 0'}>
              <ClientProfileReport
                applicantId={selectedApplicant}
                onEditMode={handleEditModeCP}
              ></ClientProfileReport>
            </Box>
          </Tabs.Panel>
        </Tabs>
      )}
    </Flex>
  );
};

export default BondFacilityTracker;
