import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
} from '../../data';

interface CalculationDisplayRTNrops {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayRTN: React.FC<CalculationDisplayRTNrops> = ({
  label,
  calculationFields,
}) => {
  const {
    totalRevenue,
    setTotalRevenue,
    tangibleNetworth,
    reportReferenceData,
  } = useEfficiencyContext();

  const isTotalRevenueOption =
    !!calculationFields?.totalRevenue?.fields?.length;
  const isTotalRevenueValue = !!calculationFields?.totalRevenue?.value
    ? calculationFields?.totalRevenue?.value
    : 0;

  const totalRevenueSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalRevenue,
      calculationFields?.totalRevenue,
      reportReferenceData
    );
  }, []);
  const revenueTotalInitialValue =
    isTotalRevenueValue - totalRevenueSelectedFieldSumInitial;

  const revenueTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalRevenue,
      calculationFields?.totalRevenue,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + revenueTotalInitialValue)?.toFixed(2)
    );
  }, [totalRevenue, calculationFields?.totalRevenue]);

  // const isRevenueTangibleNetworthOption =
  //   !!calculationFields?.revenueTangibleNetworth?.fields?.length;
  // const isRevenueTangibleNetworthValue = !!calculationFields
  //   ?.revenueTangibleNetworth?.value
  //   ? calculationFields?.revenueTangibleNetworth?.value
  //   : 0;

  // const revenueTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalRevenue,
  //       calculationFields?.totalRevenue,
  //       reportReferenceData
  //     ) + (isTotalRevenueOption ? 0 : isTotalRevenueValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalRevenue, calculationFields?.totalRevenue]);

  // const revenueTangibleNetworthTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       revenueTangibleNetworth,
  //       calculationFields?.tangibleNetworth,
  //       reportReferenceData
  //     ) +
  //     (isRevenueTangibleNetworthOption ? 0 : isRevenueTangibleNetworthValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [revenueTangibleNetworth, calculationFields?.tangibleNetworth]);

  const result = useMemo(() => {
    const tangibleNetworthValue = !!tangibleNetworth ? tangibleNetworth : 1;
    return Number((revenueTotal / tangibleNetworthValue).toFixed(2));
  }, [revenueTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Revenue ${revenueTotal}`}
            data={getOptionWithPLBSReports(
              calculationFields?.totalRevenue?.fields!,
              reportReferenceData!
            )}
            value={totalRevenue}
            onChange={setTotalRevenue}
            placeholder="Select Revenue items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label={`Tangible Networth (${revenueTangibleNetworthTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.tangibleNetworth?.fields!,
              reportReferenceData!
            )}
            value={revenueTangibleNetworth}
            onChange={setRevenueTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          /> */}
          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
