import {
  BondActivity,
  ClientProfile,
} from 'src/Pages/BrokerPages/BondFacilityTracker/model';
import { APIStatus } from 'src/types/unions';

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?: 'GET_BOND_ACTIVITY_DETAILS' | 'GET_CLIENT_PROFILE_DETAILS' | null;
  bondActivityDetail: BondActivity[] | null;
  clientProfileDetails: ClientProfile[] | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  bondActivityDetail: null,
  clientProfileDetails: null,
  msg: null,
};

export default initialState;
