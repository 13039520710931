import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface LeverageContextType {
  totalEquity: string[];
  setTotalEquity: React.Dispatch<React.SetStateAction<string[]>>;
  intangibleAssets: string[];
  setIntangibleAssets: React.Dispatch<React.SetStateAction<string[]>>;
  longTermAssets: string[];
  setLongTermAssets: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  totalAssets: string[];
  setTotalAssets: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
    }>
  >;
}

// Create Context with Default Values
const LeverageContext = createContext<LeverageContextType | undefined>(
  undefined
);

// Define Props for Provider
interface LeverageContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const LeverageProvider: React.FC<LeverageContextProviderProps> = ({
  children,
}) => {
  // Total Equity
  // Intangible Assets
  // Long Term Assets
  // Tangible Networth
  // Total Liabilities
  // Liabilities Tangible Networth
  const [totalEquity, setTotalEquity] = useState<string[]>([]);
  const [intangibleAssets, setIntangibleAssets] = useState<string[]>([]);
  const [totalAssets, setTotalAssets] = useState<string[]>([]);
  const [longTermAssets, setLongTermAssets] = useState<string[]>([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }>({ profitAndLossData: {}, balanceSheetData: {} });

  return (
    <LeverageContext.Provider
      value={{
        totalEquity,
        setTotalEquity,
        intangibleAssets,
        setIntangibleAssets,
        longTermAssets,
        setLongTermAssets,
        tangibleNetworth,
        setTangibleNetworth,
        totalAssets,
        setTotalAssets,
        totalLiabilities,
        setTotalLiabilities,
        reportReferenceData,
        setReportReferenceData,
      }}
    >
      {children}
    </LeverageContext.Provider>
  );
};

// Custom Hook for Context Access
export const useLeverageContext = (): LeverageContextType => {
  const context = useContext(LeverageContext);
  if (!context) {
    throw new Error(
      'useLeverageContext must be used within an LeverageProvider'
    );
  }
  return context;
};
