import { Box, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getAllPlans, removeType } from 'src/Redux/Plans/slice';
import { planCategory, planPayType } from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import { getSuretyPlans } from 'src/utils/helpers';
import './components/Plancard.css';
import SuretyPlanCard from './components/SuretyPlanCard';
import './swiper-local.css';

const SuretyPlans = () => {
  const dispatch = useAppDispatch();
  const plansStore = useAppSelector((state) => state.plans);
  const [planPayType, setPlanPayType] = useState<planPayType>('yearly');
  const [planCategory, setPlanCategory] =
    useState<planCategory>('subscription');

  // const [plan1, setPlans1] = useState(oneTimePurchasePlans);
  // const [faqsData, setFaqsData] = useState<IFaqs[] | null>(null);
  const [loaders, setLoaders] = useState({
    faq: false,
    plans: false,
  });
  const toast = useAppToast();
  const brokerageUserAddress =
    getSuretyPlans(plansStore.plans)?.[0]?.prices[0]?.currency === 'cad'
      ? 'CAD'
      : 'USD';

  // const toggleBillingPeriod = () => {
  //   setPlanPayType((val) => (val === 'yearly' ? 'monthly' : 'yearly'));
  // };

  useEffect(() => {
    // dispatch(getFaqs());
    // if (!plansStore.faqsData?.length) dispatch(getFaqs());
    if (!plansStore.plans.length) dispatch(getAllPlans());
  }, []);

  useEffect(() => {
    const { status, type, error, stripeURL } = plansStore;

    // if (status === 'loading' && type === 'GET_FAQS') {
    //   alert('sdsd');
    // }

    switch (status) {
      case 'loading': {
        // if (type === 'GET_FAQS') setLoaders((data) => ({ ...data, faq: true }));
        if (type === 'GET_PLANS')
          setLoaders((data) => ({ ...data, plans: true }));
        break;
      }
      case 'succeed': {
        // if (type === 'GET_FAQS') {
        //   // setFaqsData(faqsData!);
        //   setLoaders((data) => ({ ...data, faq: false }));
        // }
        if (type === 'GET_PLANS') {
          setLoaders((data) => ({ ...data, plans: false }));
        }
        if (type === 'PURCHASE_SURETY_PLAN' && stripeURL) {
          redirectStripeURL(stripeURL);
        }
        break;
      }

      case 'failed': {
        if (type === 'PURCHASE_SURETY_PLAN') {
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }
    }

    return () => {
      if (type === 'PURCHASE_SURETY_PLAN') {
        dispatch(removeType({}));
      }
    };
  }, [plansStore.status, plansStore.type]);

  const redirectStripeURL = async (stripeURL: string) => {
    console.log('stripeURL', stripeURL);
    // await dispatch(removeType({}));
    // navigate(`${stripeURL}`);
    window.open(`${stripeURL}`, '_blank', 'noopener,noreferrer');
  };

  console.log('plansStore', plansStore?.plans);

  return (
    <Box py={50}>
      <VStack spacing={2} alignItems={'center'}>
        <Heading
          as="h1"
          textAlign={'center'}
          fontSize="4xl"
          mb={4}
          px={2}
          position={'relative'}
        >
          Plans that fit your need
        </Heading>

        <Box position={'relative'}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            pb={12}
            gap={4}
            rowGap={6}
            maxW={'90rem'}
            mx={'auto'}
            flexWrap={'wrap'}
            w={'100%'}
            alignItems={'stretch'}
          >
            {!loaders.plans &&
              getSuretyPlans(plansStore.plans)?.map((plan) => (
                <SuretyPlanCard key={plan.name} plan={plan} />
              ))}
            {loaders.plans &&
              Array(4)
                .fill(0)
                .map((el) => (
                  <Skeleton
                    h={'300px'}
                    w={'220px'}
                    // isLoaded={!loaders.plans}
                    // fadeDuration={1}
                    shadow="base"
                    borderWidth="1px"
                    borderColor={'gray.300'}
                    borderRadius={'xl'}
                  />
                ))}
          </Box>
          {!loaders.plans && (
            <Box position={'absolute'} top={0} right={-4}>
              <Text fontSize={12} transform={'translateX(100%)'}>
                * Prices in {brokerageUserAddress}
              </Text>
            </Box>
          )}
        </Box>
      </VStack>
      {/* {!loaders?.plans && planCategory === 'subscription' && (
        <Container>
          <Text
            textAlign={'start'}
            fontSize={'20px'}
            fontWeight={'600'}
            mb={'5px'}
          >
            {planPayType === 'monthly'
              ? 'Monthly Subscription'
              : 'Annual Subscription'}
          </Text>
          <Text textAlign={'start'} fontSize={'12px'}>
            {planPayType === 'monthly'
              ? `When you purchase a Monthly subscription, you will be charged on the same date each month, and the subscription will automatically renew unless canceled.`
              : `When you purchase an Annual subscription, the full amount will be charged upfront, and it will automatically renew each year on the same date unless cancelled.`}
          </Text>
        </Container>
      )} */}

      {/* FAQS */}

      {/* <Container>
        <Title align="center" mb={16}>
          FAQ
        </Title>
        {loaders.faq && (
          <Flex justifyItems={'center'}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              mx={'auto'}
            />
          </Flex>
        )}
        {!loaders.faq && plansStore.faqsData && (
          <FaqSimple faqs={plansStore.faqsData} />
        )}
      </Container> */}
    </Box>
  );
};

export default SuretyPlans;
