import { Button, Flex } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useEBITDA } from 'src/context/EBITDAContext';
import { calculateEBITDA } from '../../data/calculations';
import CalculationReferenceFieldsNew from './CalculationReferenceFieldsNew';

// const initialData: any = {
//   // TotalRevenue: [
//   //   { label: 'xyz', value: 23, id: 'e334drer1' },
//   //   { label: 'xyz2', value: 23, id: 'e334drer2' },
//   // ],
//   TotalRevenue: 345,
//   CoGS: [{ label: 'abc', value: 100, id: 'cogs1' }],
//   TotalOperatingExpenses: [{ label: 'opex', value: 50, id: 'opex1' }],
//   TotalOtherIncome: [{ label: 'other', value: 10, id: 'other1' }],
//   TotalOtherExpenses: [{ label: 'otherExp', value: 5, id: 'otherExp1' }],
//   TotalIncomeTaxes: [{ label: 'tax', value: 20, id: 'tax1' }],
//   TotalCurrentAssets: [{ label: 'current', value: 200, id: 'current1' }],
//   TotalLongTermAssets: [{ label: 'longTerm', value: 500, id: 'longTerm1' }],
//   TotalAssets: [{ label: 'assets', value: 700, id: 'assets1' }],
//   TotalCurrentLiabilities: [{ label: 'currLiab', value: 150, id: 'currLiab1' }],
//   TotalLongTermLiabilities: [
//     { label: 'longLiab', value: 300, id: 'longLiab1' },
//   ],
//   // TotalLiabilities: [{ label: 'liab', value: 450, id: 'liab1' }],
//   TotalLiabilities: 345,
//   TotalEquity: [{ label: 'equity', value: 250, id: 'equity1' }],
// };
const initialData: any = {
  TotalRevenue: 1500,
  CoGS: 45,
  TotalOperatingExpenses: 122,
  TotalOtherIncome: 23,
  TotalOtherExpenses: 544,
  TotalIncomeTaxes: 344,
  TotalCurrentAssets: 124,
  TotalLongTermAssets: 678,
  TotalCurrentLiabilities: 342,
  TotalLongTermLiabilities: 234,
  TotalLiabilities: 564,
  TotalEquity: 234,
  TotalEquityOfCY: 122,
  TotalEquityOfPY: 344,
  TotalAssets: 341,
  TotalAssetsOfCY: 254,
  TotalAssetsOfPY: 564,
};

const EditAnalyticalReportModal = ({
  isOpen,
  onClose,
  TData,
}: {
  isOpen: boolean;
  onClose: () => void;
  TData: AnalyticalReportDetails;
}) => {
  const { selectedNetIncomeItems } = useEBITDA();
  const [calculationReferenceFields, setCalculationReferenceFields] = useState<
    Record<string, any>
  >({});
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: Record<string, any>[];
    balanceSheetData: Record<string, any>[];
  }>({ profitAndLossData: [], balanceSheetData: [] });

  useEffect(() => {
    if (isOpen) {
      setCalculationReferenceFields(TData?.editable);
      // setReportReferenceData({
      //   profitAndLossData: TData?.profitAndLossData,
      //   balanceSheetData: TData?.balanceSheetData,
      // });
    }
  }, [isOpen]);

  const ebitaNetIncome = useMemo(() => {
    const total = selectedNetIncomeItems.reduce((sum, itemId) => {
      const item = [
        ...reportReferenceData.profitAndLossData,
        ...reportReferenceData.balanceSheetData,
      ].find((data) => data.id === itemId);
      return sum + (item ? Number(item.Total) : 0);
    }, 0);
    return total;
  }, [selectedNetIncomeItems]);

  const calculatedEBITDAOtherFields = useMemo(() => {
    const ebitdaSubFields = calculateEBITDA(calculationReferenceFields);
    return ebitdaSubFields;
  }, [calculationReferenceFields, selectedNetIncomeItems]);

  console.log(
    'ebitaNetIncome',
    ebitaNetIncome,
    calculatedEBITDAOtherFields,
    selectedNetIncomeItems
  );

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit Analytical Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'750px'}
      styles={{
        title: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }}
    >
      <form>
        {/* <CalculationReferenceFields
          calculationFields={initialData}
          onDelete={handleDelete}
        /> */}
        <CalculationReferenceFieldsNew
          calculationFields={calculationReferenceFields}
          reportReferenceData={reportReferenceData}
        />

        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            // isDisabled={isSubmitLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            loadingText="Update"
            // isDisabled={!formik.values?.reasonOfRejection?.length}
            // isLoading={isSubmitLoader}
            onClick={() => {
              onClose();
            }}
          >
            Update
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditAnalyticalReportModal;
