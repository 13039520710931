import { Stack } from '@mantine/core';
import {
  calculateBacklogToWorkingCapitalRatio,
  calculateQuickRatio,
  calculateWorkingCapital,
  calculateWorkingCapitalRatio,
  calculateWorkingCapitalTurnover,
} from '../../data/calculations';
import { CalculationDisplay } from '../ProfitabilityRatio/CalculationDisplay';
import { CalculationDisplayBTTNW } from './CalculationDisplayBTTNW';
import { CalculationDisplayDaysOfCash } from './CalculationDisplayDaysOfCash';
import { CalculationDisplayOverbillingsToNetCash } from './CalculationDisplayOverbillingsToNetCash';
import { CalculationDisplayTangibleNetworth } from './CalculationDisplayTangibleNetworth';

export interface LiquidityCalculationReferenceFields {
  calculationFields: Record<string, any>;
}

const LiquidityCalculationReferenceFields: React.FC<
  LiquidityCalculationReferenceFields
> = ({ calculationFields }) => {
  return (
    <Stack spacing="xl">
      <CalculationDisplayDaysOfCash
        label="Days of Cash"
        calculationFields={calculationFields}
        // result={calculateDaysOfCash(calculationFields)}
      />

      <CalculationDisplay
        label="Working Capital Ratio"
        inputs={[
          {
            label: 'Current Assets',
            value: calculationFields?.currentAssets?.value,
          },
          {
            label: 'Current Liabilities',
            value: !!calculationFields?.currentLiabilities?.value
              ? calculationFields?.currentLiabilities?.value
              : 1,
          },
        ]}
        operators={['÷']}
        result={calculateWorkingCapitalRatio(calculationFields)}
      />

      <CalculationDisplay
        label="Working Capital $"
        inputs={[
          {
            label: 'Current Assets',
            value: calculationFields?.currentAssets?.value,
          },
          {
            label: 'Current Liabilities',
            value: calculationFields?.currentLiabilities?.value,
          },
        ]}
        operators={['-']}
        result={calculateWorkingCapital(calculationFields)}
      />

      <CalculationDisplay
        label="Working Capital Turnover"
        inputs={[
          {
            label: 'Net Annual Sales',
            value: calculationFields?.netAnnualSales?.value,
          },
          {
            label: 'Average Working Capital',
            value: !!calculationFields?.averageWorkingCapital?.value
              ? calculationFields?.averageWorkingCapital?.value
              : 1,
          },
        ]}
        operators={['÷']}
        result={calculateWorkingCapitalTurnover(calculationFields)}
      />

      <CalculationDisplay
        label="Backlog to working capital ratio"
        inputs={[
          { label: 'Backlog', value: calculationFields?.backlog?.value },
          {
            label: 'Working Capital',
            value: !!calculateWorkingCapital(calculationFields)
              ? calculateWorkingCapital(calculationFields)
              : 1,
          },
        ]}
        operators={['÷']}
        result={calculateBacklogToWorkingCapitalRatio(calculationFields)}
      />

      <CalculationDisplay
        label="Quick Ratio"
        inputs={[
          {
            label: 'Quick Assets',
            value: calculationFields?.quickAssets?.value,
          },
          {
            label: 'Current Liabilities',
            value: !!calculationFields?.currentLiabilities?.value
              ? calculationFields?.currentLiabilities?.value
              : 1,
          },
        ]}
        operators={['÷']}
        result={calculateQuickRatio(calculationFields)}
      />

      {/* <CalculationDisplay
        label="Backlog To Tangibal Networth"
        inputs={[
          { label: 'Backlog', value: calculationFields.backlog?.value },
          {
            label: 'Tangibal Networth',
            value: calculationFields.tangibleNetworth?.value,
          },
        ]}
        operators={['÷']}
        result={calculateBacklogToTangibalNetworth(calculationFields)}
      />

      <CalculationDisplay
        label="Overbillings To Net Cash"
        inputs={[
          {
            label: 'Billings in Excess of Costs',
            value: calculationFields.billingInExcessOfCosts?.value,
          },
          {
            label: 'Cash and Cash Equivalents',
            value: calculationFields.cashEquivalents?.value,
          },
        ]}
        operators={['÷']}
        result={calculateOverBillingsToNetCash(calculationFields)}
      /> */}

      <CalculationDisplayTangibleNetworth
        label="Tangible Networth"
        calculationFields={calculationFields}
      />

      <CalculationDisplayBTTNW
        label="Backlog To Tangibal Networth"
        calculationFields={calculationFields}
      />

      <CalculationDisplayOverbillingsToNetCash
        label="Overbillings To Net Cash"
        calculationFields={calculationFields}
      />
    </Stack>
  );
};

export default LiquidityCalculationReferenceFields;
