import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useEBITDA } from 'src/context/EBITDAContext';

interface FinancialDataItem {
  id: string;
  Label: string;
  Total: string | number;
}

interface EBITDACalculationDisplayProps {
  label: string;
  calculationFields: Record<string, any>;
  reportReferenceData: {
    profitAndLossData: Record<string, any>[];
    balanceSheetData: Record<string, any>[];
  };
  result: number | null;
}

export const EBITDACalculationDisplay: React.FC<
  EBITDACalculationDisplayProps
> = ({ label, calculationFields, reportReferenceData, result }) => {
  const { selectedNetIncomeItems, setSelectedNetIncomeItems } = useEBITDA();

  const calculateNetIncome = () => {
    return selectedNetIncomeItems.reduce((sum, itemId) => {
      const item = [
        ...reportReferenceData.profitAndLossData,
        ...reportReferenceData.balanceSheetData,
      ].find((data) => data.id === itemId);
      return sum + (item ? Number(item.Total) : 0);
    }, 0);
  };

  const ebitaNetIncome = useMemo(() => {
    const total = selectedNetIncomeItems.reduce((sum, itemId) => {
      const item = [
        ...reportReferenceData.profitAndLossData,
        ...reportReferenceData.balanceSheetData,
      ].find((data) => data.id === itemId);
      return sum + (item ? Number(item.Total) : 0);
    }, 0);

    return total;
  }, [selectedNetIncomeItems]);

  console.log('answer total', ebitaNetIncome);

  const groupedOptions = useMemo(() => {
    const formatOptions = (data: Record<string, any>[], group: string) =>
      data.map((item) => ({
        value: item.id,
        label: `${item.Label}: ${typeof item.Total === 'number' ? item.Total.toFixed(2) : item.Total}`,
        group,
      }));

    return [
      ...formatOptions(reportReferenceData.profitAndLossData, 'Profit & Loss'),
      ...formatOptions(reportReferenceData.balanceSheetData, 'Balance Sheet'),
    ];
  }, [reportReferenceData]);

  const additionalFields = [
    'Interest',
    'Taxes',
    'Depreciation',
    'Amortization',
  ];

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} align="left" mb="xs">
        {label}
      </Text>

      <Flex direction="column" gap="md">
        <Flex align="center" gap="xs">
          <MultiSelect
            data={groupedOptions}
            label="Net Income Components"
            placeholder="Select items"
            searchable
            nothingFound="No options"
            maxDropdownHeight={400}
            value={selectedNetIncomeItems}
            onChange={setSelectedNetIncomeItems}
            w={'50%'}
          />
          <Text size="xl" weight={700} style={{ marginTop: 'auto' }}>
            +
          </Text>
        </Flex>

        <Flex wrap="wrap" align="center" justify={'flex-start'} gap="xs">
          {additionalFields.map((field, index) => (
            <Flex key={field} align="center" gap="xs">
              {index > 0 && (
                <Text size="xl" weight={700}>
                  +
                </Text>
              )}
              <TextInput
                label={field}
                value={calculationFields[field] ?? 0}
                disabled
                styles={{ input: { textAlign: 'right' } }}
              />
            </Flex>
          ))}

          <Text size="xl" weight={700}>
            =
          </Text>

          <TextInput
            label="Result"
            value={(result ?? 0) + (ebitaNetIncome ?? 0)}
            disabled
            styles={{ input: { textAlign: 'right' } }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
