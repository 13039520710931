import {
  Box,
  Button,
  Container,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CiCircleCheck } from 'react-icons/ci';
import { LiaPagerSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BASEURL } from '../../../App';
import { AccountantAllData } from '../../../Redux/Applicant/Accountant/Accountant';
import { AccountantMail } from '../../../Redux/Applicant/Qbo/AccountantQbo';
import { PermissionCheckQbo, Qboauth } from '../../../Redux/Applicant/Qbo/Qbo';
import { RootState } from '../../../Redux/Store';

function ApplicantQBO() {
  const [applicationId, setApplicationId] = useState(0);
  const dispatch = useDispatch();
  const qboState = useSelector((state: RootState) => state.qbo);
  const Toast = useToast();
  const [OngoingData, setOngoingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userData: any = useSelector(
    (state: RootState) => state.accountant.user
  );
  const [selectedOption, setSelectedOption] = useState('');

  let userGetidData = userData && userData.data;
  let legalname = userGetidData && userGetidData?.name;

  let defaultLabel =
    legalname && legalname.length !== undefined
      ? legalname
      : 'Select Brokerage';
  let tabelDatass = userGetidData && userGetidData.brokerage;
  let dataArray = [];
  if (tabelDatass) {
    dataArray.push(tabelDatass);
  }

  useEffect(() => {
    if (userGetidData?.brokerage?.brokerageId) {
      setSelectedOption(userGetidData?.brokerage?.brokerageId);
    }
  }, [userGetidData?.brokerage]);

  useEffect(() => {
    fetchAccountAllData();
  }, []);

  const fetchAccountAllData = async () => {
    if (!!localStorage.getItem('Token')) {
      let actionResult = await dispatch(AccountantAllData() as any);
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };
  const HandelAccountant = () => {
    if (!selectedOption) {
      return;
    }
    const requestData = {
      accountantId: selectedOption,
    };
    dispatch(AccountantMail(requestData) as any)
      .then((response: any) => {
        const responsedata = response?.payload;
        let res = responsedata?.msg;

        if (responsedata?.status === 200) {
          Toast({
            title: responsedata.data,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          if (responsedata?.status === 401) {
            navigate('/login');
          } else {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      })

      .catch((error: any) => {
        const responsedata = error.payload;
        Toast({
          title: responsedata.data,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      })
      .finally(() => {
        fetchAccountAllData();
      });
  };
  const HandelLogin = async () => {
    let res = '';
    try {
      const actionResult: any = await dispatch(Qboauth() as any);

      const response = actionResult?.payload;
      window.open(response?.data);
      res = response?.msg;
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      } else {
        Toast({
          title: response?.msg,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      }
    } catch (error: any) {
      if (res) {
        Toast({
          title: res,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      }
    }
  };

  // fetch ongoing data
  const fetchOnGoingData = async () => {
    try {
      const id = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(
        `${BASEURL}/application/ongoing/${id}`,
        config
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const applicationId = result?.data?.applicationId;
      setApplicationId(applicationId);
      // let OngoingData = result?.data
      setOngoingData(result?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!applicationId) {
      fetchOnGoingData();
    }
  }, [applicationId]);

  useEffect(() => {
    // Permission check qbo
    dispatch(PermissionCheckQbo(null) as any);
  }, []);

  if (qboState.loading === true) {
    return <ApplicantQboLoading />;
  }

  if (qboState.qboAccountLinked) {
    console.log('qbo=', qboState);
    return <ApplicantQboLinked qboState={qboState} />;
  }

  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom="auto"
        p="20px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={20}
          >
            Connect to QBO
          </Text>
        </Box>

        <Box mt="20px" mb={10}>
          <Button
            type="submit"
            bg="#114684"
            color="white"
            w="100%"
            _hover={{ bg: '#114684', color: 'white' }}
            onClick={HandelLogin}
          >
            Connect to QBO myself
          </Button>
        </Box>

        <Text
          fontWeight="600"
          color="#114684"
          display="flex"
          justifyContent="start"
          textAlign="center"
          fontSize={18}
          mb={3}
          mt={5}
        >
          Select accountant
        </Text>
        <Box display="flex" mb={['20px', '5px']}>
          <Box
            mr={2}
            fontSize={25}
            color="#114684"
            m={0}
            border="1.5px solid #0000001a"
            borderRadius="5px 0px 0px 5px"
          >
            <LiaPagerSolid
              style={{
                marginTop: '7px',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            />
          </Box>

          <Select
            name="userData.accountantId"
            onChange={(e) => setSelectedOption(e.target.value)}
            borderRadius="0px 5px 5px 0px"
            fontSize={'13px'}
          >
            <option value="" label="Select accountant" />

            {Array.isArray(userGetidData) &&
              userGetidData.map((user: any, index: number) => (
                <option
                  key={index}
                  value={user.accountantId}
                  label={user.name}
                ></option>
              ))}
          </Select>
        </Box>
        <Box mt="20px">
          <Button
            onClick={HandelAccountant}
            bg="#114684"
            color="white"
            w="100%"
            _hover={{ bg: '#114684', color: 'white' }}
            disabled={!selectedOption}
            cursor={!selectedOption ? 'not-allowed' : 'pointer'}
          >
            Request access to the accountant
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default ApplicantQBO;

const ApplicantQboLoading = () => {
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom={10}
        minHeight={200}
        p="0px 22px 0px 22px"
        borderRadius="20px"
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <AiOutlineLoading3Quarters />
      </Container>
    </>
  );
};

const ApplicantQboLinked = ({ qboState }: any) => {
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom={10}
        minHeight={200}
        p="0px 22px 0px 22px"
        borderRadius="20px"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CiCircleCheck size={50} color="green" />
        <Text
          fontWeight="bold"
          color="#114684"
          textAlign="center"
          fontSize={30}
          mt={5}
        >
          Already connected to QBO
        </Text>

        <Text
          color="#114684"
          textAlign="center"
          fontSize={16}
          pt="20px"
          fontWeight={500}
        >
          <span style={{ fontWeight: 'bold' }}> QuickBooks company name: </span>{' '}
          {qboState.qboCompanyName}
        </Text>
      </Container>
    </>
  );
};
