import { Button, Flex } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { updateAnalyticalReport } from 'src/Redux/Broker/AnalyticalReports/slice';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import { useAnalyticalReportContext } from '../..';
import {
  updateDataWithSelectedItemsAndSum,
  updateSelectedItems,
} from '../../data';
import EfficiencyCalculationReferenceFields from './EfficiencyCalculationReferenceFields';

const initialData: any = {
  totalRevenue: {
    value: 100,
    fields: [
      { label: 'Total Revenue 1', value: 100, id: 'tr1' },
      { label: 'Total Revenue 2', value: 100, id: 'tr2' },
    ],
  },
  averageAR: {
    value: 100,
  },
  CoGS: {
    value: 100,
  },
  averageInventory: {
    value: 100,
  },
  averageTotalAssets: { value: 100 },
  costsInExcessOfBillings: {
    value: 100,
    fields: [
      { label: 'Costs in Excess of Billings 1', value: 100, id: 'ceib1' },
      { label: 'Costs in Excess of Billings 2', value: 100, id: 'ceib2' },
    ],
  },
  tangibleNetworth: {
    value: 100,
    fields: [
      { label: 'Tangible Networth 1', value: 50, id: 'tn1' },
      { label: 'Tangible Networth 2', value: 100, id: 'tn2' },
    ],
  },
};

const EditEfficiencyRatioModal = ({
  isOpen,
  onClose,
  TData,
}: {
  isOpen: boolean;
  onClose: () => void;
  TData: AnalyticalReportDetails;
}) => {
  const {
    costsInExcessOfBillings,
    setCostsInExcessOfBillings,
    totalRevenue,
    setTotalRevenue,
    reportReferenceData,
    setReportReferenceData,
    totalAsset,
    totalLiabilities,
    inTangibleAsset,
    setTotalAsset,
    setTotalLiabilities,
    setInTangibleAsset,
  } = useEfficiencyContext();
  const {
    applicantId,
    analyticalReportPeriod,
    analyticalReportRatio,
    btnLoader,
  } = useAnalyticalReportContext();
  const dispatch = useAppDispatch();
  const analyticalReportsSclice = useAppSelector(
    (state) => state.analyticalReportSlice
  );
  const [calculationReferenceFields, setCalculationReferenceFields] = useState<
    Record<string, any>
  >({});

  useEffect(() => {
    const { status, type } = analyticalReportsSclice;
    switch (status) {
      case 'succeed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      case 'failed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      default:
        break;
    }
  }, [analyticalReportsSclice.status, analyticalReportsSclice.type]);
  const [
    initialCalculationReferenceFields,
    setInitialCalculationReferenceFields,
  ] = useState<Record<string, any>>({});

  useEffect(() => {
    if (isOpen) {
      setCalculationReferenceFields(TData?.editable);
      setInitialCalculationReferenceFields(TData?.editable);
      setReportReferenceData({
        profitAndLossData: TData?.profitAndLossData,
        balanceSheetData: TData?.balanceSheetData,
      });
      updateSelectedItems(TData?.editable, {
        costsInExcessOfBillings: setCostsInExcessOfBillings,
        totalRevenue: setTotalRevenue,
        totalAsset: setTotalAsset,
        totalLiabilities: setTotalLiabilities,
        inTangibleAsset: setInTangibleAsset,
      });
    }
  }, [isOpen, TData?.editable]);

  const handleSubmitLiquidityRatio = () => {
    const selectedItemsMap = {
      costsInExcessOfBillings,
      totalRevenue,
      totalAsset,
      totalLiabilities,
      inTangibleAsset,
    };

    console.log(
      'selectedItemsMap',
      selectedItemsMap,
      calculationReferenceFields
    );
    // const allOptionsbinded = [
    //   ...Object.values(reportReferenceData.profitAndLossData).flat(),
    //   ...Object.values(reportReferenceData.balanceSheetData).flat(),
    // ];
    const allOptionsbinded = [
      ...(reportReferenceData?.balanceSheetData?.assets ?? []),
      ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
      ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
    ];

    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );

    const payload = {
      ratioName: analyticalReportRatio,
      duration: analyticalReportPeriod,
      applicantId: applicantId,
      updatedFields: updatedData,
    };

    try {
      dispatch(updateAnalyticalReport(payload as any));
    } catch (error) {
      console.log('Liquidity Ratio Update Error', error);
    }

    console.log('Liquidity updatedData', updatedData, payload);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit Analytical Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'750px'}
      styles={{
        title: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }}
    >
      <form>
        <EfficiencyCalculationReferenceFields
          calculationFields={calculationReferenceFields}
        />

        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            isDisabled={btnLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            loadingText="Update"
            isLoading={btnLoader}
            onClick={() => handleSubmitLiquidityRatio()}
          >
            Update
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditEfficiencyRatioModal;
