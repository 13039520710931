export const calculateGrossProfit = (
  data: Record<string, any>
): number | null => {
  const TotalRevenue = data?.TotalRevenue ?? 0;
  const CoGS = data?.CoGS ?? 0;
  return TotalRevenue - CoGS;
};

export const calculateGrossMargin = (
  data: Record<string, any>
): number | null => {
  const grossProfit = calculateGrossProfit(data)!;
  const TotalRevenue = !!data?.TotalRevenue ? data?.TotalRevenue : 1;
  return (grossProfit / TotalRevenue) * 100;
};

export const calculateNetIncome = (
  data: Record<string, any>
): number | null => {
  const TotalRevenue = data?.TotalRevenue ?? 0;
  const CoGS = data?.CoGS ?? 0;
  const TotalOperatingExpenses = data?.TotalOperatingExpenses ?? 0;
  const TotalOtherIncome = data?.TotalOtherIncome ?? 0;
  const TotalOtherExpenses = data?.TotalOtherExpenses ?? 0;
  const TotalIncomeTaxes = data?.TotalIncomeTaxes ?? 0;
  return (
    TotalRevenue -
    CoGS -
    TotalOperatingExpenses +
    TotalOtherIncome -
    TotalOtherExpenses -
    TotalIncomeTaxes
  );
};

export const calculateEbtdaSubFields = (data: Record<string, any>): number => {
  const interest = data?.Interest ?? 0;
  const taxes = data?.Taxes ?? 0;
  const depreciation = data?.Depreciation ?? 0;
  const amortization = data?.Amortization ?? 0;
  return interest + taxes + depreciation + amortization;
};

export const calculateEBITDA = (data: Record<string, any>): number | null => {
  // Note: This is a simplified calculation. In a real-world scenario, you'd need to include
  // interest, taxes, depreciation, and amortization, which are not provided in the current data structure.
  const netIncome = calculateEbtdaSubFields(data);
  console.log('netIncome', netIncome);
  return netIncome; // This should be: netIncome + interest + taxes + depreciation + amortization
};

export const calculateEBITDAMargin = (
  data: Record<string, any>
): number | null => {
  const ebitda = calculateEBITDA(data)!;
  const TotalRevenue = !!data?.TotalRevenue ? data?.TotalRevenue : 1;
  return (ebitda / TotalRevenue) * 100;
};

export const calculateProfitMargin = (
  data: Record<string, any>
): number | null => {
  const netIncome = calculateNetIncome(data)!;
  const TotalRevenue = !!data?.TotalRevenue ? data?.TotalRevenue : 1;
  return (netIncome / TotalRevenue) * 100;
};

export const calculateROA = (data: Record<string, any>): number | null => {
  const netIncome = calculateNetIncome(data)!;
  const TotalAssetsOfCY = data?.TotalAssetsOfCY ?? 0;
  const TotalAssetsOfPY = data?.TotalAssetsOfPY ?? 0;
  const TotalAssets = data?.TotalAssets ?? 1;

  const averageTotalAssets = !!((TotalAssetsOfCY + TotalAssetsOfPY) / 2)
    ? (TotalAssetsOfCY + TotalAssetsOfPY) / 2
    : 1;
  return (netIncome / averageTotalAssets) * 100;
};

export const calculateROE = (data: Record<string, any>): number | null => {
  const netIncome = calculateNetIncome(data)!;
  const TotalEquityOfCY = data?.TotalEquityOfCY ?? 0;
  const TotalEquityOfPY = data?.TotalEquityOfPY ?? 0;
  const TotalEquity = data?.TotalEquity ?? 1;

  const averageTotalEquity = !!((TotalEquityOfCY + TotalEquityOfPY) / 2)
    ? (TotalEquityOfCY + TotalEquityOfPY) / 2
    : 1;
  return (netIncome / averageTotalEquity) * 100;
};

//Liquidity Ratio calculations
export const calculateWorkingCapitalRatio = (
  fields: Record<string, any>
): number => {
  const currentAssets = fields.currentAssets?.value ?? 0;
  const currentLiabilities = !!fields.currentLiabilities?.value
    ? fields.currentLiabilities?.value
    : 1;
  return Number((currentAssets / currentLiabilities).toFixed(2));
};

export const calculateWorkingCapital = (
  fields: Record<string, any>
): number => {
  const currentAssets = fields.currentAssets?.value ?? 0;
  const currentLiabilities = fields.currentLiabilities?.value ?? 0;
  return Number((currentAssets - currentLiabilities).toFixed(2));
};

export const calculateWorkingCapitalTurnover = (
  fields: Record<string, any>
): number => {
  const netAnnualSales = fields.netAnnualSales?.value ?? 0;
  const workingCapitalAverage = !!fields.averageWorkingCapital?.value
    ? fields.averageWorkingCapital?.value
    : 1;
  return Number((netAnnualSales / workingCapitalAverage).toFixed(2));
};

export const calculateBacklogToWorkingCapitalRatio = (
  fields: Record<string, any>
): number => {
  const backlog = fields.backlog?.value ?? 0;
  const workingCapital = !!calculateWorkingCapital(fields)
    ? calculateWorkingCapital(fields)
    : 1;
  return Number((backlog / workingCapital).toFixed(2));
};

export const calculateQuickRatio = (fields: Record<string, any>): number => {
  const quickAssets = fields.quickAssets?.value ?? 0;
  const currentLiabilities = !!fields.currentLiabilities?.value
    ? fields.currentLiabilities?.value
    : 1;
  return Number((quickAssets / currentLiabilities).toFixed(2));
};

// export const calculateQuickAssets = (fields: Record<string, any>): number => {
//   const cash = fields.cash?.value ?? 0;
//   const cashEquivalents = fields.cashEquivalents?.value ?? 0;
//   const marketableSecurities = fields.marketableSecurities?.value ?? 0;
//   const netAccountsReceivable = fields.netAccountsReceivable?.value ?? 0;
//   return cash + cashEquivalents + marketableSecurities + netAccountsReceivable;
// }

export const calculateBacklogToTangibalNetworth = (
  fields: Record<string, any>
): number => {
  const backlog = fields.backlog?.value ?? 0;
  const tangibleNetworth = !!fields.tangibleNetworth?.value
    ? fields.tangibleNetworth?.value
    : 1;
  return backlog / tangibleNetworth;
};

export const calculateOverBillingsToNetCash = (
  fields: Record<string, any>
): number => {
  const billingInExcessOfCosts = fields.billingInExcessOfCosts?.value ?? 0;
  const cashEquivalents = !!fields.cashEquivalents?.value
    ? fields.cashEquivalents?.value
    : 1; // Cash and Cash Equivalents
  return billingInExcessOfCosts / cashEquivalents;
};

//Efficiency Ratio calculations
export const calculateAccountReceivableTurnover = (
  fields: Record<string, any>
): number => {
  const totalRevenue = fields.totalRevenue?.value ?? 0;
  const averageAccountReceivable = !!fields.averageAccountReceivable?.value
    ? fields.averageAccountReceivable?.value
    : 1;
  return Number((totalRevenue / averageAccountReceivable).toFixed(2));
};

export const calculateDaysSalesOutstanding = (
  fields: Record<string, any>
): number => {
  const days = 365;
  const accountReceivableTurnover = !!calculateAccountReceivableTurnover(fields)
    ? calculateAccountReceivableTurnover(fields)
    : 1;
  return Number((days / accountReceivableTurnover).toFixed(2));
};

export const calculateInventoryTurnover = (
  fields: Record<string, any>
): number => {
  const costOfGoodsSold = fields.CoGS?.value ?? 0;
  const averageInventory = !!fields.averageInventory?.value
    ? fields.averageInventory?.value
    : 1;
  return Number((costOfGoodsSold / averageInventory).toFixed(2));
};

export const calculateDaysInventoryOutstanding = (
  fields: Record<string, any>
): number => {
  const days = 365;
  const inventoryTurnover = !!calculateInventoryTurnover(fields)
    ? calculateInventoryTurnover(fields)
    : 1;
  return Number((days / inventoryTurnover).toFixed(2));
};

export const calculateTotalAssetTurnover = (
  fields: Record<string, any>
): number => {
  const totalRevenue = fields.totalRevenue?.value ?? 0;
  const averageTotalAssets = !!fields.averageTotalAssets?.value
    ? fields.averageTotalAssets?.value
    : 1;
  return Number((totalRevenue / averageTotalAssets).toFixed(2));
};

// Leverage Ratio Calculations
export const calculateDebtToEquity = (fields: Record<string, any>): number => {
  const totalLiabilities = fields.totalLiabilities?.value ?? 0;
  const totalEquity = !!fields.totalEquity?.value
    ? fields.totalEquity?.value
    : 1;
  return Number((totalLiabilities / totalEquity).toFixed(2));
};

export const calculateDebtToAsset = (fields: Record<string, any>): number => {
  const totalLiabilities = fields.totalLiabilities?.value ?? 0;
  const totalAssets = !!fields.totalAssets?.value
    ? fields.totalAssets?.value
    : 1;
  return Number((totalLiabilities / totalAssets).toFixed(2));
};

export const calculateEquityRatio = (fields: Record<string, any>): number => {
  const totalEquity = fields.totalEquity?.value ?? 0;
  const totalAssets = !!fields.totalAssets?.value
    ? fields.totalAssets?.value
    : 1;
  return Number((totalEquity / totalAssets).toFixed(2));
};

export const calculateLeverageEBITDA = (
  fields: Record<string, any>
): number => {
  const netIncome = fields?.netIncome?.value ?? 0;
  const interest = fields?.interest?.value ?? 0;
  const taxes = fields?.taxes?.value ?? 0;
  const depreciation = fields?.depreciation?.value ?? 0;
  const amortization = fields?.amortization?.value ?? 0;
  return Number(
    (netIncome + interest + taxes + depreciation + amortization).toFixed(2)
  );
};

export const calculateTimesInterestEarned = (
  fields: Record<string, any>
): number => {
  const ebitda = fields.EBITDA?.value ?? 0;
  const interest = !!fields.interestExpense?.value
    ? fields.interestExpense?.value
    : 1;
  return Number((ebitda / interest).toFixed(2));
};
