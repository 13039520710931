import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import { Modal } from '@mantine/core';
import { useFormik } from 'formik';
import { DatePickerInput } from 'rc-datepicker';
import { useRef } from 'react';
import { BondApplicationApprove } from 'src/Redux/Broker/BondRequest/slice';
import { useAppDispatch } from 'src/Redux/Store';
import { bondRequestApproveYupSchema } from 'src/ValidationSchema/Bond/bond-application';
import {
  addFocusEventListenerOnInput,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';
import {
  BondRequestApproveSchema,
  initialBondRequestApproveState,
} from './data';

const BondRequestApproveModal = ({
  isOpen,
  onClose,
  isSubmitLoader,
  bondRequestData,
}: {
  isOpen: boolean;
  onClose: () => void;
  isSubmitLoader: boolean;
  bondRequestData: Record<string, any>;
}) => {
  const dispatch = useAppDispatch();
  const approvedDateRef = useRef<DatePickerInput>(null);
  const formik = useFormik({
    initialValues: initialBondRequestApproveState,
    validationSchema: bondRequestApproveYupSchema,
    onSubmit: async (values: BondRequestApproveSchema) => {
      try {
        console.log('onSubmit', values);
        const payload = {
          ...values,
          approvedDate: values?.approvedDate!,
          approver: values?.approver!,
          approvalStamp: null,
          bondApplicationId: bondRequestData?.id!,
          isCreatedFromApplication: !!bondRequestData?.applicationId,
        };
        await dispatch(BondApplicationApprove(payload));
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  console.log('formik', formik);

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        formik.handleReset(formik.initialValues);
        onClose();
      }}
      title="Bond Request Approve"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'lg'}
      styles={{
        content: {
          maxHeight: '400px',
        },
      }}
      className="bondRequest_reject"
    >
      <form onSubmit={formik.handleSubmit}>
        <Box display={'flex'} flexDirection={'column'}>
          <Text
            fontWeight="600"
            color="#114684"
            textAlign="start"
            fontSize={13}
            mb={1}
          >
            <li>Approved Date</li>
          </Text>
          <DatePickerInput
            ref={approvedDateRef}
            value={
              formik.values.approvedDate !== null
                ? formik.values.approvedDate
                : undefined
            }
            onChange={(date: Date | null) => {
              formik.setFieldValue('approvedDate', date);
            }}
            locale="en"
            placeholder="Approved Date"
            className={`react-datepicker-component react-datepicker-input input gray-border`}
            showOnInputClick={true}
            onShow={() => addFocusEventListenerOnInput(approvedDateRef)}
            onHide={() => removeFocusEventListenerOnInput(approvedDateRef)}
            // minDate={moment(new Date()).toDate()}
            style={{
              zIndex: 20,
            }}
          />
          {formik.touched.approvedDate && formik.errors.approvedDate && (
            <div
              style={{
                color: 'red',
                fontSize: '13px',
                textAlign: 'start',
                fontWeight: '500',
              }}
            >
              {formik.errors.approvedDate}
            </div>
          )}
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Text
            fontWeight="600"
            color="#114684"
            textAlign="start"
            fontSize={13}
            mb={1}
          >
            <li>
              Name of under writer that Approved bond{' '}
              {/* <span style={{ color: "red" }}>*</span> */}
            </li>
          </Text>

          <Input
            fontSize={'13px'}
            w="100%"
            type="text"
            placeholder="Name of under writer that Approved bond"
            borderRadius="5px"
            onChange={formik.handleChange}
            value={formik.values.approver ?? ''}
            name="approver"
            focusBorderColor="#7f7f7f"
            borderColor={'#7f7f7f'}
            _hover={{ borderColor: '#7f7f7f' }}
          />
          {formik.touched.approver && formik.errors.approver && (
            <div
              style={{
                color: 'red',
                fontSize: '13px',
                textAlign: 'start',
                fontWeight: '500',
              }}
            >
              {formik.errors.approver}
            </div>
          )}
        </Box>

        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              formik.handleReset(formik.initialValues);
              onClose();
            }}
            isDisabled={isSubmitLoader}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            colorScheme="green"
            loadingText="Approve"
            isLoading={isSubmitLoader}
          >
            Approve
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default BondRequestApproveModal;
