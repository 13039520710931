import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from 'src/App';
import { tokenExipry } from 'src/Redux/tokenExpiry';

export const GetProjectList = createAsyncThunk(
  'brokerBondActivity/GetProjectList',
  async (applicantId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(
        `${BASEURL}/bondActivityTracker/projectList/${applicantId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const SaveBondActivity = createAsyncThunk(
  'brokerBondActivity/SaveBondActivity',
  async (data: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(
        `${BASEURL}/bondActivityTracker/create`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const GetBondActivity = createAsyncThunk(
  'brokerBondActivity/GetBondActivity',
  async (applicantId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(
        `${BASEURL}/bondActivityTracker/all/${applicantId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const DeleteBondActivity = createAsyncThunk(
  'brokerBondActivity/DeleteBondActivity',
  async (bondActivityId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.delete(
        `${BASEURL}/bondActivityTracker/${bondActivityId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const UpdateBondActivity = createAsyncThunk(
  'brokerBondActivity/UpdateBondActivity',
  async (data: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const { id, ...payload } = data;
      const response = await axios.put(
        `${BASEURL}/bondActivityTracker/${id}`,
        payload,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const GetDeletedBondActivity = createAsyncThunk(
  'brokerBondActivity/GetDeletedBondActivity',
  async (applicantId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(
        `${BASEURL}/bondActivityTracker/all-deleted/${applicantId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const RestoreBondActivity = createAsyncThunk(
  'brokerBondActivity/RestoreBondActivity',
  async (bondActivityId: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(
        `${BASEURL}/bondActivityTracker/restore/${bondActivityId}`,
        {},
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const AddClientProfile = createAsyncThunk(
  'brokerClientProfile/AddClientProfile',
  async (data: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(
        `${BASEURL}/clientProfile/create`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const GetClientProfile = createAsyncThunk(
  'brokerClientProfile/GetClientProfile',
  async (applicantId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(
        `${BASEURL}/clientProfile/all/${applicantId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const DeleteClientProfile = createAsyncThunk(
  'brokerClientProfile/DeleteClientProfile',
  async (clientProfileId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.delete(
        `${BASEURL}/clientProfile/${clientProfileId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const UpdateClientProfile = createAsyncThunk(
  'brokerClientProfile/UpdateClientProfile',
  async (data: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const { id, ...payload } = data;
      const response = await axios.put(
        `${BASEURL}/clientProfile/${id}`,
        payload,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const GetDeletedClientProfile = createAsyncThunk(
  'brokerClientProfile/GetDeletedClientProfile',
  async (applicantId: string) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.get(
        `${BASEURL}/clientProfile/all-deleted/${applicantId}`,
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);

export const RestoreClientProfile = createAsyncThunk(
  'brokerClientProfile/RestoreClientProfile',
  async (profileId: any) => {
    const Token = localStorage.getItem('Token');
    const config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await axios.post(
        `${BASEURL}/clientProfile/restore/${profileId}`,
        {},
        config
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        const result = tokenExipry();
        return result;
      }
      return error?.response?.data;
    }
  }
);
