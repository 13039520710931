import { createSlice } from '@reduxjs/toolkit';
import { GetBondActivity, GetClientProfile } from './BondActivity';
import initialState from './state';

export function isRejectedActionWithPayload(
  action: any
): action is { payload: { errorMessage: string } } {
  return action.payload && typeof action.payload.msg === 'string'
    ? action.payload.msg
    : '';
}

export const bondFacilityTrackerStore = createSlice({
  name: 'bondFacilityTrackerStore',
  initialState,
  reducers: {
    removeBondFacilityTrackerType(state, action) {
      state.type = null;
      state.error = null;
      state.status = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetBondActivity.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_BOND_ACTIVITY_DETAILS';
      })
      .addCase(GetBondActivity.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_BOND_ACTIVITY_DETAILS';
        state.bondActivityDetail = action.payload?.data;
      })
      .addCase(GetBondActivity.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_BOND_ACTIVITY_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(GetClientProfile.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_CLIENT_PROFILE_DETAILS';
      })
      .addCase(GetClientProfile.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_CLIENT_PROFILE_DETAILS';
        state.clientProfileDetails = action.payload?.data;
      })
      .addCase(GetClientProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_CLIENT_PROFILE_DETAILS';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const bondFacilityTrackerSlice = bondFacilityTrackerStore.reducer;
export const { removeBondFacilityTrackerType } =
  bondFacilityTrackerStore.actions;

export default bondFacilityTrackerSlice;
