import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface LiquidityContextType {
  cashOnHand: string[];
  setSelectedCashOnHand: React.Dispatch<React.SetStateAction<string[]>>;
  annualOperatingExpense: string[];
  setSelectedAnnualOperatingExpense: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  nonCashItems: string[];
  setSelectedNonCashItems: React.Dispatch<React.SetStateAction<string[]>>;
  backlog: string[];
  setBacklog: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  billingsInExcessOfCosts: string[];
  setBillingsInExcessOfCosts: React.Dispatch<React.SetStateAction<string[]>>;
  cashEquivalents: string[];
  setCashEquivalents: React.Dispatch<React.SetStateAction<string[]>>;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
    }>
  >;
  totalAsset: string[];
  setTotalAsset: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  inTangibleAsset: string[];
  setInTangibleAsset: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create Context with Default Values
const LiquidityContext = createContext<LiquidityContextType | undefined>(
  undefined
);

// Define Props for Provider
interface LiquidityContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const LiquidityProvider: React.FC<LiquidityContextProviderProps> = ({
  children,
}) => {
  const [cashOnHand, setSelectedCashOnHand] = useState<string[]>([]);
  const [annualOperatingExpense, setSelectedAnnualOperatingExpense] = useState<
    string[]
  >([]);
  const [nonCashItems, setSelectedNonCashItems] = useState<string[]>([]);
  const [backlog, setBacklog] = useState<string[]>([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalAsset, setTotalAsset] = useState<string[]>([]);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [inTangibleAsset, setInTangibleAsset] = useState<string[]>([]);

  const [billingsInExcessOfCosts, setBillingsInExcessOfCosts] = useState<
    string[]
  >([]);
  const [cashEquivalents, setCashEquivalents] = useState<string[]>([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }>({ profitAndLossData: {}, balanceSheetData: {} });

  return (
    <LiquidityContext.Provider
      value={{
        cashOnHand,
        setSelectedCashOnHand,
        annualOperatingExpense,
        setSelectedAnnualOperatingExpense,
        nonCashItems,
        setSelectedNonCashItems,
        backlog,
        setBacklog,
        tangibleNetworth,
        setTangibleNetworth,
        billingsInExcessOfCosts,
        setBillingsInExcessOfCosts,
        cashEquivalents,
        setCashEquivalents,
        reportReferenceData,
        setReportReferenceData,
        totalAsset,
        setTotalAsset,
        totalLiabilities,
        setTotalLiabilities,
        inTangibleAsset,
        setInTangibleAsset,
      }}
    >
      {children}
    </LiquidityContext.Provider>
  );
};

// Custom Hook for Context Access
export const useLiquidityContext = (): LiquidityContextType => {
  const context = useContext(LiquidityContext);
  if (!context) {
    throw new Error(
      'useLiquidityContext must be used within an LiquidityProvider'
    );
  }
  return context;
};
