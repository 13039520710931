import React, { ReactNode, createContext, useContext, useState } from 'react';

// Define Context Type
interface EBITDAContextType {
  selectedNetIncomeItems: string[];
  setSelectedNetIncomeItems: React.Dispatch<React.SetStateAction<string[]>>;
}

// Create Context with Default Values
const EBITDAContext = createContext<EBITDAContextType | undefined>(undefined);

// Define Props for Provider
interface EBITDAProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const EBITDAProvider: React.FC<EBITDAProviderProps> = ({ children }) => {
  const [selectedNetIncomeItems, setSelectedNetIncomeItems] = useState<
    string[]
  >([]);

  return (
    <EBITDAContext.Provider
      value={{ selectedNetIncomeItems, setSelectedNetIncomeItems }}
    >
      {children}
    </EBITDAContext.Provider>
  );
};

// Custom Hook for Context Access
export const useEBITDA = (): EBITDAContextType => {
  const context = useContext(EBITDAContext);
  if (!context) {
    throw new Error('useEBITDA must be used within an EBITDAProvider');
  }
  return context;
};
