import { Box, Button, Flex, Spacer, Switch, Text } from '@chakra-ui/react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaTrashRestore } from 'react-icons/fa';
import { RiFileDownloadFill } from 'react-icons/ri';
import { useZipFromLinks } from 'src/hooks';
import { formatNumber } from 'src/Pages/Reports/components/MantineTable';
import {
  DeleteClientProfile,
  GetClientProfile,
  GetDeletedClientProfile,
  RestoreClientProfile,
} from 'src/Redux/Broker/BondFacilityTracker/BondActivity';
import { useAppDispatch } from 'src/Redux/Store';
import Swal from 'sweetalert2';
import ClientProfileAdd from './ClientProfileAdd';
import { ClientProfile } from './model';

const ClientProfileReport = ({
  applicantId,
  onEditMode,
}: {
  applicantId: string;
  onEditMode: any;
}) => {
  const [addMode, setAddMode] = useState(false);
  const dispatch = useAppDispatch();
  const [clientProfileData, setClientProfileData] = useState<ClientProfile[]>(
    []
  );
  const [selectedClientProfile, setSelectedClientProfile] =
    useState<ClientProfile | null>(null);
  const { createZipFromLinks, loading, error } = useZipFromLinks();
  const [isDeletedProfile, setisDeletedProfile] = useState(false);
  const prevClientProfileRef: any = useRef();

  useEffect(() => {
    prevClientProfileRef.current = clientProfileData;
  }, [clientProfileData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'totalAggregateBondFacilityLimit',
        header: 'Total Aggregate  Bond Facility Limit',
        Cell: ({ cell }: { cell: any }) => (
          <>{formatNumber(cell.getValue() as number)}</>
        ),
      },
      {
        accessorKey: 'totalUtilizedAggregateOfBondFacility',
        header: 'Total Utilized Aggregate of Bond Facility',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'totalRemainingAggregateOfBondFacility',
        header: 'Total Remaining Aggregate of Bond Facility',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'singleContractLimit',
        header: 'Single Contract Limit',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'renewalDate',
        header: 'Renewal Date',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { renewalDate } = row?.original;
          return renewalDate ? moment(renewalDate).format('DD-MM-YYYY') : '';
        },
      },
      {
        accessorKey: 'financialCovenants',
        header: 'Financial Covenants',
      },
      {
        accessorKey: 'clientReportingRequirements',
        header: 'Client Reporting Requirements',
      },
      {
        accessorKey: 'financialReportingFrequency',
        header: 'Financial Reporting Frequency',
        Cell: ({ cell }: { cell: any }) => (
          <Flex gap={5}>
            <>
              {cell.getValue() &&
                cell
                  .getValue()
                  .filter((ele: string) => ele !== 'Other')
                  .join(',')}
            </>
          </Flex>
        ),
      },
      {
        accessorKey: 'mostRecentFinancialReviewDate',
        header: 'Most Recent Financial Review Date',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { mostRecentFinancialReviewDate } = row?.original;
          return mostRecentFinancialReviewDate
            ? moment(mostRecentFinancialReviewDate).format('DD-MM-YYYY')
            : '';
        },
      },
      {
        accessorKey: 'indemnityAndSecurityConditions',
        header: 'Indemnity and Security Conditions',
        Cell: ({ cell, row }: { cell: any; row: any }) => (
          <Flex gap={5}>
            <>
              {cell.getValue() &&
                cell
                  .getValue()
                  .filter((ele: string) => ele !== 'Other')
                  .join(',')}
            </>
            <Spacer></Spacer>
            {row?.original?.indemnityAndSecurityConditionsAttachments &&
              row?.original?.indemnityAndSecurityConditionsAttachments.length >
                0 && (
                <>
                  <Tooltip label="Download Files">
                    <ActionIcon
                      color="teal"
                      onClick={() =>
                        handleDownload(
                          row?.original
                            .indemnityAndSecurityConditionsAttachments,
                          'Indemnity_Security_Conditions'
                        )
                      }
                      size={'md'}
                    >
                      <RiFileDownloadFill style={{ fontSize: '20px' }} />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
          </Flex>
        ),
      },
      {
        accessorKey: 'claimsIncidents',
        header: 'Claims / Incidents',
      },
      {
        accessorKey: 'underwritingFee',
        header: 'Underwriting Fee',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'bondFees',
        header: 'Bond Fees',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'bondFacilityAgreementAttachments',
        header: 'Bond Facility Agreement',
        Cell: ({ cell, row }: { cell: any; row: any }) => (
          <Flex justifyContent={'center'}>
            {row?.original?.bondFacilityAgreementAttachments &&
              row?.original?.bondFacilityAgreementAttachments.length > 0 && (
                <>
                  <Tooltip label="Download Files">
                    <ActionIcon
                      color="teal"
                      onClick={() =>
                        handleDownload(
                          row?.original.bondFacilityAgreementAttachments,

                          'Bond_Facility_Agreement'
                        )
                      }
                      size={'md'}
                    >
                      <RiFileDownloadFill style={{ fontSize: '20px' }} />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
          </Flex>
        ),
      },
    ],
    []
  );

  const handleDownload = (fileInfo: any, zipName: string) => {
    createZipFromLinks({
      links: (fileInfo as any[])?.map((item: any) => item?.link),
      zipName: `${zipName}`,
    });
  };

  const getClientProfile = async () => {
    const result = await dispatch(GetClientProfile(applicantId));
    setClientProfileData(result.payload?.data);
  };

  useEffect(() => {
    setisDeletedProfile(false);
    getClientProfile();
  }, [applicantId]);

  const getDeletedClientProfile = async () => {
    const result = await dispatch(GetDeletedClientProfile(applicantId));
    setClientProfileData(result.payload?.data);
  };

  const showDeletedProfile = async (e: any) => {
    setisDeletedProfile(e.target.checked);
    if (e.target.checked) {
      getDeletedClientProfile();
    } else getClientProfile();
  };

  const tableClient = useMantineReactTable({
    columns,
    data: clientProfileData as any,
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex gap={5}>
        {((!isDeletedProfile &&
          clientProfileData &&
          clientProfileData.length === 0) ||
          (isDeletedProfile &&
            prevClientProfileRef.current &&
            prevClientProfileRef.current.length === 0)) && (
          <Button
            bg={'#114684'}
            color={'#ffffff'}
            onClick={handleAddProfile}
            _hover={{ opacity: 0.8 }}
          >
            Add Profile
          </Button>
        )}
        <Flex gap={2} alignItems={'center'}>
          <Text>Show Deleted</Text>
          <Switch
            id="showDeletedCP"
            isChecked={isDeletedProfile}
            onChange={showDeletedProfile}
          />
        </Flex>
      </Flex>
    ),
    // state: { columnVisibility: { 'mrt-row-actions': !isDeletedProfile } },
    enableColumnPinning: true,
    initialState: {
      columnPinning: { left: ['mrt-row-actions'] },
    },
    enableRowActions: true,
    renderRowActions: ({ row }: any) => (
      <Flex gap="5">
        {!isDeletedProfile && (
          <Flex gap={5}>
            <ActionIcon
              onClick={() => {
                setSelectedClientProfile(row?.original);
                setAddMode(true);
                onEditMode(true);
              }}
              size={'sm'}
            >
              <IconEdit />
            </ActionIcon>

            <ActionIcon
              color="red"
              onClick={() => handleDelete(row?.original)}
              size={'sm'}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        )}

        {isDeletedProfile && (
          <Tooltip label="Restore">
            <ActionIcon
              color="orange"
              onClick={() => {
                restoreClientProfile(row?.original);
              }}
              size={'sm'}
            >
              <FaTrashRestore />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
  });

  const restoreClientProfile = (profile: any) => {
    Swal.fire({
      title: 'Do you want to restore this client profile record?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(RestoreClientProfile(profile.id))
          .then((res: any) => {
            if (res.payload.status === 200) {
              setClientProfileData((current) =>
                current.filter((obj) => obj.id !== profile.id)
              );
              Swal.fire({
                title: 'Client Profile restored successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              Swal.fire({
                title: res.payload.message || 'Error occurred!',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {
            Swal.fire({
              title: 'Error occurred!',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  const handleDelete = (profile: any) => {
    Swal.fire({
      title: 'Do you want to delete this profile record?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(DeleteClientProfile(profile.id))
          .then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setClientProfileData((current) =>
                current.filter((obj) => obj.id !== profile.id)
              );
              Swal.fire({
                title: 'Deleted Successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {});
      }
    });
  };

  const handleAddProfile = () => {
    setAddMode(true);
  };
  const handleExit = (data: boolean) => {
    if (selectedClientProfile) onEditMode(false);

    setAddMode(data);
    setSelectedClientProfile(null);
    if (!data) {
      getClientProfile();
    }
  };
  return (
    <Box>
      {!addMode && <MantineReactTable table={tableClient} />}
      {addMode && (
        <ClientProfileAdd
          selectedClientProfile={selectedClientProfile}
          applicantId={applicantId}
          addMode={handleExit}
        ></ClientProfileAdd>
      )}
    </Box>
  );
};

export default ClientProfileReport;
