import { Box, Button, Flex } from '@chakra-ui/react';
import { Modal, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import InputTag from 'src/Component/Common/TagInput';
import { updateBondNumbers } from 'src/Redux/Broker/Reports/WorkInProgress/slice';
import {
  uploadTemplteType,
  WIPUploadBondList,
} from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch } from 'src/Redux/Store';

const WIPBondListModal = ({
  isOpen,
  onClose,
  isUpdateStatusLoader,
  uploadTemplte,
}: {
  isOpen: boolean;
  onClose: () => void;
  isUpdateStatusLoader: boolean;
  uploadTemplte: uploadTemplteType;
}) => {
  const dispatch = useAppDispatch();
  const [bondNumbers, setBondNumbers] = useState<WIPUploadBondList[] | []>([]);

  useEffect(() => {
    setBondNumbers(uploadTemplte?.bondList || []);
  }, [uploadTemplte?.bondList]);
  const handleUpdateBondNumbers = () => {
    const { reportId } = uploadTemplte;
    console.log('bonnnnn22222=', bondNumbers);
    dispatch(
      updateBondNumbers({
        id: reportId,
        bondList: bondNumbers,
      })
    );
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setBondNumbers([]);
        onClose();
      }}
      title="WIP Report"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'md'}
    >
      <Box>
        <Text size={'sm'} mb={4} weight={'bold'}>
          Use comma to seperate multiple bond numbers.
        </Text>
        {bondNumbers.map(
          (item, index) =>
            item.projectBonded && (
              <Flex direction={'column'} key={index}>
                <Text>{item?.projectName}</Text>
                <InputTag
                  onChange={(data) => {
                    console.log('bonnnnn=', bondNumbers);

                    setBondNumbers(
                      [...bondNumbers].map((obj) => {
                        if (obj.uniqueProjectId === item.uniqueProjectId) {
                          return { ...obj, bondNumber: data };
                        } else return obj;
                      })
                    );

                    // setBondNumbers((prev) => {
                    //   const newBondNumbers = [...prev];
                    //   newBondNumbers[index] = {
                    //     ...newBondNumbers[index],
                    //     bondNumber: data,
                    //   };

                    //   return newBondNumbers;
                    // });
                  }}
                />
              </Flex>
            )
        )}
        <Text size={'sm'} mt={4} weight={'bold'}>
          Please enter Bond numbers for above project(s).
        </Text>
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              setBondNumbers([]);
              onClose();
            }}
            isDisabled={isUpdateStatusLoader}
          >
            Cancel
          </Button>
          <Button
            type="button"
            colorScheme="blue"
            onClick={handleUpdateBondNumbers}
            loadingText="Saving"
            isDisabled={!bondNumbers.length}
            isLoading={isUpdateStatusLoader}
          >
            Continue
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default WIPBondListModal;
