import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import { useLeverageContext } from 'src/context/LeverageRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
} from '../../data';

interface CalculationDisplayTNWProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayTNW: React.FC<CalculationDisplayTNWProps> = ({
  label,
  calculationFields,
}) => {
  const {
    totalEquity,
    setTotalEquity,
    intangibleAssets,
    setIntangibleAssets,
    reportReferenceData,
    setTangibleNetworth,
  } = useLeverageContext();

  console.log('reportReferenceData', reportReferenceData);

  const isTotalEquityOption = !!calculationFields?.totalEquity?.fields?.length;
  const isTotalEquityValue = !!calculationFields?.totalEquity?.value
    ? calculationFields?.totalEquity?.value
    : 0;
  const isIntangibleAssetsOption =
    !!calculationFields?.intangibleAssets?.fields?.length;
  const isIntangibleAssetsValue = !!calculationFields?.intangibleAssets?.value
    ? calculationFields?.intangibleAssets?.value
    : 0;

  const totalEquitySelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalEquity,
      calculationFields?.totalEquity,
      reportReferenceData
    );
  }, []);
  const equityTotalInitialValue =
    isTotalEquityValue - totalEquitySelectedFieldSumInitial;

  const totalIntangibleAssetsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      intangibleAssets,
      calculationFields?.intangibleAssets,
      reportReferenceData
    );
  }, []);
  const intangibleAssetsTotalInitialValue =
    isIntangibleAssetsValue - totalIntangibleAssetsSelectedFieldSumInitial;

  // const equityTotalValue = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalEquity,
  //       calculationFields?.totalEquity,
  //       reportReferenceData
  //     ) + (isTotalEquityOption ? 0 : isTotalEquityValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalEquity, calculationFields.totalEquity]);

  // const intangibleAssetsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       intangibleAssets,
  //       calculationFields?.intangibleAssets,
  //       reportReferenceData
  //     ) + (isIntangibleAssetsOption ? 0 : isIntangibleAssetsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [intangibleAssets, calculationFields.intangibleAssets]);

  const equityTotalValue = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalEquity,
      calculationFields?.totalEquity,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + equityTotalInitialValue)?.toFixed(2)
    );
  }, [totalEquity, calculationFields?.totalEquity]);

  const intangibleAssetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      intangibleAssets,
      calculationFields?.intangibleAssets,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + intangibleAssetsTotalInitialValue)?.toFixed(2)
    );
  }, [intangibleAssets, calculationFields.intangibleAssets]);

  const result = useMemo(() => {
    const intangibleAssetsValue = !!intangibleAssetsTotal
      ? intangibleAssetsTotal
      : 0;
    return Number((equityTotalValue - intangibleAssetsValue).toFixed(2));
  }, [equityTotalValue, intangibleAssetsTotal]);

  useEffect(() => {
    setTangibleNetworth(result);
  }, [result]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Total Equity (${equityTotalValue})`}
            data={getBSAssetsOptions(
              calculationFields?.totalEquity?.fields!,
              reportReferenceData!
            )}
            value={totalEquity}
            onChange={setTotalEquity}
            placeholder="Select Total Equity items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <MultiSelect
            label={`Intangible Assets (${intangibleAssetsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.intangibleAssets?.fields!,
              reportReferenceData!
            )}
            value={intangibleAssets}
            onChange={setIntangibleAssets}
            placeholder="Select Intangible Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
