import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useLeverageContext } from 'src/context/LeverageRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
} from '../../data';

interface CalculationDisplayLTATNProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayLTATN: React.FC<
  CalculationDisplayLTATNProps
> = ({ label, calculationFields }) => {
  const {
    longTermAssets,
    setLongTermAssets,
    tangibleNetworth,
    reportReferenceData,
  } = useLeverageContext();

  const isLongTermAssetsOption =
    !!calculationFields?.longTermAssets?.fields?.length;
  const isLongTermAssetsValue = !!calculationFields?.longTermAssets?.value
    ? calculationFields?.longTermAssets?.value
    : 0;

  const longTermAssetsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      longTermAssets,
      calculationFields?.longTermAssets,
      reportReferenceData
    );
  }, []);
  const longTermAssetsTotalInitialValue =
    isLongTermAssetsValue - longTermAssetsSelectedFieldSumInitial;

  // const longTermAssetsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       longTermAssets,
  //       calculationFields?.longTermAssets,
  //       reportReferenceData
  //     ) + (isLongTermAssetsOption ? 0 : isLongTermAssetsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [longTermAssets, calculationFields.longTermAssets]);

  const longTermAssetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      longTermAssets,
      calculationFields?.longTermAssets,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + longTermAssetsTotalInitialValue)?.toFixed(2)
    );
  }, [longTermAssets, calculationFields?.longTermAssets]);

  const result = useMemo(() => {
    const tangibleNetworthValue = !!tangibleNetworth ? tangibleNetworth : 1;
    return Number((longTermAssetsTotal / tangibleNetworthValue).toFixed(2));
  }, [longTermAssetsTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Long Term Assets (${longTermAssetsTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.longTermAssets?.fields!,
              reportReferenceData!
            )}
            value={longTermAssets}
            onChange={setLongTermAssets}
            placeholder="Select Long Term Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label="Tangible Networth"
            data={getOptions(calculationFields?.tangibleNetworth?.fields!)}
            value={tangibleNetworth}
            onChange={setTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            sx={{ flex: 1 }}
          /> */}
          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
