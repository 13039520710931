import { BondApplicationAttributes } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { BondApplicationAPIData } from 'src/Redux/Broker/BondApplicationForm/state';
import { initialBondApplicationState } from '../../../BondApplicationForm/data';
export interface StepperBondApplicationFormSchema
  extends BondApplicationAttributes {
  applicationId: number | null;
}

export const initialStepperBondApplicationFormState: StepperBondApplicationFormSchema =
  {
    ...initialBondApplicationState,
    applicationId: null,
  };

export interface StepperBondApplicationAPISchema
  extends BondApplicationAPIData {
  applicationId: number | null;
}
