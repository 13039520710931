import { Box, Flex, Text } from '@chakra-ui/react';
import { List, Modal } from '@mantine/core';

const UploadDocReportLogs = ({
  isOpen,
  onClose,
  logData,
}: {
  isOpen: boolean;
  onClose: () => void;
  logData?:
    | Array<{ user_id: any; message: string; id: string; date: string }>
    | []
    | null;
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={() => onClose()}
      title="History"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'625px'}
      zIndex={300}
      styles={{
        title: {
          fontWeight: 'bold',
          fontSize: '18px',
        },
        header: {
          borderBottom: '1px solid #E2E8F0',
        },
        content: {
          maxHeight: 'calc(100vh - 50px)',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          overflow: 'auto',
        },
      }}
    >
      <Box margin={'10px'} maxW={'625px'} maxH={'600px'}>
        <Flex direction={'column'} gap={2} mb={3}>
          <Flex gap={1}>
            <Text textAlign="start" color="black" fontWeight={'600'}>
              Report Name:
            </Text>
            <Text>Upload Document Log</Text>
          </Flex>
        </Flex>
        {logData && logData?.length > 0 ? (
          <List>
            {logData?.map((item: any, index: number) => (
              <List.Item key={index}>{item?.message}</List.Item>
            ))}
          </List>
        ) : (
          <Box marginBottom={'10px'} textAlign={'center'}>
            No Data Found
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default UploadDocReportLogs;
