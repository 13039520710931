import { Box, Flex, Text, TextInput } from '@mantine/core';
import React from 'react';

interface CalculationDisplayProps {
  label: string;
  inputs: { label: string; value: number | null }[];
  operators: string[];
  result: number | null;
  isPercentage?: boolean;
}

export const CalculationDisplay: React.FC<CalculationDisplayProps> = ({
  label,
  inputs,
  operators,
  result,
  isPercentage = false,
}) => {
  return (
    <Box w={'100%'}>
      {/* Label Row */}
      <Text size="lg" weight={700} align="left" mb="xs">
        {label}
      </Text>

      {/* Calculation Row */}
      <Flex wrap="wrap" align="center" justify={'flex-start'} gap="xs">
        {inputs.map((input, index) => (
          <Flex key={input.label} align="center" gap="xs">
            <TextInput
              label={input.label}
              value={input.value ?? 0}
              disabled
              styles={{
                input: {
                  textAlign: 'right',
                  '&:disabled': {
                    color: 'black',
                    fontWeight: 'bold',
                  },
                },
              }}
            />
            {index < operators.length && (
              <Text size="xl" weight={700}>
                {operators[index]}
              </Text>
            )}
          </Flex>
        ))}

        {isPercentage && (
          <Text size="md" style={{ pointerEvents: 'none' }}>
            * 100
          </Text>
        )}
        {/* Equals Sign */}
        <Text size="xl" weight={700}>
          =
        </Text>
        {/* Result */}
        <TextInput
          label="Result"
          value={result ?? 0}
          disabled
          styles={{
            input: {
              textAlign: 'right',
              '&:disabled': {
                color: 'black',
                fontWeight: 'bold',
              },
            },
          }}
        />
      </Flex>
    </Box>
  );
};
