import axios from 'axios';
import JSZip from 'jszip';
import { useState } from 'react';
import { getFileNameFromURL } from 'src/utils/helpers';
// import Bluebird from 'bluebird';

const getUrlExtension = (url: string) => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
};

type Props = {
  folderWisedata: {
    mainFolder: string;
    subFolder: string;
    links: string[];
    nestedSubFolder?: string;
  }[];
  zipName?: string;
  returnData?: boolean;
  downloadZip?: boolean;
  regexpattern?: RegExp;
};

const useZipFromLinksWithFolder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createZipFromFolderAndLinks = async ({
    folderWisedata,
    zipName = 'files',
    returnData = false,
    downloadZip = true,
    regexpattern,
  }: Props) => {
    console.log('folderWisedata', folderWisedata);

    setLoading(true);
    setError(null);

    const zip = new JSZip();

    // Download files from each link and add them to the zip

    await Promise.all(
      folderWisedata.map(async (data, index) => {
        try {
          const links = data.links;

          const mainFolderName = zip.folder(data.mainFolder);
          const subFolder = mainFolderName?.folder(data.subFolder);
          const nestedSubFolder =
            !!data.nestedSubFolder && subFolder?.folder(data.nestedSubFolder);

          await Promise.all(
            links.map(async (link: string) => {
              const response = await axios.get(link, {
                responseType: 'arraybuffer',
              });
              // const extension = getUrlExtension(link);
              const name = getFileNameFromURL(link, true);
              if (!!data.nestedSubFolder) {
                nestedSubFolder &&
                  nestedSubFolder?.file(
                    `${name || `file` + index}`,
                    response.data
                  );
              } else {
                subFolder &&
                  subFolder.file(`${name || `file` + index}`, response.data); // Adjust file name and extension
              }
              return response;
            })
          );

          // links.forEach(async (link) => {
          //   const response = await axios.get(link, {
          //     responseType: 'arraybuffer',
          //   }); // Adjust request as per file type and origin

          // });
        } catch (error: any) {
          console.error(`Error downloading file from ${data.links}:`, error);
          // Handle errors as per your requirement (e.g., skip the file, show an error message)
          setError(
            `Error downloading file from ${data.links}: ${error.message}`
          );
        }
      })
    );

    // Generate the zip content
    const zipContent = await zip.generateAsync({ type: 'blob' });
    if (downloadZip) {
      // Create a download link for the zip file
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(zipContent);
      downloadLink.download = `${zipName}.zip`;
      downloadLink.click();
    }
    if (returnData) return zipContent;

    setLoading(false);
  };

  return { createZipFromFolderAndLinks, loading, error };
};

export default useZipFromLinksWithFolder;
