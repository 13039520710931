import { Stack } from '@mantine/core';
import { useLeverageContext } from 'src/context/LeverageRatioContext';
import {
  calculateDebtToAsset,
  calculateDebtToEquity,
  calculateEquityRatio,
  calculateLeverageEBITDA,
  calculateTimesInterestEarned,
} from '../../data/calculations';
import { CalculationDisplay } from '../ProfitabilityRatio/CalculationDisplay';
import { CalculationDisplayLTATN } from './CalculationDisplayLTATN';
import { CalculationDisplayTLTN } from './CalculationDisplayTLTN';
import { CalculationDisplayTNW } from './CalculationDisplayTNW';
import { CalculationDisplayTangibleNetworthLeverage } from './CalculationDisplayTangibleNetworthLeverage';

export interface LeverageCalculationReferenceFields {
  calculationFields: Record<string, any>;
}

const LeverageCalculationReferenceFields: React.FC<
  LeverageCalculationReferenceFields
> = ({ calculationFields }) => {
  const { reportReferenceData } = useLeverageContext();
  console.log('calculationFields', calculationFields, reportReferenceData);

  const isTotalEquityValue = !!calculationFields?.totalEquity?.value;
  return (
    <Stack spacing="xl">
      {/* Debt to Equity */}
      <CalculationDisplay
        label="Debt to Equity"
        inputs={[
          {
            label: 'Total Liabilities',
            value: calculationFields?.totalLiabilities?.value,
          },
          {
            label: 'Total Equity',
            value: calculationFields?.totalEquity?.value,
          },
        ]}
        operators={['÷']}
        result={calculateDebtToEquity(calculationFields)}
      />

      {/* Debt to Asset */}
      <CalculationDisplay
        label="Debt to Asset"
        inputs={[
          {
            label: 'Total Liabilities',
            value: calculationFields?.totalLiabilities?.value,
          },
          {
            label: 'Total Assets',
            value: calculationFields?.totalAssets?.value,
          },
        ]}
        operators={['÷']}
        result={calculateDebtToAsset(calculationFields)}
      />

      {/* Equity Ratio */}
      <CalculationDisplay
        label="Equity Ratio"
        inputs={[
          {
            label: 'Total Equity',
            value: calculationFields?.totalEquity?.value,
          },
          {
            label: 'Total Assets',
            value: calculationFields?.totalAssets?.value,
          },
        ]}
        operators={['÷']}
        result={calculateEquityRatio(calculationFields)}
      />
      {/* Times Interest Earned */}
      <CalculationDisplay
        label="Times Interest Earned"
        inputs={[
          {
            label: 'EBITDA',
            value: calculateLeverageEBITDA(calculationFields),
          },
          {
            label: 'Interest Expense',
            value: calculationFields?.interestExpense?.value,
          },
        ]}
        operators={['÷']}
        result={calculateTimesInterestEarned(calculationFields)}
      />

      {isTotalEquityValue ? (
        <CalculationDisplayTNW
          label="Tangible NetWorth"
          calculationFields={calculationFields}
        />
      ) : (
        <CalculationDisplayTangibleNetworthLeverage
          label="Tangible Networth"
          calculationFields={calculationFields}
        />
      )}
      <CalculationDisplayLTATN
        label="Long Term Assets to Tangible Networth"
        calculationFields={calculationFields}
      />
      <CalculationDisplayTLTN
        label="Total Liabilities to Tangible Networth"
        calculationFields={calculationFields}
      />
    </Stack>
  );
};

export default LeverageCalculationReferenceFields;
