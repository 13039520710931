import { BondApplicationAttributes } from 'src/Pages/BrokerPages/BondApplicationForm/data';
import { APIStatus } from 'src/types/unions';

export interface BondApplicationAPIData extends BondApplicationAttributes {
  id: number;
  brokerId: number;
  createdBy: number;
  createdByType: string | null;
  createdAt: Date | null;
  // assignedBy: number | null;
  // assignedTo: number | null;
  // assignedAt: Date | null;
}

export interface JobDetails {
  contractPrice: number | null;
  costToComplete: number | null;
}

export interface WipApplicationAPIData {
  workOnHandsAsOfDate: string | null;
  totalBondedJobs: JobDetails | null;
  totalUnbondedJobs: JobDetails | null;
  wipBondDetails: string | null;
}

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_BOND_APPLICATION_FORM_DETAILS'
    | 'CREATE_BOND_APPLICATION_FORM'
    | 'UPDATE_BOND_APPLICATION_FORM_DETAILS'
    | 'GET_WIP_BOND_DETAILS'
    | 'GET_BOND_APPLICATION_REFERENCE_DETAILS'
    | null;
  bondApplicationData: BondApplicationAPIData | null;
  bondApplicationReferenceData: BondApplicationAPIData | null;
  msg: string | null;
  wipBondDetails: WipApplicationAPIData | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  bondApplicationData: null,
  bondApplicationReferenceData: null,
  msg: null,
  wipBondDetails: null,
};

export default initialState;
