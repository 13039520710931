import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React from 'react';

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: '8px 15px',
    fontSize: 10,
  },
  header: {
    textAlign: 'center',
    fontSize: 12,
    marginBottom: '2px',
    fontWeight: 'bold',
  },
  headerLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  mainSection: {
    margin: '10px',
    border: '2px solid black',
  },
  section: {
    borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontSize: 9,
    fontWeight: 'bold',
    marginRight: '2px',
    padding: '1.5px',
  },
  field: {
    fontSize: 8,
    color: '#575555',
    padding: '2.2px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1px 2px',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    // borderTop: '1px solid black',
    marginTop: 2,
  },
  tableHeader: {
    flex: 1,
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    padding: 3,
  },
  tableCell: {
    flex: 1,
    padding: 3,
  },
  leftColumn: {
    width: '50%',
    borderRight: '1px solid black',
    paddingRight: 10,
  },
  rightColumn: {
    width: '50%',
    paddingLeft: 10,
  },
  logo: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  address: {
    textAlign: 'right',
  },
  divider: {
    width: '1px',
    backgroundColor: 'black',
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '1px solid black',
  },
  contract: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 0px',
  },
  subHerder: {
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    padding: '1px 0px',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderTop: '1px solid black',
    // borderBottom: '1px solid black',
  },
  checkBox: {
    borderBottom: '1px solid black',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '1.5px 0px',
    alignItems: 'center',
  },
  borderBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderBottomWidth: 2,
    borderBottomColor: 'black',
  },
  leftSession: {
    borderBottom: '1px solid black',
    display: 'flex',
    flexDirection: 'row',
    padding: '1.5px 0px',
  },
});

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const options: any = { day: '2-digit', month: 'short', year: '2-digit' };
  return date
    .toLocaleDateString('en-GB', options)
    .replace(',', '')
    .replace(/\s/g, '/');
};

const CheckBox = ({ checked }: { checked?: boolean }) => {
  return (
    <View
      style={{
        width: 10,
        height: 10,
        border: '1px solid black',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: checked ? 'green' : '',
      }}
    ></View>
  );
};

const PDFForm: React.FC<{ data: any; logo?: any }> = ({ data, logo }) => {
  const bondDetails = data?.bidBondDetails || {};

  let validBonds = Object.keys(bondDetails)
    .filter((key) => key.includes('BondAmount') && key !== 'bidBondAmount')
    .map((key) => {
      const percentageKey = key.replace('BondAmount', 'BondPercentage');
      return {
        type: key.replace('BondAmount', ''),
        amount: bondDetails[key],
        percentage: bondDetails[percentageKey] || null,
      };
    });

  validBonds = validBonds
    .filter((bond) => bond.amount !== null || bond.percentage !== null)
    .slice(0, 5);

  const finalDetails = data?.finalBondDetails || {};

  let finalValidBonds = Object.keys(finalDetails)
    .filter((key) => key.includes('BondAmount')) // Filter for keys with 'BondAmount'
    .map((key) => {
      return {
        type: key.replace('BondAmount', ''), // Remove 'BondAmount' part from key to get the bond type
        amount: finalDetails[key], // Only include the amount
      };
    })
    .filter((bond) => bond.amount !== null) // Ensure that amount is not null
    .slice(0, 5); // Limit the result to top 5 items

  console.log(finalValidBonds);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            ...styles.row,
            borderBottom: 'none',
            padding: '5px 20px 0px 15px',
          }}
        >
          {logo && (
            <View>
              <Image
                src={logo}
                style={{
                  width: 85,
                  height: 35,
                }}
              />
            </View>
          )}

          <View>
            <Image
              src={'/assets/Subtraid.png'}
              style={{
                width: 100,
                height: 23,
              }}
            />
          </View>
        </View>

        <Text style={styles.header}>Contract Bond Requisition</Text>

        <View style={styles.mainSection}>
          <View
            style={{
              ...styles.contract,
              borderTop: 'none',
              borderBottomWidth: 2,
            }}
          >
            <Text
              style={{
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              Contact & Project Overview
            </Text>
          </View>

          <View>
            <View style={{ ...styles.row }}>
              <View
                style={{
                  ...styles.column,
                  width: '63%',
                }}
              >
                <Text style={{ ...styles.label }}>Attention :</Text>
                <Text style={styles.field}>{data?.attention}</Text>
              </View>

              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />
              <View
                style={{
                  ...styles.column,
                  width: '37%',
                }}
              >
                <Text style={styles.label}>Final Bond Number: </Text>
                <Text style={styles.field}>{data?.finalBondNumber}</Text>
              </View>
            </View>

            <View style={styles.row}>
              <View style={{ ...styles.column, width: '40%' }}>
                <Text style={{ ...styles.label }}>Broker Name:</Text>
                <Text
                  style={{
                    ...styles.field,
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  {data?.brokerName}
                </Text>
              </View>
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />
              <View style={{ ...styles.column, width: '30%' }}>
                <Text style={{ ...styles.label }}>Date of Requisition: </Text>
                <Text style={styles.field}>
                  {formatDate(data?.dateOfRequisition)}
                  {/* {new Date(data?.dateOfRequisition).toLocaleDateString()} */}
                </Text>
              </View>
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />
              <View style={{ ...styles.column, width: '30%' }}>
                <Text style={{ ...styles.label }}>Date Required: </Text>
                <Text style={styles.field}>
                  {formatDate(data?.dateRequired)}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.row }}>
              <View style={{ ...styles.column, width: '40%' }}>
                <Text style={{ ...styles.label }}>Form Completed By: </Text>
                <Text style={styles.field}>{formatDate(data?.createdAt)}</Text>
              </View>
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />
              <View style={{ ...styles.column, width: '30%' }}>
                <Text style={{ ...styles.label }}>Phone Number: </Text>
                <Text style={styles.field}> {data?.brokerPhone}</Text>
              </View>
            </View>
          </View>

          {/* Contractor and Owner Details */}

          <View style={{ width: '100%' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {/* Left Column */}
              <View style={{ width: '25%' }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                    borderTop: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '9px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1px',
                  }}
                >
                  <Text style={styles.label}>1. General Contractor:</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: '1px',
                  }}
                >
                  <Text style={styles.label}>2. Owner/Obligee:</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: '1px',
                  }}
                >
                  <Text style={styles.label}>3. Engineer/Architect:</Text>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingLeft: '1px',
                  }}
                >
                  <Text style={styles.label}>4. Applicant:</Text>
                </View>
              </View>

              {/* Vertical Divider */}
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />

              <View style={{ width: '25%' }}>
                <View
                  style={{
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '9px',
                      fontWeight: 'bold',
                    }}
                  >
                    Name
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1px',
                  }}
                >
                  <Text style={styles.field}>
                    {data?.generalContractorName}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}> {data?.ownerName}</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}>
                    {' '}
                    {data?.engineerOrArchitectName}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}> {data?.applicantName}</Text>
                </View>
              </View>

              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                  borderBottom: '1px solid black',
                }}
              />

              <View style={{ width: '25%' }}>
                <View
                  style={{
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '9px',
                      fontWeight: 'bold',
                    }}
                  >
                    Phone
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1px 0px',
                  }}
                >
                  <Text style={styles.field}>
                    {' '}
                    {data?.generalContractorPhone}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',

                    flexDirection: 'column',
                  }}
                >
                  <Text style={styles.field}> {data?.ownerPhone}</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}>
                    {' '}
                    {data?.engineerOrArchitectPhone}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}> {data?.applicantPhone}</Text>
                </View>
              </View>

              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />

              <View style={{ width: '25%' }}>
                <View
                  style={{
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '9px',
                      fontWeight: 'bold',
                    }}
                  >
                    Email
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1px 0px',
                  }}
                >
                  <Text style={styles.field}>
                    {' '}
                    {data?.generalContractorEmail}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',

                    flexDirection: 'column',
                  }}
                >
                  <Text style={styles.field}> {data?.ownerEmail}</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}>
                    {' '}
                    {data?.engineerOrArchitectEmail}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={styles.field}> {data?.applicantEmail}</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ ...styles.subContainer }}>
            <View style={{ width: '50%' }}>
              <View
                style={{
                  ...styles.subHerder,
                }}
              >
                <Text
                  style={{
                    ...styles.label,
                    fontSize: '9px',
                  }}
                >
                  5. a) Project Number/Title:
                </Text>

                <Text style={{ ...styles.field, fontSize: 9 }}>
                  {data?.projectName}
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    borderBottom: '1px solid black',
                    borderTop: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text style={styles.label}>b) Description of Work: </Text>
                  <Text style={styles.field}>
                    {data?.typeOfWork?.join(', ')}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text style={styles.label}>
                    c) Location (Nearest Town/City):{' '}
                  </Text>
                  <Text style={styles.field}>{data?.projectLocation}</Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderBottom: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '1.5px 0px',
                  }}
                >
                  <Text style={styles.label}>d) Type of Owner: </Text>
                  <Text style={styles.field}>{data?.typeOfOwner}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '1px',
                backgroundColor: 'black',
              }}
            />
            <View style={{ width: '50%' }}>
              <View
                style={{
                  ...styles.subHerder,
                }}
              >
                <Text
                  style={{
                    ...styles.label,
                    fontSize: '9px',
                  }}
                >
                  6. Work On Hand as of Date:
                </Text>

                <Text style={{ ...styles.field, fontSize: 9 }}>
                  {data?.workOnHandsAsOfDate}
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {/* Left Column */}
                <View style={{ width: '40%' }}>
                  <View
                    style={{
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1.5px 0px',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.label,
                        fontSize: '9px',
                        fontWeight: 'bold',
                      }}
                    >
                      Bond
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1px 0px',
                    }}
                  >
                    <Text style={styles.label}>Total Bonded Jobs:</Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>Total Unbonded Jobs:</Text>
                    {/* <Text style={styles.field}>{data?.applicantName}</Text> */}
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>Total Outstanding Bids:</Text>
                    {/* <Text style={styles.field}>{data?.applicantName}</Text> */}
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>Total Sum:</Text>
                    {/* <Text style={styles.field}>{data?.applicantName}</Text> */}
                  </View>
                </View>

                {/* Vertical Divider */}
                <View
                  style={{
                    width: '1px',
                    backgroundColor: 'black',
                  }}
                />

                <View style={{ width: '30%' }}>
                  <View
                    style={{
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1.5px 0px',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.label,
                        fontSize: '9px',
                        fontWeight: 'bold',
                      }}
                    >
                      Contract Price
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1px 0px',
                    }}
                  >
                    <Text style={styles.label}>
                      ${data?.totalBondedJobs?.contractPrice}
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalUnbondedJobs?.contractPrice}
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalOutstandingBids?.contractPrice}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalSum?.contractPrice}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    width: '1px',
                    backgroundColor: 'black',
                  }}
                />

                <View style={{ width: '30%' }}>
                  <View
                    style={{
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1.5px 0px',
                    }}
                  >
                    <Text
                      style={{
                        ...styles.label,
                        fontSize: '9px',
                        fontWeight: 'bold',
                      }}
                    >
                      Cost to Complete
                    </Text>
                    {/* <Text style={styles.field}>{data?.applicantName}</Text> */}
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '1px 0px',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalBondedJobs?.costToComplete}
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',

                      flexDirection: 'column',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalUnbondedJobs?.costToComplete}
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.section,
                      borderBottom: '1px solid black',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalOutstandingBids?.costToComplete}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>
                      {' '}
                      ${data?.totalSum?.costToComplete}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Contract Details (also fill out A or B below) */}
          <View
            style={{
              ...styles.contract,
              borderTopWidth: 2,
            }}
          >
            <Text
              style={{
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              Contract Details
            </Text>
          </View>

          <View
            style={{
              ...styles.subContainer,
            }}
          >
            {/* Left Column */}
            <View style={{ width: '50%' }}>
              <View
                style={{
                  ...styles.leftSession,
                }}
              >
                <Text style={styles.label}>
                  7. Architect/Engineer Estimate:
                </Text>
                <Text style={styles.field}>${data?.architectEstimate}</Text>
              </View>

              <View
                style={{
                  ...styles.leftSession,
                }}
              >
                <Text style={styles.label}>8. Commencement Date: </Text>
                <Text style={styles.field}>
                  {formatDate(data?.projectStartDate)}
                </Text>
              </View>

              <View
                style={{
                  ...styles.leftSession,
                }}
              >
                <Text style={styles.label}>9. Completion Date: </Text>
                <Text style={styles.field}>
                  {formatDate(data?.projectEndDate)}
                </Text>
              </View>
              <View
                style={{
                  ...styles.leftSession,
                  flexDirection: 'column',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={styles.label}>10. a) Contract Form: </Text>
                    <Text style={styles.field}>{data?.contractDetails}</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Text style={{ ...styles.label }}>b) Contract Type: </Text>
                    <Text style={styles.field}>{data?.contractType}</Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={{ ...styles.label, marginTop: '2px' }}>
                    c) Private Clause:
                  </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox checked={data?.privateOwnersClause === true} />
                    <Text style={styles.label}>Yes</Text>
                    <CheckBox checked={data?.privateOwnersClause === false} />
                    <Text style={styles.label}>No</Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  ...styles.leftSession,
                }}
              >
                <Text style={styles.label}>
                  11. Maintenance/Warranty Term (number of years):{' '}
                </Text>
                <Text style={styles.field}>
                  {data?.maintenanceWarrantyTerm}
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.label}>12. a) Payment Terms:</Text>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <CheckBox checked={data?.paymentTerms === 'Monthly'} />
                  <Text style={styles.label}> Monthly</Text>

                  <CheckBox checked={data?.paymentTerms === 'Milestone'} />
                  <Text style={styles.label}> Milestone</Text>

                  <CheckBox checked={data?.paymentTerms === 'Others'} />
                  <Text style={styles.label}> Others</Text>
                </View>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ ...styles.label, marginTop: '2px' }}>
                  b) Holdback Terms:
                </Text>
                {/* <Text style={styles.field}>{data?.holdback}</Text> */}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  {data?.holdbackPercentage ? (
                    <Text style={styles.label}>
                      {' '}
                      {data?.holdbackPercentage}%
                    </Text>
                  ) : (
                    <Text style={styles.label}> N/A</Text>
                  )}
                </View>
              </View>
            </View>

            {/* Vertical Divider */}
            <View
              style={{
                width: '1px',
                backgroundColor: 'black',
              }}
            />

            {/* Right Column */}
            <View style={{ width: '50%' }}>
              <View
                style={{
                  ...styles.section,
                  borderBottom: '1px solid black',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    ...styles.checkBox,
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderTop: 'none',
                  }}
                >
                  <Text style={styles.label}>
                    13. Is there a payment certifier?
                  </Text>

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox checked={true} />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox checked={false} />
                    <Text style={styles.label}> No</Text>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={styles.label}>14.a) Liquidated Damages? </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox checked={data?.liquidatedDamages === true} />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox checked={data?.liquidatedDamages === false} />
                    <Text style={styles.label}> No</Text>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={styles.label}>b) Penalties? </Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox checked={data?.penaltiesInContract === true} />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox checked={data?.penaltiesInContract === false} />
                    <Text style={styles.label}> No</Text>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={styles.label}>c) Are the damages capped?</Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox
                      checked={data?.liquidatedDamagesCapped === true}
                    />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox checked={false} />
                    <Text style={styles.label}> No</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  ...styles.section,
                  borderBottom: '1px solid black',
                  justifyContent: 'space-between',
                  padding: '1.2px 0px',
                }}
              >
                <Text style={styles.label}>15. Environmental Hazards?</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <CheckBox checked={data?.environmentalHazards === true} />
                  <Text style={styles.label}> Yes</Text>

                  <CheckBox checked={data?.environmentalHazards === false} />
                  <Text style={styles.label}> No</Text>
                </View>
              </View>

              <View
                style={{
                  ...styles.section,
                  borderBottom: '1px solid black',
                  justifyContent: 'space-between',
                  padding: '1.8px 0px',
                }}
              >
                <Text style={styles.label}>16. Design Responsibility?</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <CheckBox checked={data?.designResponsibility === true} />
                  <Text style={styles.label}> Yes</Text>

                  <CheckBox checked={data?.designResponsibility === false} />
                  <Text style={styles.label}> No</Text>
                </View>
              </View>
              <View
                style={{
                  ...styles.section,
                  display: 'flex',
                  flexDirection: 'column',
                  borderBottom: 'none',
                }}
              >
                <Text style={styles.label}>17. Bond Form:</Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}
                >
                  <CheckBox
                    checked={data?.bondForm?.bondFormValue === 'CCDC'}
                  />
                  <Text style={styles.label}> CCDC</Text>

                  <CheckBox
                    checked={data?.bondForm?.bondFormValue === 'Federal'}
                  />
                  <Text style={styles.label}>
                    {' '}
                    Federal/Provincial Government
                  </Text>
                  <CheckBox
                    checked={data?.bondForm?.bondFormValue === 'Others'}
                  />
                  <Text style={styles.label}> Others</Text>
                </View>
              </View>
            </View>
          </View>

          {/* Bid Bond  application */}
          {!!data?.bidBondDetails?.estimatedContractPrice && (
            <View
              style={{
                ...styles.subContainer,
              }}
            >
              {/* Left Column */}
              <View style={{ width: '50%' }}>
                <View
                  style={{
                    ...styles.subHerder,
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    A. Bid Bond Application
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.leftSession,
                    borderTopWidth: 1,
                    borderTopColor: 'black',
                    width: '100%',
                  }}
                >
                  <Text style={styles.label}>18. Bond Type:</Text>
                  <Text
                    style={[styles.field, { flexWrap: 'wrap', width: '80%' }]}
                  >
                    {data?.bondType?.slice(0, 3).join(', ') +
                      (data?.bondType?.length > 3 ? '... more' : '')}
                  </Text>
                </View>

                <View style={{ width: '100%' }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    {/* Left Column - Bond Type */}
                    <View style={{ width: '40%' }}>
                      <View
                        style={{
                          borderTop: '1px solid black',
                          padding: '1.5px 0px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: '9px',
                            fontWeight: 'bold',
                          }}
                        >
                          Bond Type
                        </Text>
                      </View>
                      {validBonds.map((bond, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottom: '1px solid black',
                            padding: '1px 0px',
                          }}
                        >
                          <Text style={styles.label}>{bond.type}</Text>
                        </View>
                      ))}
                    </View>

                    {/* Vertical Divider */}
                    <View style={{ width: '1px', backgroundColor: 'black' }} />

                    {/* Percentage Column */}
                    <View style={{ width: '30%' }}>
                      <View
                        style={{
                          borderTop: '1px solid black',
                          padding: '1.5px 0px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: '9px',
                            fontWeight: 'bold',
                          }}
                        >
                          Percentage
                        </Text>
                      </View>
                      {validBonds.map((bond, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottom: '1px solid black',
                            padding: '1px',
                          }}
                        >
                          <Text style={styles.label}>
                            % {bond.percentage || 'N/A'}
                          </Text>
                        </View>
                      ))}
                    </View>

                    {/* Vertical Divider */}
                    <View style={{ width: '1px', backgroundColor: 'black' }} />

                    {/* Amount Column */}
                    <View style={{ width: '30%' }}>
                      <View
                        style={{
                          borderTop: '1px solid black',
                          padding: '1.5px 0px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: '9px',
                            fontWeight: 'bold',
                          }}
                        >
                          Amount
                        </Text>
                      </View>
                      {validBonds.map((bond, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottom: '1px solid black',
                            padding: '1px 0px',
                          }}
                        >
                          <Text style={styles.label}>${bond.amount}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              </View>

              {/* Vertical Divider */}
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />

              {/* Right Column */}
              <View style={{ width: '50%' }}>
                <View
                  style={{
                    ...styles.leftSession,
                  }}
                >
                  <Text style={styles.label}>
                    19. Estimated Contract Price:
                  </Text>
                  <Text style={styles.field}>
                    ${data?.bidBondDetails?.estimatedContractPrice}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.leftSession,
                  }}
                >
                  <Text style={styles.label}>20. Tender Date (dd/mmm/yy):</Text>
                  <Text style={styles.field}>
                    {formatDate(data?.bidBondDetails?.tenderDate)}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.leftSession,
                    borderBottom: 'none',
                  }}
                >
                  <Text style={styles.label}>21. Bid Bond Amount:</Text>
                  <Text style={styles.field}>
                    ${data?.bidBondDetails?.bidBondAmount}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.section,
                    borderTop: '1px solid black',
                    padding: '2px 0px',
                  }}
                >
                  <Text style={styles.label}>
                    22. Acceptance Period (number of days):{' '}
                  </Text>

                  <Text style={styles.field}>
                    {data?.bidBondDetails?.acceptancePeriod}
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.section,
                    justifyContent: 'space-between',
                    padding: '2.5px 0px',
                  }}
                >
                  <Text style={styles.label}>23. Consent of Surety?</Text>
                  {/* <Text style={styles.field}>{data?.consentOfSurety}</Text> */}
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox checked={data?.consentOfSurety === true} />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox checked={data?.consentOfSurety === false} />
                    <Text style={styles.label}>No</Text>
                  </View>
                </View>

                <View
                  style={{
                    ...styles.section,
                    justifyContent: 'space-between',
                    padding: '2.5px 0px',
                  }}
                >
                  <Text style={styles.label}>
                    24. Pre-qualification Letter?:
                  </Text>

                  {/* <Text style={styles.field}>
                {data?.bidBondDetails?.preQualificationLetter}
              </Text> */}
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox
                      checked={
                        data?.bidBondDetails?.preQualificationLetter === true
                      }
                    />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox
                      checked={
                        data?.bidBondDetails?.preQualificationLetter === false
                      }
                    />
                    <Text style={styles.label}>No</Text>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.section,
                    borderBottom: 'none',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={styles.label}>
                    25. Final Bonds to be Required:
                  </Text>

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox
                      checked={
                        data?.bidBondDetails?.isFinalBondRequired === true
                      }
                    />
                    <Text style={styles.label}> Yes</Text>

                    <CheckBox
                      checked={
                        data?.bidBondDetails?.isFinalBondRequired === false
                      }
                    />
                    <Text style={styles.label}>No</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* final Bond  application */}
          {/* final Bond  application */}
          {/* final Bond  application */}
          {!!data?.finalBondDetails?.contractPrice && (
            <View
              style={{
                ...styles.subContainer,
              }}
            >
              {/* Left Column */}
              <View style={{ width: '50%' }}>
                <View
                  style={{
                    ...styles.subHerder,
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '10px',
                      fontWeight: 'bold',
                    }}
                  >
                    A. final Bond Application
                  </Text>
                </View>
                <View
                  style={{
                    ...styles.leftSession,
                    borderTopWidth: 1,
                    borderTopColor: 'black',
                    width: '100%',
                  }}
                >
                  <Text style={styles.label}>18. Bond Type:</Text>
                  <Text
                    style={[styles.field, { flexWrap: 'wrap', width: '80%' }]}
                  >
                    {data?.bondType?.slice(0, 3).join(', ') +
                      (data?.bondType?.length > 3 ? '... more' : '')}
                  </Text>
                </View>

                <View style={{ width: '100%' }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                  >
                    {/* Left Column - Bond Type */}
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          borderTop: '1px solid black',
                          padding: '1.5px 0px',
                        }}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: '9px',
                            fontWeight: 'bold',
                            borderBottom: '1px solid black',
                          }}
                        >
                          Bond Type
                        </Text>
                      </View>
                      {finalValidBonds.map((bond, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottom: '1px solid black',
                            padding: '1px 0px',
                          }}
                        >
                          <Text style={styles.label}>{bond.type}</Text>
                        </View>
                      ))}
                    </View>

                    {/* Vertical Divider */}
                    <View style={{ width: '1px', backgroundColor: 'black' }} />

                    {/* Amount Column */}
                    <View style={{ width: '50%' }}>
                      <View
                        style={{
                          borderTop: '1px solid black',
                          padding: '1.5px 0px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        <Text
                          style={{
                            ...styles.label,
                            fontSize: '9px',
                            fontWeight: 'bold',
                          }}
                        >
                          Amount
                        </Text>
                      </View>
                      {finalValidBonds.map((bond, index) => (
                        <View
                          key={index}
                          style={{
                            borderBottom: '1px solid black',
                            padding: '1px 0px',
                          }}
                        >
                          <Text style={styles.label}>${bond.amount}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                </View>
              </View>

              {/* Vertical Divider */}
              <View
                style={{
                  width: '1px',
                  backgroundColor: 'black',
                }}
              />

              {/* Right Column */}
              <View style={{ width: '50%' }}>
                <View
                  style={{
                    ...styles.subHerder,
                    borderBottom: '1px solid black',
                  }}
                >
                  <Text style={styles.label}>19. Contract Price:</Text>
                  <Text style={styles.field}>
                    ${data?.finalBondDetails?.contractPrice}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.checkBox,
                    padding: '3.1px 0px',
                    borderTop: 'null',
                  }}
                >
                  <Text style={styles.label}>20.Includes:</Text>

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginLeft: 10,
                    }}
                  >
                    <CheckBox
                      checked={data?.finalBondDetails?.includesTaxes === true}
                    />
                    <Text style={styles.label}> GST/HST</Text>

                    <CheckBox checked={false} />
                    <Text style={styles.label}>Provincial Tax</Text>
                  </View>
                </View>

                <View
                  style={{
                    ...styles.section,
                    padding: '2px 0px',
                    borderBottom: 'none',
                  }}
                >
                  <Text style={styles.label}>21. Contract Date:</Text>
                  <Text style={styles.field}>
                    {data?.finalBondDetails?.contractDate}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    borderTop: '1px solid black',
                    padding: '2px 0px',
                  }}
                >
                  <Text style={styles.label}>22. Performance Bond Amount:</Text>

                  <Text style={styles.field}>
                    ${data?.finalBondDetails?.performanceBondAmount}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    padding: '2.5px 0px',
                  }}
                >
                  <Text style={styles.label}>23. Payment Bond Amount:</Text>
                  <Text style={styles.field}>
                    ${data?.finalBondDetails?.paymentBondAmount}
                  </Text>
                </View>

                <View
                  style={{
                    ...styles.section,
                    padding: '2.4px 0px',
                  }}
                >
                  <Text style={styles.label}>24. Maintenance Bond Amount:</Text>
                  <Text style={styles.field}>
                    ${data?.finalBondDetails?.maintenanceBondAmount}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingVertical: 2.5,
                    flexWrap: 'wrap',
                  }}
                >
                  <Text style={[styles.label, { flexShrink: 1 }]}>
                    25. Premium Calculation including rate used:
                  </Text>
                  <Text
                    style={[styles.field, { flexShrink: 1, flexWrap: 'wrap' }]}
                  >
                    {data?.premiumCalculation}
                  </Text>
                </View>
              </View>
            </View>
          )}

          {/* Tender */}
          {/* Tender */}
          {/* Tender */}
          <View
            style={{
              ...styles.contract,
            }}
          >
            <Text
              style={{
                fontSize: '11px',
                fontWeight: 'bold',
              }}
            >
              Bond Approval & Premium
            </Text>
          </View>

          <View
            style={{
              ...styles.subContainer,
              borderTop: 'none',
            }}
            wrap={false}
          >
            {/* Left Column */}
            <View style={{ width: '50%' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderTop: '1px solid black',
                  gap: '20px',
                  padding: '2px 0px',
                }}
              >
                <Text
                  style={{
                    ...styles.label,
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  26. Was the price and/or terms negotiated
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: 2,
                  }}
                >
                  <CheckBox checked={data?.isNegotiated === true} />
                  <Text style={styles.label}> Yes</Text>

                  <CheckBox checked={data?.isNegotiated === false} />
                  <Text style={styles.label}>No</Text>
                </View>
              </View>

              <View
                style={{
                  borderBottom: '1px solid black',
                  borderTop: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '1.5px 0px',
                }}
              >
                <Text
                  style={{
                    ...styles.label,
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  27. Work Sublet
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  borderBottom: '1px solid black',
                }}
              >
                <View style={{ width: '33%' }}>
                  <View
                    style={{
                      ...styles.label,
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '4px',
                    }}
                  >
                    <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                      Major Subs
                    </Text>
                  </View>

                  {data?.workSublet?.map((bidder: any, index: any) => (
                    <View
                      key={index}
                      style={{
                        borderBottom:
                          index === data?.workSublet.length - 1
                            ? 'none'
                            : '1px solid black',
                        borderTop: index === 0 ? '1px solid black' : 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '2.5px 0px',
                        height: '22.5px',
                      }}
                    >
                      <Text style={{ marginLeft: '2px' }}>{bidder?.name}</Text>
                    </View>
                  ))}
                </View>

                <View style={{ width: '1px', backgroundColor: 'black' }} />

                <View style={{ width: '33%' }}>
                  <View
                    style={{
                      ...styles.label,
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '4px',
                    }}
                  >
                    <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                      Amount
                    </Text>
                  </View>

                  {data?.workSublet?.map((bidder: any, index: any) => (
                    <View
                      key={index}
                      style={{
                        borderBottom:
                          index === data?.workSublet.length - 1
                            ? 'none'
                            : '1px solid black',
                        borderTop: index === 0 ? '1px solid black' : 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '2.5px 0px',
                        height: '22.5px',
                      }}
                    >
                      <Text style={{ marginLeft: '2px' }}>
                        ${bidder?.amount}
                      </Text>
                    </View>
                  ))}
                </View>
                <View style={{ width: '1px', backgroundColor: 'black' }} />

                <View style={{ width: '34%' }}>
                  <View
                    style={{
                      ...styles.label,
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '4px',
                    }}
                  >
                    <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                      Bonded
                    </Text>
                  </View>

                  {data?.workSublet?.map((bidder: any, index: any) => (
                    <View
                      key={index}
                      style={{
                        borderBottom:
                          index === data?.workSublet.length - 1
                            ? 'none'
                            : '1px solid black',
                        borderTop: index === 0 ? '1px solid black' : 'none',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '3.7px 0px',
                      }}
                    >
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingLeft: '5px',
                          padding: '2px 4px',
                        }}
                      >
                        <CheckBox checked={bidder?.bonded === true} />
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            {/* Vertical Divider */}
            <View
              style={{
                width: '1px',
                backgroundColor: 'black',
              }}
            />

            {/* Right Column */}
            <View style={{ width: '50%' }}>
              <View
                style={{
                  borderBottom: '1px solid black',
                  borderTop: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '1.6px 0px',
                }}
              >
                <Text
                  style={{
                    ...styles.label,
                    fontSize: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  28.Tender Results
                </Text>
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {/* Left Column */}
                <View style={{ width: '50%' }}>
                  <View
                    style={{
                      ...styles.label,
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '4px',
                    }}
                  >
                    <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                      Company Name
                    </Text>
                  </View>

                  {data?.tenderResults?.bidders.map(
                    (bidder: any, index: any) => (
                      <View
                        key={index}
                        style={{
                          borderBottom: '1px solid black',
                          borderTop: index === 0 ? '1px solid black' : 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '2px 0px',
                          height: '22.5px',
                        }}
                      >
                        <Text style={{ marginLeft: '5px', fontSize: 8 }}>
                          {`${index + 2}nd Bidder:` + ' ' + bidder?.companyName}
                        </Text>
                      </View>
                    )
                  )}
                </View>

                {/* Vertical Divider */}
                <View style={{ width: '1px', backgroundColor: 'black' }} />

                {/* Right Column */}
                <View style={{ width: '50%' }}>
                  <View
                    style={{
                      ...styles.label,
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '4px',
                    }}
                  >
                    <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
                      Amount
                    </Text>
                  </View>

                  {data?.tenderResults?.bidders.map(
                    (bidder: any, index: any) => (
                      <View
                        key={index}
                        style={{
                          borderBottom: '1px solid black',
                          borderTop: index === 0 ? '1px solid black' : 'none',
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '2px 0px',
                          height: '22.5px',
                        }}
                      >
                        <Text style={{ marginLeft: '2px', fontSize: 8 }}>
                          ${bidder.amount}
                        </Text>
                      </View>
                    )
                  )}
                </View>
              </View>
              <View style={{ width: '100%' }}>
                <View
                  style={{
                    ...styles.subHerder,
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      ...styles.label,
                      fontSize: '11px',
                      fontWeight: 'bold',
                      marginBottom: '60px',
                    }}
                  >
                    Approval Stamp
                  </Text>

                  <View
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <Text style={styles.label}> Recommended By:_______</Text>

                    <Text
                      style={{
                        ...styles.label,
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {' '}
                      Approved By:{' '}
                      <Text
                        style={{
                          ...styles.label,
                          textDecoration: 'underline',
                        }}
                      >
                        {data?.approver}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.borderBottom} />
      </Page>
    </Document>
  );
};

export default PDFForm;
