import {
  BondActivity,
  BondActivityBonds,
  BondEntry,
  ClientProfile,
} from 'src/Pages/BrokerPages/BondFacilityTracker/model';
import * as yup from 'yup';

let amount = /^(0|[1-9]\d*)(\.\d{1,2})?$/;

export const bondActivityYupSchema = yup.object<BondActivity>({
  projectName: yup.string().required('Project Name is required'),
  initialTotalContractValue: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  currentTotalContractValue: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  bondAmount: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  maintenancePeriod: yup
    .string()
    .matches(
      /^([0-9][0-9]?){0,2}((\.[1][0-2])?|(\.[0-9]{0,1})?)$/,
      "Enter 'Maintenance Period' in decimal format. E.g., 1.5 is considered as 1 yr 5 months"
    )
    .nullable(),
  otherIndemnity: yup.string().when('indemnityAndSecurityConditions', {
    is: (val: Array<string>) => val && val.includes('Other'),
    then: () =>
      yup.string().required('Enter name for Indemnity & Security Conditions'),
    otherwise: () => yup.string().nullable(),
  }),
  projectStartDate: yup.date().typeError('Invalid Date').nullable(),
  projectCompletionDate: yup.date().typeError('Invalid Date').nullable(),
  issueDate: yup.date().typeError('Invalid Date').nullable(),
  expiryDate: yup.date().typeError('Invalid Date').nullable(),
});

export const clientProfileYupSchema = yup.object<ClientProfile>({
  renewalDate: yup.date().typeError('Invalid Date').nullable(),
  mostRecentFinancialReviewDate: yup
    .date()
    .typeError('Invalid Date')
    .nullable(),
  totalAggregateBondFacilityLimit: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }

      return true;
    })
    .nullable(),
  totalUtilizedAggregateOfBondFacility: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  totalRemainingAggregateOfBondFacility: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  underwritingFee: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  singleContractLimit: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
  otherIndemnity: yup.string().when('indemnityAndSecurityConditions', {
    is: (val: Array<string>) => val && val.includes('Other'),
    then: () =>
      yup.string().required('Enter name for Indemnity & Security Conditions'),
    otherwise: () => yup.string().nullable(),
  }),
  bondFees: yup
    .string()
    .test('is-decimal', 'Enter valid amount', (val: any) => {
      if (!(val === undefined || val === null)) {
        return amount.test(val);
      }
      return true;
    })
    .nullable(),
});

export const manageBondYupSchema = yup.object<BondEntry>({
  bondNo: yup.string().required('Select Bond Number'),
  bondType: yup.string().required('Select Bond Type'),
  customBond: yup.string().when('bondNo', {
    is: (val: string) => val && val === '-addCustom-',
    then: () => yup.string().required('Enter Bond Number'),
    otherwise: () => yup.string().nullable(),
  }),
});

export const manageBondRowYupSchema = yup.object().shape({
  bondType: yup.array().of(
    yup.object<BondActivityBonds>({
      bonds: yup.array().of(
        yup.object().shape({
          amount: yup
            .string()
            .test('is-decimal', 'Enter valid amount', (val: any) => {
              if (!(val === undefined || val === null)) {
                return amount.test(val);
              }
              return true;
            })
            .nullable(),
          expDate: yup.date().typeError('Invalid Date').nullable(),
          // maintenancePeriod: yup
          //   .number()
          //   .positive(
          //     'Enter Maintenance Period in months. E.g., 18 = 18 months'
          //   )
          //   .nullable(),
        })
      ),
    })
  ),
});
