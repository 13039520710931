import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';
import { APIStatus } from 'src/types/unions';

export interface AnalyticalProfitabilityEditableFields {
  TotalRevenue: number | null;
  CoGS: number | null;
  TotalOperatingExpenses: number | null;
  TotalOtherIncome: number | null;
  TotalOtherExpenses: number | null;
  TotalIncomeTaxes: number | null;
  TotalCurrentAssets: number | null;
  TotalLongTermAssets: number | null;
  TotalCurrentLiabilities: number | null;
  TotalLongTermLiabilities: number | null;
  TotalLiabilities: number | null;
  TotalEquity: number | null;
  TotalEquityOfCY: number | null;
  TotalEquityOfPY: number | null;
  TotalAssets: number | null;
  TotalAssetsOfCY: number | null;
  TotalAssetsOfPY: number | null;
}

// Define the structure of our financial data
export interface FinancialData {
  label: string | number;
  netSales: number;
  cogs: number;
  netIncome: number;
  interest: number;
  taxes: number;
  depreciation: number;
  amortization: number;
  totalAssets: number;
  shareholdersEquity: number;
}

export interface ITableData {
  label: string;
  GrossProfit: number | null;
  GrossProfitMargin: number | null;
  GrossMargin: number | null;
  EBITDA: number | null;
  EBITDAMargin: number | null;
  NetIncome: number | null;
  ProfitMargin: number | null;
  ReturnOnAsset: number | null;
  ReturnOnEquity: number | null;
}
export interface ILiquidityTableData {
  label: string;
  daysOfCash: number | null;
  currentRatio: number | null;
  workingCapital: number | null;
  workingCapitalTurnover: number | null;
  backlogToWorkingCapitalRatio: number | null;
  quickRatio: number | null;
  quickAssets: number | null;
  backlogToTangibalNetworth: number | null;
  overBillingsToNetCash: number | null;
}

export interface IEfficiencyTableData {
  label: string;
  accountReceivableTurnover: number | null;
  daysSalesOutstanding: number | null;
  inventoryTurnover: number | null;
  daysInventoryOutstanding: number | null;
  totalAssetTurnover: number | null;
  underbillingsToTangibleNetworth: number | null;
  revenueToTangibleNetwork: number | null;
}

export interface ILeverageTableData {
  label: string;
  debtToEquity: number | null;
  debtToAsset: number | null;
  equityRatio: number | null;
  timesInterestEarned: number | null;
  tangibleNetWorth: number | null;
  longTermAssetsToTangibleNetWorth: number | null;
  totalLiabilitiesToTangibleNetWorth: number | null;
  ebitda: number | null;
}
export interface AnalyticalReportDetails {
  tableData:
    | ITableData[]
    | ILiquidityTableData[]
    | IEfficiencyTableData[]
    | ILeverageTableData[];
  editable: Record<string, any>;
  profitAndLossData: { [key: string]: optionsFields[] };
  balanceSheetData: { [key: string]: optionsFields[] };
}

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_ANALYTICAL_REPORTS_DETAILS'
    | 'UPDATE_ANALYTICAL_REPORTS_DETAILS'
    | null;
  reportDetails: AnalyticalReportDetails | null;
  msg: string | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  reportDetails: null,
  msg: null,
};

export default initialState;
