import {
  Box,
  Button,
  Container,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { BsFillExclamationOctagonFill } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DisconnectQBO, PermissionCheckQbo } from 'src/Redux/Applicant/Qbo/Qbo';
import * as Yup from 'yup';

function QboSuccess() {
  const [searchParams] = useSearchParams();
  const [companies] = useState([
    searchParams.get('applicantCompanyName') || '',
    searchParams.get('companyName') || '',
  ]);
  const [qboToken] = useState(searchParams.get('token') || '');
  const [isDataUpdating, setIsDataUpdating] = useState(false);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState('');
  const Toast = useToast();

  const initialValues = {
    selectOption: '',
  };

  useEffect(() => {
    const qboCheck = async () => {
      let res = '';
      try {
        const result = await dispatch(PermissionCheckQbo(qboToken) as any);
        console.log('qbo=', result);
        const response = result?.payload;
        res = response?.message;
        const resStatus = response?.status;

        if (resStatus === 200) {
          if (!response.data.qboAccountLinked) {
            setUserInput('no');
          }
        } else {
          if (resStatus === 401) {
            setUserInput('no');
            Toast({
              title: 'Token expired!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            Toast({
              title: response?.msg || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res || 'Error occurred!',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    };
    qboCheck();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      selectOption: Yup.string().required('Please select'),
    }),
    onSubmit: async (values) => {
      console.log('submit');
      if (values.selectOption === 'no') {
        setIsDataUpdating(true);
        let res = '';
        try {
          const actionResult = await dispatch(DisconnectQBO(qboToken) as any);
          console.log('res=', actionResult);
          const response = actionResult?.payload;
          res = response?.message;
          const resStatus = response?.status;
          if (resStatus === 200) {
            setUserInput('no');
          } else {
            if (resStatus === 401) {
              Toast({
                title: 'Token expired!',
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              Toast({
                title: response?.msg || 'Error occurred!',
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          }
        } catch (error: any) {
          if (res) {
            Toast({
              title: res || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
        setIsDataUpdating(false);
      } else setUserInput('yes');
    },
  });
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p={2}
    >
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        // w={["96%", "96%", "auto  "]}
        p="22px"
        borderRadius="20px"
      >
        <Flex flexDirection={'column'} gap={'10px'}>
          <Box
            mt={5}
            fontSize={'100px'}
            display={'flex'}
            justifyContent={'center'}
            color={userInput === 'no' ? 'red' : 'green'}
          >
            {userInput === 'no' ? (
              <BsFillExclamationOctagonFill />
            ) : (
              <FaCheckCircle />
            )}
          </Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={26}
            pt="20px"
            pb="20px"
          >
            {userInput === 'no'
              ? 'Disconnected from QBO'
              : 'Successfully connected to QBO'}
          </Text>
          {userInput === '' && (
            <Text color="#114684" fontWeight={'600'}>
              Is Applicant's company name <b>({companies[0]})</b> same as QBO
              company name <b>({companies[1]})</b>?
            </Text>
          )}
          {userInput === '' && (
            <form onSubmit={formik.handleSubmit}>
              <RadioGroup
                color="#114684"
                mb={'20px'}
                fontSize={18}
                fontWeight={'600'}
                defaultValue={formik.values.selectOption}
                name="selectOption"
                onChange={(value) =>
                  formik.setFieldValue('selectOption', value)
                }
              >
                <Stack direction="row" gap={5} justifyContent={'center'}>
                  <Radio colorScheme="green" value="yes">
                    Yes
                  </Radio>
                  <Radio colorScheme="red" value="no">
                    No
                  </Radio>
                </Stack>
              </RadioGroup>
              <Text size={'sm'} mb={5}>
                Select 'No' to disconnect from QBO
              </Text>
              <Button
                type="submit"
                colorScheme="purple"
                width={'100%'}
                size="lg"
                disabled={!(formik.isValid && formik.dirty)}
                isLoading={isDataUpdating}
              >
                Continue
              </Button>
            </form>
          )}
        </Flex>
      </Container>
    </Box>
  );
}

export default QboSuccess;
