import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { DatePickerInput } from 'rc-datepicker';
import { useEffect, useRef } from 'react';
import { FaDollarSign } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { IoAttachSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useS3FileUpload } from 'src/hooks';
import {
  AddClientProfile,
  UpdateClientProfile,
} from 'src/Redux/Broker/BondFacilityTracker/BondActivity';
import { useAppDispatch } from 'src/Redux/Store';
import {
  addFocusEventListenerOnInput,
  amountOnly,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';
import { clientProfileYupSchema } from 'src/ValidationSchema/Bond/bond-facility-tracker';
import Swal from 'sweetalert2';
import {
  ClientProfile,
  indemnityAndSecurityTypes,
  initalizeClientProfile,
} from './model';

const ClientProfileAdd = ({
  addMode,
  applicantId,
  selectedClientProfile,
}: {
  addMode: any;
  applicantId: string;
  selectedClientProfile: ClientProfile | null;
}) => {
  const renewalDateRef = useRef<DatePickerInput>(null);
  const reviewDateRef = useRef<DatePickerInput>(null);
  const inputFileBondAgreement = useRef<HTMLInputElement>(null);
  const Toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const handleExit = () => {
    addMode(false);
  };

  const inputFileRef = useRef<HTMLInputElement>(null);
  const triggerInputFile = () => {
    inputFileRef.current?.click();
  };
  const onFileChange = async (e: any, fieldName: string) => {
    try {
      const formData = new FormData();
      formData.append(`files`, e.target.files[0]);
      const res = await uploadFilesInS3(formData);

      if (S3UploadError) {
        Toast({
          title: S3UploadError,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
        return;
      }

      const fileData = {
        linkId: res?.data?.[0]?.encoding,
        link: res?.data?.[0]?.location,
        urlKey: res?.data?.[0]?.key,
        fileName: res?.data?.[0]?.originalname,
      };

      const files = [
        fileData,
        ...(formik.getFieldProps(fieldName).value || []),
      ];

      formik.setFieldValue(fieldName, files);
    } catch (error) {
      console.log('Upload error', error);
    }
  };

  const removeFile = (index: number, fieldName: string) => {
    const files = [...(formik.getFieldProps(fieldName).value || [])];
    files.splice(index, 1);
    formik.setFieldValue(fieldName, files);
  };

  const formik = useFormik({
    initialValues: selectedClientProfile
      ? selectedClientProfile
      : initalizeClientProfile,
    validationSchema: clientProfileYupSchema,
    onSubmit: async (values: ClientProfile, { resetForm }) => {
      let hasValue = false;

      if (selectedClientProfile === null) {
        hasValue = Object.values(values).some((value) => {
          if (!Array.isArray(value)) {
            if (!(value === null || value.toString().trim() === ''))
              return true;
          }
          return false;
        });
      } else {
        const payloadVal = omit(values, [
          'applicantId',
          'createdAt',
          'id',
          'updatedAt',
        ]);

        hasValue = Object.values(payloadVal).some((value) => {
          if (!Array.isArray(value)) {
            if (!(value === null || value.toString().trim() === ''))
              return true;
          }
          return false;
        });
      }

      if (hasValue) {
        let res = '';

        if (
          values.indemnityAndSecurityConditions &&
          values.indemnityAndSecurityConditions?.length > 0
        ) {
          if (values.indemnityAndSecurityConditions.includes('Other'))
            values.indemnityAndSecurityConditions.push(
              values.otherIndemnity ?? ''
            );
        }

        const payload: any = {
          indemnityAndSecurityConditionsAttachments:
            values.indemnityAndSecurityConditions &&
            values.indemnityAndSecurityConditions.length > 0
              ? values.indemnityAndSecurityConditionsAttachments
              : [],

          indemnityAndSecurityConditions:
            values.indemnityAndSecurityConditions || [],
          totalAggregateBondFacilityLimit:
            values.totalAggregateBondFacilityLimit || 0,
          totalUtilizedAggregateOfBondFacility:
            values.totalUtilizedAggregateOfBondFacility || 0,
          totalRemainingAggregateOfBondFacility:
            values.totalRemainingAggregateOfBondFacility || 0,
          singleContractLimit: values.singleContractLimit || 0,
          renewalDate: values.renewalDate,
          financialCovenants: values.financialCovenants,
          clientReportingRequirements: values.clientReportingRequirements,
          financialReportingFrequency: values.financialReportingFrequency,
          mostRecentFinancialReviewDate: values.mostRecentFinancialReviewDate,

          claimsIncidents: values.claimsIncidents?.toString().trim(),
          underwritingFee: values.underwritingFee || 0,
          bondFees: values.bondFees || 0,
          bondFacilityAgreementAttachments:
            values.bondFacilityAgreementAttachments || [],
          applicantId: applicantId,
        };

        if (selectedClientProfile == null) {
          try {
            const actionResult = await dispatch(
              AddClientProfile(payload) as any
            );

            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              resetForm();
              handleExit();
              Toast({
                title: res || 'Client Profile added successfully',
                status: 'success',
                isClosable: true,
                duration: 1000,
                position: 'top-right',
              });
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res || 'Error occurred!',
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          } catch (err: any) {
            Toast({
              title: res || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        } else {
          try {
            const actionResult = await dispatch(
              UpdateClientProfile({
                ...payload,
                id: selectedClientProfile.id,
              }) as any
            );

            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              resetForm();
              handleExit();
              Toast({
                title: res || 'Bond Activity successfully updated',
                status: 'success',
                isClosable: true,
                duration: 1000,
                position: 'top-right',
              });
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res || 'Error occurred!',
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          } catch (err: any) {
            Toast({
              title: res || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      } else {
        Swal.fire({
          title: 'Please provide value for atleast one field.',
          icon: 'warning',
          showConfirmButton: true,
        });
      }
    },
  });

  useEffect(() => {
    if (selectedClientProfile) {
      let otherindem =
        selectedClientProfile.indemnityAndSecurityConditions?.filter(
          (obj) => !indemnityAndSecurityTypes.includes(obj)
        );

      if (otherindem && otherindem?.length > 0)
        formik.setFieldValue('otherIndemnity', otherindem[0]);
    }
  }, [applicantId]);

  return (
    <Flex
      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px;'}
      p="1.5em"
      borderRadius={'8px'}
      flexDirection={'column'}
      gap={5}
      color={'#114684'}
    >
      <Flex>
        <Heading size={'md'}>
          {selectedClientProfile === null ? 'Add' : 'Edit'} Client Profile
        </Heading>
        <Spacer></Spacer>
        <IconButton
          isRound={true}
          variant="solid"
          fontSize={'18px'}
          aria-label="Exit"
          icon={<IoMdClose />}
          onClick={handleExit}
        />
      </Flex>
      <form onSubmit={formik.handleSubmit}>
        <Flex flexDirection={'column'} gap={5}>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Total Aggregate Bond Facility Limit
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="totalAggregateBondFacilityLimit"
                  value={formik.values.totalAggregateBondFacilityLimit || ''}
                  onChange={formik.handleChange}
                  placeholder="Total Aggregate  Bond Facility Limit"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.totalAggregateBondFacilityLimit &&
                formik.errors.totalAggregateBondFacilityLimit && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.totalAggregateBondFacilityLimit}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Total Utilized Aggregate of Bond Facility
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="totalUtilizedAggregateOfBondFacility"
                  value={
                    formik.values.totalUtilizedAggregateOfBondFacility || ''
                  }
                  onChange={formik.handleChange}
                  placeholder="Total Utilized Aggregate of Bond Facility"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.totalUtilizedAggregateOfBondFacility &&
                formik.errors.totalUtilizedAggregateOfBondFacility && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.totalUtilizedAggregateOfBondFacility}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Total Remaining Aggregate of Bond Facility
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="totalRemainingAggregateOfBondFacility"
                  value={
                    formik.values.totalRemainingAggregateOfBondFacility || ''
                  }
                  onChange={formik.handleChange}
                  placeholder="Total Remaining Aggregate of Bond Facility"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.totalRemainingAggregateOfBondFacility &&
                formik.errors.totalRemainingAggregateOfBondFacility && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.totalRemainingAggregateOfBondFacility}
                  </div>
                )}
            </Box>
          </Flex>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Single Contract Limit
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="singleContractLimit"
                  value={formik.values.singleContractLimit || ''}
                  onChange={formik.handleChange}
                  placeholder="Single Contract Limit"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.singleContractLimit &&
                formik.errors.singleContractLimit && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.singleContractLimit}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Renewal Date
              </Text>
              <DatePickerInput
                ref={renewalDateRef}
                locale="en"
                placeholder="Renewal Date"
                className={`react-datepicker-component react-datepicker-input input gray-border`}
                showOnInputClick={true}
                onShow={() => addFocusEventListenerOnInput(renewalDateRef)}
                onHide={() => removeFocusEventListenerOnInput(renewalDateRef)}
                value={
                  formik.values.renewalDate
                    ? formik.values.renewalDate
                    : undefined
                }
                onChange={(date: Date | null) => {
                  formik.setFieldValue('renewalDate', date);
                }}
              />
              {formik.touched.renewalDate && formik.errors.renewalDate && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.renewalDate}
                </div>
              )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Financial Covenants
              </Text>

              <Input
                name="financialCovenants"
                value={formik.values.financialCovenants || ''}
                onChange={formik.handleChange}
                placeholder="Financial Covenants"
              />
            </Box>
          </Flex>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Client Reporting Requirements
              </Text>
              <Select
                name="clientReportingRequirements"
                value={formik.values.clientReportingRequirements || ''}
                onChange={formik.handleChange}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Reporting Requirements"
                />
                <option
                  value={'Balance Sheet'}
                  style={{ color: 'black' }}
                  label="Balance Sheet"
                />
                <option
                  value={'Inventory'}
                  style={{ color: 'black' }}
                  label="Inventory"
                />
                <option
                  value={'Work on hand'}
                  style={{ color: 'black' }}
                  label="Work on hand"
                />
                <option
                  value={'A/R Aging'}
                  style={{ color: 'black' }}
                  label="A/R Aging"
                />
                <option
                  value={'A/P Aging'}
                  style={{ color: 'black' }}
                  label="A/P Aging"
                />
                <option
                  value={'P & L'}
                  style={{ color: 'black' }}
                  label="P & L"
                />
              </Select>
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Most Recent Financial Review Date
              </Text>
              <DatePickerInput
                ref={reviewDateRef}
                locale="en"
                placeholder="Review Date"
                className={`react-datepicker-component react-datepicker-input input gray-border`}
                showOnInputClick={true}
                onShow={() => addFocusEventListenerOnInput(reviewDateRef)}
                onHide={() => removeFocusEventListenerOnInput(reviewDateRef)}
                value={
                  formik.values.mostRecentFinancialReviewDate
                    ? formik.values.mostRecentFinancialReviewDate
                    : undefined
                }
                onChange={(date: Date | null) => {
                  formik.setFieldValue('mostRecentFinancialReviewDate', date);
                }}
              />
              {formik.touched.mostRecentFinancialReviewDate &&
                formik.errors.mostRecentFinancialReviewDate && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.mostRecentFinancialReviewDate}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Financial Reporting Frequency
              </Text>

              <CheckboxGroup
                value={formik.values.financialReportingFrequency || []}
                onChange={(val) =>
                  formik.setFieldValue('financialReportingFrequency', val)
                }
              >
                <Stack
                  flexWrap={'wrap'}
                  spacing={[1, 5]}
                  direction={['column', 'row']}
                >
                  <Checkbox value="Monthly">Monthly</Checkbox>
                  <Checkbox value="Quarterly">Quarterly</Checkbox>
                  <Checkbox value="Semi-Annually">Semi-Annually</Checkbox>
                  <Checkbox value="Annually" isDisabled>
                    Annually
                  </Checkbox>
                </Stack>
              </CheckboxGroup>
            </Box>
          </Flex>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Claims / Incidents
              </Text>
              <Textarea
                name="claimsIncidents"
                value={formik.values.claimsIncidents || ''}
                onChange={formik.handleChange}
                resize={'none'}
                rows={7}
                placeholder="Describe here"
              />
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Indemnity and Security Conditions
              </Text>
              <CheckboxGroup
                value={formik.values.indemnityAndSecurityConditions || []}
                onChange={(val) =>
                  formik.setFieldValue('indemnityAndSecurityConditions', val)
                }
              >
                <Stack
                  spacing={[1, 5]}
                  direction={['column', 'row']}
                  flexWrap={'wrap'}
                >
                  <Checkbox value="Collateral">Collateral</Checkbox>
                  <Checkbox value="Letter of Credit">Letter of Credit</Checkbox>
                  <Checkbox value="Guarantees">Guarantees</Checkbox>
                  <Checkbox value="Other">Other</Checkbox>
                  <input
                    type="file"
                    ref={inputFileRef}
                    style={{ display: 'none' }}
                    onChange={(e: any) =>
                      onFileChange(
                        e,
                        'indemnityAndSecurityConditionsAttachments'
                      )
                    }
                  />
                  {formik.values.indemnityAndSecurityConditions &&
                    formik.values.indemnityAndSecurityConditions?.length >
                      0 && (
                      <Button
                        size={'sm'}
                        colorScheme="yellow"
                        onClick={triggerInputFile}
                        isDisabled={S3UploadLoading}
                      >
                        Attach File
                      </Button>
                    )}
                </Stack>
              </CheckboxGroup>
              {formik.values.indemnityAndSecurityConditions?.includes(
                'Other'
              ) && (
                <Box pt="10px">
                  <Text fontWeight="600" fontSize={13} pb="5px">
                    Name
                  </Text>
                  <Input
                    type="text"
                    name="otherIndemnity"
                    onChange={formik.handleChange}
                    value={formik.values.otherIndemnity || ''}
                    placeholder="Indemnity and Security Condition Name"
                  />
                  {formik.touched.otherIndemnity &&
                    formik.errors.otherIndemnity && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.otherIndemnity}
                      </div>
                    )}
                </Box>
              )}
            </Box>
            {formik.values.indemnityAndSecurityConditions &&
              formik.values.indemnityAndSecurityConditions?.length > 0 && (
                <Flex width="33.33%" direction={'column'}>
                  {formik.values.indemnityAndSecurityConditionsAttachments &&
                    formik.values.indemnityAndSecurityConditionsAttachments
                      .length > 0 && (
                      <Text fontWeight="600" fontSize={13} pb="5px">
                        Files
                      </Text>
                    )}

                  <Flex
                    width={'100%'}
                    borderRadius={'6px'}
                    direction={'column'}
                    border={
                      formik.values.indemnityAndSecurityConditionsAttachments &&
                      formik.values.indemnityAndSecurityConditionsAttachments
                        .length > 0
                        ? '1px solid rgb(210, 213, 218)'
                        : 'none'
                    }
                  >
                    {Array.isArray(
                      formik.values.indemnityAndSecurityConditionsAttachments
                    ) &&
                      formik.values.indemnityAndSecurityConditionsAttachments.map(
                        (items, index) => (
                          <Flex
                            key={index}
                            padding={'4px 5px'}
                            alignItems={'center'}
                            gap={2}
                            borderBottom={
                              index <
                              (
                                formik?.values
                                  ?.indemnityAndSecurityConditionsAttachments ??
                                []
                              ).length -
                                1
                                ? '1px solid rgb(210, 213, 218)'
                                : undefined
                            }
                          >
                            <IoAttachSharp
                              style={{ flexGrow: 0, flexShrink: 0 }}
                              color="#909cab"
                              fontSize={'22px'}
                            />
                            <Text fontSize={'sm'}>{items.fileName}</Text>
                            <Spacer></Spacer>
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              size="sm"
                              fontSize={'14px'}
                              aria-label="Exit"
                              icon={<IoMdClose />}
                              onClick={() =>
                                removeFile(
                                  index,
                                  'indemnityAndSecurityConditionsAttachments'
                                )
                              }
                            />
                          </Flex>
                        )
                      )}
                  </Flex>
                </Flex>
              )}
          </Flex>
          <Flex gap={5}>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Underwriting Fee
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="underwritingFee"
                  value={formik.values.underwritingFee || ''}
                  onChange={formik.handleChange}
                  placeholder="Underwriting Fee"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.underwritingFee &&
                formik.errors.underwritingFee && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {formik.errors.underwritingFee}
                  </div>
                )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="5px">
                Bond Fees
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaDollarSign color="#babec1" />
                </InputLeftElement>
                <Input
                  name="bondFees"
                  value={formik.values.bondFees || ''}
                  onChange={formik.handleChange}
                  placeholder="Bond Fees"
                  onKeyDown={amountOnly}
                />
              </InputGroup>
              {formik.touched.bondFees && formik.errors.bondFees && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {formik.errors.bondFees}
                </div>
              )}
            </Box>
            <Box w="33.33%">
              <Text fontWeight="600" fontSize={13} pb="8px">
                Bond Facility Agreement
              </Text>
              <input
                type="file"
                ref={inputFileBondAgreement}
                style={{ display: 'none' }}
                onChange={(e: any) =>
                  onFileChange(e, 'bondFacilityAgreementAttachments')
                }
              />
              <Button
                size={'sm'}
                colorScheme="yellow"
                onClick={() => inputFileBondAgreement.current?.click()}
                isDisabled={S3UploadLoading}
              >
                Attach File
              </Button>
              {(formik.values.bondFacilityAgreementAttachments ?? []).length >
                0 && (
                <Flex direction={'column'}>
                  <Text fontWeight="600" fontSize={13} pb="5px" pt={'10px'}>
                    Files
                  </Text>

                  <Flex
                    width={'100%'}
                    borderRadius={'6px'}
                    direction={'column'}
                    border={'1px solid rgb(210, 213, 218)'}
                  >
                    {Array.isArray(
                      formik.values.bondFacilityAgreementAttachments
                    ) &&
                      formik.values.bondFacilityAgreementAttachments.map(
                        (items, index) => (
                          <Flex
                            key={index}
                            padding={'4px 5px'}
                            alignItems={'center'}
                            gap={2}
                            borderBottom={
                              index <
                              (
                                formik.values
                                  .bondFacilityAgreementAttachments ?? []
                              ).length -
                                1
                                ? '1px solid rgb(210, 213, 218)'
                                : undefined
                            }
                          >
                            <IoAttachSharp
                              style={{ flexGrow: 0, flexShrink: 0 }}
                              color="#909cab"
                              fontSize={'22px'}
                            />
                            <Text fontSize={'sm'}>{items.fileName}</Text>
                            <Spacer></Spacer>
                            <IconButton
                              isRound={true}
                              variant="ghost"
                              size="sm"
                              fontSize={'14px'}
                              aria-label="Exit"
                              icon={<IoMdClose />}
                              onClick={() =>
                                removeFile(
                                  index,
                                  'bondFacilityAgreementAttachments'
                                )
                              }
                            />
                          </Flex>
                        )
                      )}
                  </Flex>
                </Flex>
              )}
            </Box>
          </Flex>
        </Flex>
      </form>
      <Flex gap={10} justifyContent={'center'} pt={'2em'}>
        <Button colorScheme="gray" type="button" onClick={handleExit}>
          Cancel
        </Button>
        <Button
          colorScheme="blue"
          type="button"
          onClick={() => {
            formik.handleSubmit();
          }}
          isDisabled={S3UploadLoading}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default ClientProfileAdd;
