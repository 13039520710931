import { Stack } from '@mantine/core';
import {
  calculateEBITDA,
  calculateEBITDAMargin,
  calculateGrossMargin,
  calculateGrossProfit,
  calculateNetIncome,
  calculateProfitMargin,
  calculateROA,
  calculateROE,
} from '../../data/calculations';
import { CalculationDisplay } from './CalculationDisplay';
import { EBITDACalculationDisplay } from './EBITDACalculationDisplay';

export interface CalculationReferenceFieldsNew {
  calculationFields: Record<string, any>;
  reportReferenceData: {
    profitAndLossData: Record<string, any>[];
    balanceSheetData: Record<string, any>[];
  };
}

const CalculationReferenceFieldsNew: React.FC<
  CalculationReferenceFieldsNew
> = ({ calculationFields, reportReferenceData }) => {
  return (
    <Stack spacing="xl">
      <CalculationDisplay
        label="Gross Profit"
        inputs={[
          { label: 'Total Revenue', value: calculationFields.TotalRevenue },
          { label: 'CoGS', value: calculationFields.CoGS },
        ]}
        operators={['-']}
        result={calculateGrossProfit(calculationFields)}
      />

      <CalculationDisplay
        label="Gross Margin %"
        inputs={[
          {
            label: 'Gross Profit',
            value: calculateGrossProfit(calculationFields),
          },
          { label: 'Total Revenue', value: calculationFields.TotalRevenue },
        ]}
        operators={['/']}
        result={calculateGrossMargin(calculationFields)}
        isPercentage={true}
      />

      <CalculationDisplay
        label="Net Income"
        inputs={[
          { label: 'Total Revenue', value: calculationFields.TotalRevenue },
          { label: 'CoGS', value: calculationFields.CoGS },
          {
            label: 'Operating Expenses',
            value: calculationFields.TotalOperatingExpenses,
          },
          { label: 'Other Income', value: calculationFields.TotalOtherIncome },
          {
            label: 'Other Expenses',
            value: calculationFields.TotalOtherExpenses,
          },
          { label: 'Income Taxes', value: calculationFields.TotalIncomeTaxes },
        ]}
        operators={['-', '-', '+', '-', '-']}
        result={calculateNetIncome(calculationFields)}
      />

      <EBITDACalculationDisplay
        label="EBITDA"
        calculationFields={calculationFields}
        reportReferenceData={reportReferenceData}
        result={calculateEBITDA(calculationFields)}
      />
      {/* <CalculationDisplay
        label="EBITDA"
        inputs={[
          {
            label: 'Net Income',
            value: calculateNetIncome(calculationFields),
          },
          // Add other inputs for interest, taxes, depreciation, and amortization when available
        ]}
        operators={[]}
        result={calculateEBITDA(calculationFields)}
      /> */}

      <CalculationDisplay
        label="EBITDA Margin %"
        inputs={[
          {
            label: 'EBITDA',
            value: calculateEBITDA(calculationFields),
          },
          { label: 'Total Revenue', value: calculationFields.TotalRevenue },
        ]}
        operators={['/']}
        result={calculateEBITDAMargin(calculationFields)}
        isPercentage={true}
      />

      <CalculationDisplay
        label="Profit Margin %"
        inputs={[
          {
            label: 'Net Income',
            value: calculateNetIncome(calculationFields),
          },
          { label: 'Total Revenue', value: calculationFields.TotalRevenue },
        ]}
        operators={['/']}
        result={calculateProfitMargin(calculationFields)}
        isPercentage={true}
      />

      <CalculationDisplay
        label="Return on Assets %"
        inputs={[
          {
            label: 'Net Income',
            value: calculateNetIncome(calculationFields),
          },
          {
            label: 'Avg Total Assets',
            value:
              calculationFields.TotalAssetsOfCY &&
              calculationFields.TotalAssetsOfPY
                ? (calculationFields.TotalAssetsOfCY +
                    calculationFields.TotalAssetsOfPY) /
                  2
                : null,
          },
        ]}
        operators={['/']}
        result={calculateROA(calculationFields)}
        isPercentage={true}
      />

      <CalculationDisplay
        label="Return on Equity %"
        inputs={[
          {
            label: 'Net Income',
            value: calculateNetIncome(calculationFields),
          },
          {
            label: 'Avg Total Equity',
            value:
              calculationFields.TotalEquityOfCY &&
              calculationFields.TotalEquityOfPY
                ? (calculationFields.TotalEquityOfCY +
                    calculationFields.TotalEquityOfPY) /
                  2
                : null,
          },
        ]}
        operators={['/']}
        result={calculateROE(calculationFields)}
        isPercentage={true}
      />
    </Stack>
  );
};

export default CalculationReferenceFieldsNew;
