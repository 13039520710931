import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
  getPLExpensesOptions,
} from '../../data';

interface CalculationDisplayDaysOfCashProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayDaysOfCash: React.FC<
  CalculationDisplayDaysOfCashProps
> = ({ label, calculationFields }) => {
  const {
    cashOnHand,
    setSelectedCashOnHand,
    annualOperatingExpense,
    setSelectedAnnualOperatingExpense,
    nonCashItems,
    setSelectedNonCashItems,
    reportReferenceData,
  } = useLiquidityContext();
  const isCashOnHandOptions = !!calculationFields?.cashOnHand?.fields?.length;
  const isAnnualOperatingExpenseOptions =
    !!calculationFields?.annualOperatingExpense?.fields?.length;
  const isNonCashItemsOptions =
    !!calculationFields?.nonCashItems?.fields?.length;
  const isCashOnHandValue = !!calculationFields?.cashOnHand?.value
    ? calculationFields?.cashOnHand?.value
    : 0;
  const isAnnualOperatingExpenseValue = !!calculationFields
    ?.annualOperatingExpense?.value
    ? calculationFields?.annualOperatingExpense?.value
    : 0;
  const isNonCashItemsValue = !!calculationFields?.nonCashItems?.value
    ? calculationFields?.nonCashItems?.value
    : 0;

  const totalCashOnHandSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      cashOnHand,
      calculationFields?.cashOnHand,
      reportReferenceData
    );
  }, []);
  const cashOnHandTotalInitialValue =
    isCashOnHandValue - totalCashOnHandSelectedFieldSumInitial;

  const totalAnnualOperatingExpenseSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      annualOperatingExpense,
      calculationFields?.annualOperatingExpense,
      reportReferenceData
    );
  }, []);
  const annualOperatingExpenseTotalInitialValue =
    isAnnualOperatingExpenseValue -
    totalAnnualOperatingExpenseSelectedFieldSumInitial;

  const totalNonCashItemsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      nonCashItems,
      calculationFields?.nonCashItems,
      reportReferenceData
    );
  }, []);
  const nonCashItemsTotalInitialValue =
    isNonCashItemsValue - totalNonCashItemsSelectedFieldSumInitial;

  const cashOnHandTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      cashOnHand,
      calculationFields?.cashOnHand,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + cashOnHandTotalInitialValue)?.toFixed(2)
    );
  }, [cashOnHand, calculationFields.cashOnHand]);

  const annualOperatingExpenseTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      annualOperatingExpense,
      calculationFields?.annualOperatingExpense,
      reportReferenceData
    );
    return Number(
      (
        totalSumSelectedFields + annualOperatingExpenseTotalInitialValue
      )?.toFixed(2)
    );
  }, [annualOperatingExpense, calculationFields.annualOperatingExpense]);

  const nonCashItemsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      nonCashItems,
      calculationFields?.nonCashItems,
      reportReferenceData
    );
    return Number(
      (totalSumSelectedFields + nonCashItemsTotalInitialValue)?.toFixed(2)
    );
  }, [nonCashItems, calculationFields.nonCashItems]);

  const result = useMemo(() => {
    const denominator = (annualOperatingExpenseTotal - nonCashItemsTotal) / 365;
    return Number(
      (cashOnHandTotal / (!!denominator ? denominator : 1)).toFixed(2)
    );
  }, [cashOnHandTotal, annualOperatingExpenseTotal, nonCashItemsTotal]);

  return (
    <Box w={'100%'}>
      <Text size="lg" weight={700} mb="md">
        {label}
      </Text>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Cash on Hand (${cashOnHandTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.cashOnHand?.fields!,
              reportReferenceData!
            )}
            value={cashOnHand}
            onChange={setSelectedCashOnHand}
            placeholder="Select Cash on Hand items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            maw={'50%'}
            w={'100%'}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
        </Flex>

        <Flex align="center" justify="center" wrap={'wrap'} gap={'xs'}>
          <Box sx={{ flex: 1 }}>
            <MultiSelect
              label={`Annual Operating Expense (${annualOperatingExpenseTotal})`}
              data={getPLExpensesOptions(
                calculationFields?.annualOperatingExpense?.fields!,
                reportReferenceData!
              )}
              value={annualOperatingExpense}
              onChange={setSelectedAnnualOperatingExpense}
              placeholder="Select Annual Operating Expense items"
              searchable
              className="legalform_pdfPreference"
              maxDropdownHeight={400}
            />
          </Box>
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <Box sx={{ flex: 1 }}>
            <MultiSelect
              label={`Non-Cash Items (${nonCashItemsTotal})`}
              data={getBSAssetsOptions(
                calculationFields?.nonCashItems?.fields!,
                reportReferenceData!
              )}
              value={nonCashItems}
              onChange={setSelectedNonCashItems}
              placeholder="Select Non-Cash Items"
              searchable
              className="legalform_pdfPreference"
              maxDropdownHeight={400}
            />
          </Box>
          <Text size="xl" weight={700} mt={'xs'}>
            /
          </Text>
          <Text align="center" size={'xs'}>
            365 Days
          </Text>
        </Flex>

        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
