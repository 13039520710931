import { Box, Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from 'src/Redux/Store';

const SuretyHub = () => {
  const userData = useAppSelector((state) => state.broker.brokerProfileData);
  const userType = localStorage.getItem('userType');
  const SubscriptionPlan = userData?.data?.suretyPurchasedPlanInfo;

  return (
    <Flex justify="center" p={6}>
      <Box
        border="1px solid #BDC3C7"
        borderRadius="12px"
        p={6}
        bg="white"
        boxShadow="xl"
        maxW="800px"
        w="100%"
      >
        {/* Title */}
        <Text
          fontSize="24px"
          fontWeight="700"
          color="#2C3E50"
          textAlign="center"
          mb={4}
        >
          Simplify Bond Management with the Surety Hub
        </Text>

        {/* Features Section */}
        <Box bg="#ECF0F1" p={5} borderRadius="8px" mb={5}>
          <Text fontSize="16px" fontWeight="400" color="#34495E" mb={3}>
            <strong>✅ Track with Ease:</strong> Monitor the status of all your
            clients' bonds and facilities in one convenient location.
          </Text>
          <Text fontSize="16px" fontWeight="400" color="#34495E" mb={3}>
            <strong>📊 Gain Insights:</strong> Access detailed analytics to stay
            updated on the progress of your clients' projects.
          </Text>
          <Text fontSize="16px" fontWeight="400" color="#34495E">
            <strong>🔔 Stay Notified:</strong> Receive alerts when updated
            reporting or information is needed, so you never miss a critical
            deadline.
          </Text>
        </Box>

        {/* Call to Action */}
        <Flex justify="center" mt={4}>
          <Text fontSize="18px" fontWeight="600" color="#16A085">
            🚀 Transform how you manage your bond portfolio today!
          </Text>
        </Flex>

        {/* Subscription Message */}
        {(!SubscriptionPlan || SubscriptionPlan === null) && (
          <Flex justify="center" mt={4}>
            <Text fontSize="18px" fontWeight="600" color="#E74C3C">
              ⚠️
              {userType === 'applicant'
                ? 'Please contact your broker to get access to Surety Hub'
                : 'Please contact brokerage for Surety Hub subscription.'}
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default SuretyHub;
